<template>
	<div class="hello flex" v-if="isPreparedHeatmapData">
		<div class="flex-auto ml-5">
			
			<!-- Heading -->
			<h2 class="text-xl text-gray-300">Settings</h2>
			<div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
			<!-- End Heading -->
			
			<!-- Tabs -->
			<div class="flex tab-list">
				<div
					class="h-20 flex-grow flex flex-col justify-between items-center bg-bgLight border border-gray-600 sticky top-0 z-10 cursor-pointer"
					v-for="(item, index) in systemList"
					:key="index"
					@click="changeActiveTab(item)"
					:class="{'bg-purple-500 bg-opacity-40': activeTab.slug === item.slug}"
				>
					<!--<img-->
					<!--	:src="require(`@/assets/${item.logo}`)"-->
					<!--	:alt="item.name"-->
					<!--	class="h-3.5 mt-3.5"-->
					<!--&gt;-->
					<span class="text-sm text-center h-full flex items-center px-2">{{item.name}}</span>
					<div
						class="flex text-xs justify-between self-stretch border-t border-gray-600"
						:style="'font-size: 0.6rem'"
					>
						<div
							class="py-1 flex justify-center w-full border-gray-600"
							:class="{'bg-purple-500 bg-opacity-40': activeTab.slug === item.slug && activeTab.type === 'Operational'}"
							@click="activeTab.type = 'Operational'"
						>
							<p class="text-xs text-gray-400 text-center">Oper.</p>
						</div>
						<div class="self-stretch border-l border-gray-600"></div>
						<div
							class="py-1 flex justify-center w-full border-gray-600"
							:class="{'bg-purple-500 bg-opacity-40': activeTab.slug === item.slug && activeTab.type === 'Deployment'}"
							@click="activeTab.type = 'Deployment'"
						>
							<p class="text-xs text-gray-400 text-center">Depl.</p>
						</div>
					</div>
				</div>
			</div>
			<!-- End Tabs -->
			
			<!-- Color Guide -->
			<div class="flex flex-col items-end">
				<div class="flex mt-4">
					<p class="text-sm mr-2 text-gray-300">Colourblind mode:</p>
					<BaseToggle
						@click.self.stop
						@change="(val) => changeColorBlind(val.target.checked)"
						:name="'colorblind'"
						:value="colorBlindMode"
					/>
				</div>
			</div>
			<div class="my-4">
				<div class="flex justify-between mb-1">
					<p class="text-sm text-gray-300 text-center">Bad</p>
					<p class="text-sm text-gray-300 text-center">Good</p>
				</div>
				<div class="flex rounded-sm overflow-hidden">
				<div
					v-for="(colour, i) in reversedColoursScale"
					:key="i"
					class="w-full h-2"
					:style="`background-color: rgba(${colour[colorMode]}, 0.9)`"
				></div>
			</div>
			</div>
			<!-- End Color Guide -->
			
			<!-- Logo and Description -->
			<div class="flex items-center mt-8">
				<img :src="require(`@/assets/${activeTab.logo}`)" :alt="activeTab.name" class="h-10 mr-2">
				<span> ({{activeTab.name}})</span>
			</div>
			<p class="mt-3 mb-8">{{ activeTab.description }}</p>
			<p class="text-primary mb-1" v-if="activeHeatmapMinMaxAvgValues">Current:
				<span class="ml-0.5 mr-3">Minimum = {{ activeHeatmapMinMaxAvgValues?.min }}</span>
				<span class="mr-3">Maximum = {{ activeHeatmapMinMaxAvgValues?.max }}</span>
				<span>Average = {{ activeHeatmapMinMaxAvgValues?.avg }}</span>
			</p>
			<!-- End Logo and Description -->
			
			<!-- Content -->
			<div class="tab-content flex">
				
				<form class="inline-flex rounded-lg border-t border-b overflow-hidden">
					<table v-if="activeHeatmapMinMaxAvgValues">
						<thead class="bg-purple-700">
							<tr class="">
								<th class="py-2 px-4 border-l border-r">Color Normal</th>
								<th class="py-2 px-4 border-l border-r">Color Blind</th>
								<th class="py-2 px-4 border-l border-r">>= Min</th>
								<th class="py-2 px-4 border-l border-r">&#60; Max</th>
							</tr>
						</thead>
						<tbody class="text-center">
							<tr
								v-for="(item, index) in colorListSortChanged"
								:key="index"
							>
								<td class="px-4 py-2 border">
                  <span
	                  class="flex w-6 h-6 rounded-sm m-auto"
	                  :style="{'background': `rgb(${item.normal})`}"
                  ></span>
								</td>
								<td class="px-4 py-2 border">
                  <span
	                  class="flex w-6 h-6 rounded-sm m-auto"
	                  :style="{'background': `rgb(${item.blind})`}"
                  ></span>
								</td>
								<td class="px-4 py-2 border">
									<input
										v-model="activeSettingsSortChanged[index].min"
										@blur="updateSettings(activeSettingsSortChanged[index].min, index, 'min')"
										type="number"
										class="outline-none text-black border-gray-700 rounded-lg px-2 py-2 w-20 text-center"
									>
								</td>
								<td class="px-4 py-2 border">
									<input
										v-model="activeSettingsSortChanged[index].max"
										@blur="updateSettings(activeSettingsSortChanged[index].max, index, 'max')"
										type="number"
										class="outline-none text-black border-gray-700 rounded-lg px-2 py-2 w-20 text-center"
									>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td colspan="4" class="text-right px-4 py-2 border-l border-r">
									<button
										@click.prevent="update()"
										class="rounded-lg transition bg-blue-600 hover:bg-blue-700 px-4 py-1.5"
									>Submit
									</button>
								</td>
							</tr>
						</tfoot>
					</table>
				</form>
				
				<div class="ml-16" v-if="activeHeatmapMinMaxAvgValues && !(activeSettings[1].min === 0 && activeSettings[1].max === 0)">
					<user-admin-settings-slider
						:colorList="colorListSortChanged"
						:minMaxValues="activeHeatmapMinMaxAvgValues"
						:settings="activeSettingsSortChanged"
						:activeTabKey="activeTabKey"
						:reversed="reversed"
					/>
				</div>
				
			</div>
			<!-- End Content -->
		
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {coloursScale} from "@/utils/colors";
import BaseToggle from "@/components/Base/BaseToggle"
import UserAdminSettingsSlider from "@/components/UserAdminSettingsSlider";

export default {
	name: 'UserAdminSettings',
	components: {
		UserAdminSettingsSlider,
		BaseToggle
	},
	data() {
		return {
			activeTab: {
				slug: 'crowdstrike',
				name: 'Crowdstrike',
				logo: 'crowdstrike_logo.svg',
				description: 'Operational: Average detections per asset. Please set the metrics below for the minimum and maximum detections per asset for each block of the colour scale.',
				type: 'Operational'
			},
			minMaxList: [
				{min: '', max: ''},
				{min: '', max: ''},
				{min: '', max: ''},
				{min: '', max: ''},
				{min: '', max: ''}
			],
			systemList: [
				{
					slug: 'crowdstrike',
					name: 'Endpoint Security',
					logo: 'crowdstrike_logo.svg',
					description: 'Operational: Average detections per asset. Please set the metrics below for the minimum and maximum detections per asset for each block of the colour scale.'
				},
				{
					slug: 'invicti',
					name: 'Web App Scans',
					logo: 'invicti_logo.png',
					description: 'invicti description'
				},
				{
					slug: 'proofpoint',
					name: 'Email Security',
					logo: 'proofpoint_logo.png',
					description: 'Proofpoint description'
				},
				{
					slug: 'knowbe4',
					name: 'Security Awareness/Training',
					logo: 'knowbe4-logo.png',
					description: 'KnowBe4 description'
				},
				{
					slug: 'spycloud',
					name: 'Digital Risk Management',
					logo: 'SpyCloud-logotype-white.png',
					description: 'SpyCloud description'
				},
				{
					slug: 'cisco',
					name: 'Web Security Gateway',
					logo: 'cisco-logo.png',
					description: 'Cisco description'
				},
				{
					slug: 'rapid7',
					name: 'Vulnerability Management',
					logo: 'rapid7_logo.svg',
					description: 'Vulnerability Management description'
				},
				{
					slug: 'azure',
					name: 'Multi-Factor Authentication',
					logo: 'azure_logo.png',
					description: 'Azure description'
				},
				{
					slug: 'workday',
					name: 'HR Systems',
					logo: 'workday_logo.png',
					description: 'Workday description'
				},
				{
					slug: 'sixclicks',
					name: 'GRC & Risk Management',
					logo: '6clicks-logo.png',
					description: 'Six Clicks description'
				},
				{
					slug: 'scorecard',
					name: '3rd Party Risk Management',
					logo: 'securityscorecard.png',
					description: 'Security Score Card description'
				}
			],
			colorList: [
				{name: 'green', normal: '117, 226, 70', blind: '182, 219, 255'},
				{name: 'yellow', normal: '228, 248, 73', blind: '109, 182, 255'},
				{name: 'orange', normal: '248, 158, 73', blind: '182, 109, 255'},
				{name: 'darkorange', normal: '248, 108, 73', blind: '0, 109, 219'},
				{name: 'red', normal: '248, 73, 73', blind: '73, 0, 146'},
			],
			colorListSortChanged: [
				{name: 'green', normal: '117, 226, 70', blind: '182, 219, 255'},
				{name: 'yellow', normal: '228, 248, 73', blind: '109, 182, 255'},
				{name: 'orange', normal: '248, 158, 73', blind: '182, 109, 255'},
				{name: 'darkorange', normal: '248, 108, 73', blind: '0, 109, 219'},
				{name: 'red', normal: '248, 73, 73', blind: '73, 0, 146'},
			],
			activeSettingsSortChanged: {},
			reversed: false,
			isPreparedHeatmapData: false
		};
	},
	async beforeMount() {
		// get heatmap data
		if(!this.$store.state.heatmap.heatmapData.length) {
			await this.$store.dispatch('heatmap/getHeatmapData');
		}
		
		// Set min, max, avg data into heatmapMinMaxAvgValues that is state structure
		await this.$store.dispatch('dashboardSettings/setMinMaxAvgValues');
		
		await this.activeSettingsChange();
		
		this.isPreparedHeatmapData = true;
	},
	computed: {
		colorBlindMode() {
			return this.$store.state.ui.colorblindMode;
		},
		minMaxListAllSystems() {
			return this.$store.state.ui.minMaxList;
		},
		reversedColoursScale() {
			return [...coloursScale()].reverse();
		},
		settings() {
			if (this.$store.state.dashboardSettings?.data[0]) {
				let {__typename, ...settings} = JSON.parse(JSON.stringify(this.$store.state.dashboardSettings?.data[0]));
				
				// set index instead of color name
				for (let i in settings) {
					settings[i][0] = settings[i].green;
					settings[i][1] = settings[i].yellow;
					settings[i][2] = settings[i].orange;
					settings[i][3] = settings[i].darkorange;
					settings[i][4] = settings[i].red;
					
					delete settings[i].green;
					delete settings[i].yellow;
					delete settings[i].orange;
					delete settings[i].darkorange;
					delete settings[i].red;
				}
				
				return settings;
			} else {
				return {};
			}
		},
		activeTabKey() {
			return `${this.activeTab.slug.toLowerCase()}_${this.activeTab.type.toLowerCase()}`;
		},
		activeSettings() {
			console.log("settings", this.settings)
			return this.settings[this.activeTabKey];
		},
		heatmapMinMaxAvgValues() {
			return this.$store.state.dashboardSettings.heatmapMinMaxAvgValues;
		},
		activeHeatmapMinMaxAvgValues() {
			return this.heatmapMinMaxAvgValues[this.activeTab.slug.toLowerCase()][this.activeTab.type.toLowerCase()];
		},
		colorMode() {
			return this.$store.state.ui.colorblindMode ? 'blind' : 'normal';
		},
	},
	methods: {
		update() {
			let list = Array.from(this.minMaxListAllSystems) //JSON.parse(JSON.stringify())
			list[this.activeTab] = this.minMaxList;
			
			axios
				.post('update_max_list_url', list)
				.then((res) => {
					// this.$store.dispatch('ui/getMinMaxSystemColor'); // get new list after update
				})
		},
		changeColorBlind(val) {
			this.$store.commit('ui/SET_COLORBLIND', val);
		},
		changeActiveTab(item) {
			this.activeTab.slug = item.slug;
			this.activeTab.name = item.name;
			this.activeTab.logo = item.logo;
			this.activeTab.description = item.description;
		},
		updateSettings(newValue, index, type) {
			// console.log(newValue, index, type)
			// let errorArray = [];
			//
			// if(newValue < 0)
			// 	errorArray.push('You can not use negative values.')
			//
			// if(type == 'min') {
			// 	if(this.activeSettings[index].max && newValue > this.activeSettings[index].max)
			// 		errorArray.push('Min must be less than Max.')
			// } else {
			// 	if(newValue < this.activeSettings[index].min)
			// 		errorArray.push('Max must be greater than Min.')
			// }
			//
			// if(newValue < this.activeHeatmapMinMaxAvgValues.min)
			// 	errorArray.push(`You can not use a number less than ${this.activeHeatmapMinMaxAvgValues.min}.`)
			//
			// if(newValue > this.activeHeatmapMinMaxAvgValues.max)
			// 	errorArray.push(`You can not use a number greater than ${this.activeHeatmapMinMaxAvgValues.max}.`)
			//
			// if(errorArray.length > 0) {
			// 	alert(errorArray[0])
			// 	return;
			// }
			
			this.$store.commit('dashboardSettings/UPDATE_DASHBOARD_SETTINGS_BY_INDEX', {
				settings: this.activeSettings,
				activeTabKey: this.activeTabKey
			})
		},
		activeSettingsChange() {
			if(this.reversed) {
				let newObject = {};
				let keys = Object.keys(this.activeSettings).reverse();
				keys.forEach((value, index) => {
					newObject[index] = this.activeSettings[value]
				})
				
				this.activeSettingsSortChanged = newObject;
			} else {
				this.activeSettingsSortChanged = this.activeSettings;
			}
		}
	},
	watch: {
		activeSettings: {
			handler(newValue, oldValue) {
				console.log("active settings", this.activeSettings)
				if (this.activeSettings[0].min > this.activeSettings[1].min) {
					this.reversed = true;
					this.colorListSortChanged = JSON.parse(JSON.stringify(this.colorList));
					this.colorListSortChanged.reverse();
				} else {
					this.reversed = false;
					this.colorListSortChanged = JSON.parse(JSON.stringify(this.colorList));
				}
				
				this.activeSettingsChange();
			},
			deep: true
		}
	}
};
</script>
