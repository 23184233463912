import { createStore } from "vuex";
import auth from "./modules/auth";
import widgets from "./modules/widgets";
import structure from "./modules/structure";
import dashboardSettings from "./modules/dashboard_settings";
import customise from "./modules/customise";
import ui from "./modules/ui";
import heatmap from "./modules/heatmap";
import systemsDetails from "./modules/systems_details";
import heatmapAggregate from "./modules/heatmap_aggregate";

export default createStore({
  modules: {
    auth,
    widgets,
    customise,
    heatmap,
    heatmapAggregate,
    structure,
    dashboardSettings,
    ui,
    systemsDetails,
  },

  actions: {
    resetStore({ commit }) {
      const moduleList = [
        "auth",
        "widgets",
        "customise",
        "heatmap",
        "heatmapAggregate",
        "structure",
        "dashboardSettings",
        "ui",
        "systemsDetails",
      ];
      for (const module of moduleList) {
        commit(`${module}/RESET_STORE`);
      }
    },
  },
});
