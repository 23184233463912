const now = new Date();
export const currentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

export const prevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);

export const prevOfPrevMonth = new Date(
  now.getFullYear(),
  now.getMonth() - 2,
  1
);

export const sixMonthsAgo = new Date(
  now.getFullYear(),
  now.getMonth() - 6,
  1
);
