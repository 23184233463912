<template>
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !orgData?.is_clickable}"
    :to="orgData?.is_clickable ? `/proofpoint/${org.org_id}` : '#'"
    :is="orgData?.is_clickable ? 'router-link' : 'span'"
    @click="isClicked = true"
  >
    <div
      class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
      :class="orgData?.has_data && 'clickable'"
    >
      <div>
	      <span>Email Security</span>
        <BaseTooltip  class="relative ml-2 inline-block" :info="'Email Security description'">
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <div class="flex items-center">
	      <img src="@/assets/proofpoint_logo.png" class="h-6 mr-3 inline-block" />
        <div class="not-clickable-warning text-primary text-sm mr-3" v-if="!orgData?.is_clickable && isClicked">
          <font-awesome-icon
            class="text-primary mr-1"
            :icon="['fa', 'info-circle']"
          />
          <span>No click-through data</span>
        </div>
        <font-awesome-icon
          v-if="orgData?.is_clickable"
          class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
          :icon="['fa', 'arrow-right']"
        />
      </div>
    </div>
    <div
	    class="flex h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
	    v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode)"
    >
	    
	    <!-- First Column -->
	    <div class="flex flex-col items-center px-3 flex-grow justify-center">
		    <div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
			    <font-awesome-icon
				    class="text-xl mr-2"
				    :icon="['fa', 'user-shield']"
				    :class="
                {
                  'text-green-500': (orgDataAggregate.vaps === 0),
                  'text-red-500': (orgDataAggregate.vaps > 0)
                }
              "
			    />
			    <h2 class="text-4xl">{{ orgDataAggregate.vaps || 0 }}</h2>
		    </div>
		    <div class="flex items-center" v-else>
			    <font-awesome-icon
				    class="text-xl mr-2"
				    :icon="['fa', 'user-shield']"
				    :class="
                {
                  'text-green-500': (orgData?.vaps === 0),
                  'text-red-500': (orgData?.vaps > 0)
                }
              "
			    />
			    <h2 class="text-4xl">{{ orgData?.vaps || 0 }}</h2>
		    </div>
		    <p class="text-sm text-gray-400 text-center">
			    very attacked people <br />
			    (last 90 days)
		    </p>
	    </div>
	    <!-- End First Column -->
	    
	    <!-- Second Column -->
	    <div class="flex flex-col items-center px-3 flex-grow border-r border-l border-gray-700 justify-center">
		    <div class="flex items-center">
			    <font-awesome-icon
				    class="text-xl mr-2 text-purple-400"
				    :icon="['fa', 'mouse']"
			    />
			    <h2 class="text-4xl" v-if="isAggregateMode && org.org_type != 'VBU'">{{ orgDataAggregate.top_clickers || 0 }}</h2>
			    <h2 class="text-4xl" v-else>{{ orgData?.top_clickers || 0 }}</h2>
		    </div>
		    <p class="text-sm text-gray-400 text-center">
			    top clickers <br />
			    (last 90 days)
		    </p>
	    </div>
	    <!-- End Second Column -->
	
	    <!-- Third Column -->
	    <div class="flex flex-col border-r border-gray-700">
		    <div class="p-3 border-b border-gray-700">
			    <div class="flex items-center justify-center" v-if="isAggregateMode && org.org_type != 'VBU'">
				    <font-awesome-icon
					    v-if="(orgDataAggregate?.allowed_clicks_last_month || 0) - (orgDataAggregate?.allowed_clicks_last_of_last_month || 0)"
					    class="mr-2"
					    :class="
                (orgDataAggregate?.allowed_clicks_last_month || 0) >
                (orgDataAggregate?.allowed_clicks_last_of_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
					    :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgDataAggregate?.allowed_clicks_last_month || 0) >
                  (orgDataAggregate?.allowed_clicks_last_of_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
				    />
				    <font-awesome-icon
					    v-else
					    class="text-xl mr-2"
					    :class="
					      (!orgDataAggregate?.allowed_clicks_last_month && !orgDataAggregate?.allowed_clicks_last_of_last_month) ?
									'text-green-500' : 'text-yellow-500'
					    "
					    :icon="['fa', 'mouse']"
				    />
				    <h4 class="uppercase">{{ orgDataAggregate.allowed_clicks_last_month }}</h4>
			    </div>
			    <div class="flex items-center justify-center" v-else>
				    <font-awesome-icon
					    v-if="(orgData?.allowed_clicks_last_month || 0) - (orgData?.allowed_clicks_last_of_last_month || 0)"
					    class="mr-2"
					    :class="
                (orgData?.allowed_clicks_last_month || 0) >
                (orgData?.allowed_clicks_last_of_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
					    :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgData?.allowed_clicks_last_month || 0) >
                  (orgData?.allowed_clicks_last_of_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
				    />
				    <font-awesome-icon
					    v-else
					    class="text-xl mr-2"
					    :class="
					      (!orgData?.allowed_clicks_last_month && !orgData?.allowed_clicks_last_of_last_month) ?
									'text-green-500' : 'text-yellow-500'
					    "
					    :icon="['fa', 'mouse']"
				    />
				    <h4 class="uppercase">{{ orgData?.allowed_clicks_last_month }}</h4>
			    </div>
			    <p class="text-xs text-gray-400 text-center">
				    permitted clicks <br />
				    last month
			    </p>
		    </div>
		    <div class="p-3">
			    <div class="flex items-center justify-center" v-if="isAggregateMode && org.org_type != 'VBU'">
				    <font-awesome-icon
					    v-if="(orgDataAggregate?.blocked_clicks_last_month || 0) - (orgDataAggregate?.blocked_clicks_last_of_last_month || 0)"
					    class="mr-2"
					    :class="
                (orgDataAggregate?.blocked_clicks_last_month || 0) >
                (orgDataAggregate?.blocked_clicks_last_of_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
					    :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgDataAggregate?.blocked_clicks_last_month || 0) >
                  (orgDataAggregate?.blocked_clicks_last_of_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
				    />
				    <font-awesome-icon
					    v-else
					    class="text-xl mr-2"
					    :class="
					      (!orgDataAggregate?.blocked_clicks_last_month && !orgDataAggregate?.blocked_clicks_last_of_last_month) ?
									'text-green-500' : 'text-yellow-500'
					    "
					    :icon="['fa', 'mouse']"
				    />
				    <h4 class="uppercase">
					    {{ orgDataAggregate.blocked_clicks_last_month }}
				    </h4>
			    </div>
			    <div class="flex items-center justify-center" v-else>
				    <font-awesome-icon
					    v-if="(orgData?.blocked_clicks_last_month || 0) - (orgData?.blocked_clicks_last_of_last_month || 0)"
					    class="mr-2"
					    :class="
                (orgData?.blocked_clicks_last_month || 0) >
                (orgData?.blocked_clicks_last_of_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
					    :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgData?.blocked_clicks_last_month || 0) >
                  (orgData?.blocked_clicks_last_of_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
				    />
				    <font-awesome-icon
					    v-else
					    class="text-xl mr-2"
					    :class="
					      (!orgData?.blocked_clicks_last_month && !orgData?.blocked_clicks_last_of_last_month) ?
									'text-green-500' : 'text-yellow-500'
					    "
					    :icon="['fa', 'mouse']"
				    />
				    <h4 class="uppercase">
					    {{ orgData?.blocked_clicks_last_month }}
				    </h4>
			    </div>
			    <p class="text-xs text-gray-400 text-center">
				    blocked clicks <br />
				    last month
			    </p>
		    </div>
	    </div>
	    <!-- End Third Column -->

	    <!-- Fourth Column -->
      <div class="flex flex-col">
	      <div class="p-3 border-b border-gray-700">
		      <div class="flex items-center justify-center" v-if="isAggregateMode && org.org_type != 'VBU'">
			      <font-awesome-icon
				      v-if="(orgDataAggregate?.allowed_clicks_this_month || 0) - (orgDataAggregate?.allowed_clicks_last_month || 0)"
				      class="mr-2"
				      :class="
                (orgDataAggregate?.allowed_clicks_this_month || 0) >
                (orgDataAggregate?.allowed_clicks_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
				      :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgDataAggregate?.allowed_clicks_this_month || 0) >
                  (orgDataAggregate?.allowed_clicks_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
			      />
			      <font-awesome-icon
				      v-else
				      class="text-xl mr-2"
				      :class="
					      (!orgDataAggregate?.allowed_clicks_this_month && !orgDataAggregate?.allowed_clicks_last_month) ?
									'text-green-500' : 'text-yellow-500'
					    "
				      :icon="['fa', 'mouse']"
			      />
			      <h4>{{ orgDataAggregate.allowed_clicks_this_month }}</h4>
		      </div>
		      <div class="flex items-center justify-center" v-else>
			      <font-awesome-icon
				      v-if="(orgData?.allowed_clicks_this_month || 0) - (orgData?.allowed_clicks_last_month || 0)"
				      class="mr-2"
				      :class="
                (orgData?.allowed_clicks_this_month || 0) >
                (orgData?.allowed_clicks_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
				      :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgData?.allowed_clicks_this_month || 0) >
                  (orgData?.allowed_clicks_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
			      />
			      <font-awesome-icon
				      v-else
				      class="text-xl mr-2"
				      :class="
					      (!orgData?.allowed_clicks_this_month && !orgData?.allowed_clicks_last_month) ?
									'text-green-500' : 'text-yellow-500'
					    "
				      :icon="['fa', 'mouse']"
			      />
			      <h4>{{ orgData?.allowed_clicks_this_month }}</h4>
		      </div>
		      <p class="text-xs text-gray-400 text-center">
			      permitted clicks <br />
			      this month
		      </p>
	      </div>
        <div class="p-3">
          <div class="flex items-center justify-center" v-if="isAggregateMode && org.org_type != 'VBU'">
	          <font-awesome-icon
		          v-if="(orgDataAggregate?.blocked_clicks_this_month || 0) - (orgDataAggregate?.blocked_clicks_last_month || 0)"
		          class="mr-2"
		          :class="
                (orgDataAggregate?.blocked_clicks_this_month || 0) >
                (orgDataAggregate?.blocked_clicks_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
		          :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgDataAggregate?.blocked_clicks_this_month || 0) >
                  (orgDataAggregate?.blocked_clicks_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
	          />
	          <font-awesome-icon
		          v-else
		          class="text-xl mr-2"
		          :class="
					      (!orgDataAggregate?.blocked_clicks_this_month && !orgDataAggregate?.blocked_clicks_last_month) ?
									'text-green-500' : 'text-yellow-500'
					    "
		          :icon="['fa', 'mouse']"
	          />
            <h4>{{ orgDataAggregate.blocked_clicks_this_month }}</h4>
          </div>
          <div class="flex items-center justify-center" v-else>
	          <font-awesome-icon
		          v-if="(orgData?.blocked_clicks_this_month || 0) - (orgData?.blocked_clicks_last_month || 0)"
		          class="mr-2"
		          :class="
                (orgData?.blocked_clicks_this_month || 0) >
                (orgData?.blocked_clicks_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
		          :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgData?.blocked_clicks_this_month || 0) >
                  (orgData?.blocked_clicks_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
	          />
	          <font-awesome-icon
		          v-else
		          class="text-xl mr-2"
		          :class="
					      (!orgData?.blocked_clicks_this_month && !orgData?.blocked_clicks_last_month) ?
									'text-green-500' : 'text-yellow-500'
					    "
		          :icon="['fa', 'mouse']"
	          />
            <h4>{{ orgData?.blocked_clicks_this_month }}</h4>
          </div>
          <p class="text-xs text-gray-400 text-center">
            blocked clicks <br />
            this month
          </p>
        </div>
      </div>
	    <!-- End Fourth Column -->
	    
    </div>
    <div class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg" v-else>
      <p class="py-8 opacity-50">No users found.</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
  components: {
    BaseTooltip,
  },
  data() {
    return {
      isClicked: false
    }
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
	  orgData: {
		  type: Object,
		  required: true,
	  }
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgDataAggregate() {
	    let VBUs = this.org.VBUs;
	    let widgetsData = this.$store.state.widgets.widgetsData;
	
	    if (VBUs && VBUs.length) {
		    let dataArr = [];
		    VBUs.forEach((VBUItem) => {
			    widgetsData?.forEach((orgItem) => {
				    if(VBUItem.org_id === +orgItem.org_id) {
					    dataArr.push({
						    blocked_clicks_this_month: orgItem.data.proofpoint.blocked_clicks_this_month || 0,
						    blocked_clicks_last_month: orgItem.data.proofpoint.blocked_clicks_last_month || 0,
						    blocked_clicks_last_of_last_month: orgItem.data.proofpoint.blocked_clicks_last_of_last_month || 0,
						    allowed_clicks_this_month: orgItem.data.proofpoint.allowed_clicks_this_month || 0,
						    allowed_clicks_last_month: orgItem.data.proofpoint.allowed_clicks_last_month || 0,
						    allowed_clicks_last_of_last_month: orgItem.data.proofpoint.allowed_clicks_last_of_last_month || 0,
						    vaps: orgItem.data.proofpoint.vaps || 0,
						    top_clickers: orgItem.data.proofpoint.top_clickers || 0
					    })
				    }
			    })
		    })
		
		    let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
			    return {
				    blocked_clicks_this_month: (sumObj.blocked_clicks_this_month || 0) + (currentObj.blocked_clicks_this_month || 0),
				    blocked_clicks_last_month: (sumObj.blocked_clicks_last_month || 0) + (currentObj.blocked_clicks_last_month || 0),
				    blocked_clicks_last_of_last_month: (sumObj.blocked_clicks_last_of_last_month || 0) + (currentObj.blocked_clicks_last_of_last_month || 0),
				    allowed_clicks_this_month: (sumObj.allowed_clicks_this_month || 0) + (currentObj.allowed_clicks_this_month || 0),
				    allowed_clicks_last_month: (sumObj.allowed_clicks_last_month || 0) + (currentObj.allowed_clicks_last_month || 0),
				    allowed_clicks_last_of_last_month: (sumObj.allowed_clicks_last_of_last_month || 0) + (currentObj.allowed_clicks_last_of_last_month || 0),
				    vaps: (sumObj.vaps || 0) + (currentObj.vaps || 0),
				    top_clickers: (sumObj.top_clickers || 0) + (currentObj.top_clickers || 0),
			    }
		    }, 0)
		
		    return {
			    blocked_clicks_this_month: sumVBUs.blocked_clicks_this_month,
			    blocked_clicks_last_month: sumVBUs.blocked_clicks_last_month,
			    blocked_clicks_last_of_last_month: sumVBUs.blocked_clicks_last_of_last_month,
			    allowed_clicks_this_month: sumVBUs.allowed_clicks_this_month,
			    allowed_clicks_last_month: sumVBUs.allowed_clicks_last_month,
			    allowed_clicks_last_of_last_month: sumVBUs.allowed_clicks_last_of_last_month,
			    vaps: sumVBUs.vaps,
			    top_clickers: sumVBUs.top_clickers,
		    };
	    } else {
		    return {}
	    }
    },
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>
