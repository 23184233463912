<template>
	<router-link
		class="card flex flex-col"
		:class="{'cursor-auto': !orgData?.is_clickable}"
		:to="orgData?.is_clickable ? `/crowdstrike/${org.org_id}` : '#'"
		@click="isClicked = true"
	>
		<!-- :to="`/crowdstrike/${vbu.name.replaceAll(' ', '_')}-&-${'vbu.id' || '#'}`" -->
		<div
			class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
			:class="this.org && 'clickable'"
		>
			<div>
				<span>Endpoint Security</span>
				<BaseTooltip class="relative ml-2 inline-block" :info="'Endpoint Security description'">
					<font-awesome-icon
						class="text-gray-500"
						:icon="['fa', 'info-circle']"
					/>
				</BaseTooltip>
			</div>
			<div class="flex items-center">
				<img src="@/assets/crowdstrike_logo.svg" class="h-6 mr-3 inline-block"/>
				<div class="not-clickable-warning text-primary text-sm mr-3" v-if="!orgData?.is_clickable && isClicked">
					<font-awesome-icon
						class="text-primary mr-1"
						:icon="['fa', 'info-circle']"
					/>
					<span>No click-through data</span>
				</div>
				<font-awesome-icon
					v-if="orgData?.is_clickable"
					class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
					:icon="['fa', 'arrow-right']"
				/>
			</div>
		
		</div>
		<div
			class="flex border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
			v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode)"
		>
			
			<!-- Detections Last Month and Assets -->
			<div class="flex flex-col w-full">
				<div class="px-1 py-3 border-b border-gray-700">
					<div class="flex items-center justify-center">
						<font-awesome-icon
							class="text-purple-400 text-sm mr-2"
							:icon="['fa', 'search']"
						/>
						<h4 v-if="isAggregateMode && org.org_type != 'VBU'">{{ orgDataAggregate.detections_last_month }}</h4>
						<h4 v-else>{{ orgData?.detections_last_month }}</h4>
					</div>
					<p class="text-xs text-gray-400 text-center">detections last month</p>
				</div>
				<div class="px-1 py-3">
					<div class="flex items-center justify-center">
						<font-awesome-icon
							class="text-yellow-400 text-sm mr-2"
							:icon="['fa', 'server']"
						/>
						<h4 v-if="isAggregateMode && org.org_type != 'VBU'">{{ orgDataAggregate.recent_crowdstrike_assets }}</h4>
						<h4 v-else>{{ orgData?.recent_crowdstrike_assets  }}</h4>
					</div>
					<p class="text-xs text-gray-400 text-center">assets seen in<br/>last seven days</p>
				</div>
			</div>
			<!-- End Detections Last Month and Assets -->
			
			<!-- Spacer -->
			<div class="self-stretch border-l border-gray-700"></div>
			<!-- End Spacer -->
			
			<!-- Unresolved Escalations -->
			<div class="flex flex-col items-center justify-center border-r border-gray-700 w-full px-1">
				<div class="flex items-center">
					<div v-if="isAggregateMode && org.org_type != 'VBU'">
						<font-awesome-icon
							class="text-xl mr-2 text-green-500"
							:icon="['fa', 'shield-alt']"
							v-if="orgDataAggregate.unresolved_escalations == 0"
						/>
						<font-awesome-icon
							class="text-xl mr-2 text-yellow-500"
							:icon="['fa', 'exclamation-triangle']"
							v-else
						/>
					</div>
					<div v-else>
						<font-awesome-icon
							class="text-xl mr-2 text-green-500"
							:icon="['fa', 'shield-alt']"
							v-if="orgData.unresolved_escalations == 0"
						/>
						<font-awesome-icon
							class="text-xl mr-2 text-yellow-500"
							:icon="['fa', 'exclamation-triangle']"
							v-else
						/>
					</div>
					<h2 class="text-4xl" v-if="isAggregateMode && org.org_type != 'VBU'">{{ orgDataAggregate.unresolved_escalations }}</h2>
					<h2 class="text-4xl" v-else>{{ orgData?.unresolved_escalations || 0 }}</h2>
				</div>
				<p class="text-sm text-gray-400 text-center">unresolved escalations</p>
			</div>
			<!-- End Unresolved Escalations -->
			
			<!-- Detections This Month -->
			<div class="flex flex-col items-center justify-center border-r border-gray-700 w-full px-1">
				<div class="flex items-center">
					<font-awesome-icon
						v-if="isDetectionHigher !== null"
						class="text-xl mr-2"
						:class="isDetectionHigher ? 'text-red-500' : 'text-green-500'"
						:icon="[
              'fa',
              `arrow-alt-circle-${isDetectionHigher ? 'up' : 'down'}`,
            ]"
					/>
					<h2 class="text-4xl" v-if="isAggregateMode && org.org_type != 'VBU'">
						{{ orgDataAggregate?.detections_this_month }}
					</h2>
					<h2 class="text-4xl" v-else>
						{{ orgData?.detections_this_month }}
					</h2>
				</div>
				<p class="text-sm text-gray-400">detections this month</p>
			</div>
			<!-- End Detections This Month -->
			
		</div>
		<div class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg" v-else>
			<p class="py-8 opacity-50">
				We don't have any data for this organisation.
			</p>
		</div>
	</router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
	components: {
		BaseTooltip,
	},
	data() {
		return {
			isClicked: false
		}
	},
	props: {
		org: {
			type: Object,
			required: true,
		},
		orgData: {
			type: Object,
			required: true,
		}
	},
	computed: {
		isAggregateMode() {
			return this.$store.state.structure.isAggregateMode;
		},
		orgDataAggregate() {
			let VBUs = this.org.VBUs;
			let widgetsData = this.$store.state.widgets.widgetsData;
			
			if (VBUs && VBUs.length) {
				let dataArr = [];
				VBUs.forEach((VBUItem) => {
					widgetsData?.forEach((orgItem) => {
						if (VBUItem.org_id === +orgItem.org_id) {
							dataArr.push({
								detections_this_month: orgItem.data.crowdstrike.detections_this_month || 0,
								unresolved_escalations: orgItem.data.crowdstrike.unresolved_escalations || 0,
								recent_crowdstrike_assets: orgItem.data.crowdstrike.recent_crowdstrike_assets || 0,
								detections_last_month: orgItem.data.crowdstrike.detections_last_month || 0
							})
						}
					})
				})
				
				let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
					return {
						detections_this_month: (sumObj.detections_this_month || 0) + (currentObj.detections_this_month || 0),
						unresolved_escalations: (sumObj.unresolved_escalations || 0) + (currentObj.unresolved_escalations || 0),
						recent_crowdstrike_assets: (sumObj.recent_crowdstrike_assets || 0) + (currentObj.recent_crowdstrike_assets || 0),
						detections_last_month: (sumObj.detections_last_month || 0) + (currentObj.detections_last_month || 0),
					}
				}, 0)
				
				return {
					detections_this_month: sumVBUs.detections_this_month,
					unresolved_escalations: sumVBUs.unresolved_escalations,
					recent_crowdstrike_assets: sumVBUs.recent_crowdstrike_assets,
					detections_last_month: sumVBUs.detections_last_month,
				};
			} else {
				return {}
			}
		},
		isDetectionHigher() {
			return this.orgData?.detections_this_month >
			this.orgData?.detections_last_month ?
				true :
				(this.orgData?.detections_this_month == this.orgData?.detections_last_month) ? null : false;
		},
	}
};
</script>

<style lang="scss" scoped>
.card {
	&--title {
		@apply transition-colors duration-300 relative;
		
		h4 {
			@apply relative pl-2;
			
			&::before {
				@apply w-2 h-2 rounded-full bg-white absolute top-2 -left-2 opacity-20;
				
				content: '';
			}
			
			// border-bottom: 1px rgba(255, 255, 255, 0.467) dashed;
			
			&.group::before {
				@apply opacity-100;
				background-color: rgb(90, 192, 30);
			}
			
			&.portfolio::before {
				@apply opacity-100 bg-blue-500;
			}
		}
	}
	
	&:hover {
		.card--title {
			background-color: rgba(52, 52, 62, 1);
			
			.icon {
				@apply text-gray-400;
			}
		}
	}
}
</style>
