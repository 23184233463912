<template>
  <div class="hello flex">
<div class="flex-auto ml-5">
  
    <h2 class="text-xl text-gray-300">Update password</h2>
    <div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
    <form class="max-w-lg" @submit.prevent="updatePassword">
      <div>
        <div v-if="!isLoading">
          <input-base
            class="w-full mt-4"
            name="currentPw"
            label="Current password:"
            type="password"
            placeholder="Current password"
            :value="currentPw"
            :showLabel="false"
            :required="true"
            @change="
              (event) => {
                currentPw = event.target.value;
              }
            "
            :icon="['fas', 'user-shield']"
          />
          <input-base
            class="w-full mt-4"
            name="newPw"
            label="New password"
            type="password"
            placeholder="New password"
            :value="newPw"
            :showLabel="false"
            :required="true"
            @change="
              (event) => {
                newPw = event.target.value;
              }
            "
            :icon="['fas', 'lock']"
          />
          <input-base
            class="w-full mt-4"
            name="newPwConfirm"
            label="Password:"
            type="password"
            placeholder="Confirm new password"
            :value="newPwConfirm"
            :showLabel="false"
            :required="true"
            @change="
              (event) => {
                newPwConfirm = event.target.value;
              }
            "
            :icon="['fas', 'lock']"
          />
          <button class="btn mt-8 px-10" type="submit">Update Password</button>
        </div>
        <div class="w-10 h-10 mx-auto my-12" v-else>
          <SpinnerBase />
        </div>
        <div class="rounded-md border border-red-400 p-4 mt-4" v-if="error">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/x-circle -->
              <svg
                class="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-red-400">
                {{ error }}
              </p>
            </div>
          </div>
        </div>
        <div class="rounded-md border border-green-400 p-4 mt-4" v-if="message">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-green-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-green-400">
                {{ message }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
</div>
  </div>
</template>

<script>
import axios from 'axios';
import SpinnerBase from '@/components/Base/SpinnerBase';

import InputBase from '@/components/Base/InputBase';

export default {
  name: 'UserUpdatePassword',
  components: {
    InputBase,
    SpinnerBase,
  },
  data() {
    return {
      currentPw: '',
      newPw: '',
      newPwConfirm: '',
      isLoading: false,
      error: '',
      message: '',
    };
  },
  // mixins: [validationMixin],
  // validations: {
  //   currentPw: {
  //     required,
  //   },
  //   newPw: {
  //     required,
  //   },
  //   newPwConfirm: {
  //     required,
  //     sameAs: sameAs('newPw'),
  //   },
  // },
  methods: {
    async updatePassword() {
      // this.$v.$touch();

      this.error = '';
      this.message = '';

      const data = {
        password: this.currentPw,
        passwordNew: this.newPw,
        passwordNewConfirm: this.newPwConfirm,
      };

      try {
        this.isLoading = true;

        const res = await axios.post(
          `https://id-ciso.redsmart.app/password_update`,
          data,
          {
            withCredentials: true,
          }
        );

        if (res.status > 399 && res.status < 200) {
          throw new Error();
        }

        this.message = 'Your password has been updated';

        this.isLoading = false;
      } catch (e) {
        console.log(e.response.data.message);
        this.error = e.response.data.message;

        this.isLoading = false;
      }
    },
  },
  computed: {},
};
</script>
