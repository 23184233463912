<template>
	<router-link
		class="card flex flex-col"
		:class="{'cursor-auto': !orgData?.is_clickable}"
		:to="orgData?.is_clickable ? `/vulnerability_management/${org.org_id}` : '#'"
		@click="isClicked = true"
	>
		<div
			class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
			:class="orgData?.has_data && 'clickable'"
		>
			<div>
				<div class="flex items-center">
					<span>Vulnerability Management</span>
					<BaseTooltip
						class="relative ml-2 inline-block"
						:info="'Vulnerability Management description'"
					>
						<font-awesome-icon
							class="text-gray-500"
							:icon="['fa', 'info-circle']"
						/>
					</BaseTooltip>
				</div>
			</div>
			<div class="flex items-center">
				<img src="@/assets/rapid7_logo.svg" class="h-5 mt-1 mr-3 inline-block"/>
				<div v-if="orgData?.calculations_rapidsev_version == 'both'" class="relative">
					<div class="w-2 h-2 both bg-blue-500 rounded-full mr-3 mt-1"></div>
					<p class="absolute w-48 bothp top-6 text-sm -right-20 z-10 bg-bgLighter text-gray-300 border-[0.5px] border-gray-500 border-opacity-80 rounded-md px-2.5 py-2">Organisation has deployed their own Rapid7 platform but still has agents deployed on the previous version.</p>
				</div>
				<div v-else-if="orgData?.calculations_rapidsev_version == 'new'" class="relative">
					<div class="w-2 h-2 new bg-green-500 rounded-full mr-2 mt-1"></div>
					<p class="absolute w-48 newp top-6 text-sm -right-20 z-10 bg-bgLighter text-gray-300 border-[0.5px] border-gray-500 border-opacity-80 rounded-md px-2.5 py-2">Organisation has deployed their own Rapid7 platform and has no assets deployed to the previous version.</p>
				</div>
				<div v-else-if="orgData?.calculations_rapidsev_version == 'old'" class="relative">
					<div class="w-2 h-2 old bg-yellow-600 rounded-full mr-2 mt-1"></div>
					<p class="absolute w-48 oldp top-6 text-sm -right-20 z-10 bg-bgLighter text-gray-300 border-[0.5px] border-gray-500 border-opacity-80 rounded-md px-2.5 py-2">Organisation has not yet deployed their own Rapid7 platform</p>	
				</div>

				<div class="not-clickable-warning text-primary text-sm mr-3" v-if="!orgData?.is_clickable && isClicked">
					<font-awesome-icon
						class="text-primary mr-1"
						:icon="['fa', 'info-circle']"
					/>
					<span>No click-through data</span>
				</div>
				<font-awesome-icon
					v-if="orgData?.has_data"
					class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
					:icon="['fa', 'arrow-right']"
				/>
			</div>
		</div>
		<div
			class="w-full h-full flex items-center border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
			v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode)"
		>
			
			<!-- Critical Vulnerability Last Month -->
			<div class="flex flex-col items-center px-3 py-4 w-full">
				<div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
					<font-awesome-icon
						class="text-xl mr-2 text-green-500"
						:class="
              (orgDataAggregate.critical_vulnerabilities_last_month || 0) >
              (orgDataAggregate.critical_vulnerabilities_last_of_last_month || 0)
                ? 'text-red-500'
                : 'text-green-500'
            "
						:icon="[
              'fa',
              `arrow-alt-circle-${
                (orgDataAggregate.critical_vulnerabilities_last_month || 0) >
                (orgDataAggregate.critical_vulnerabilities_last_of_last_month || 0)
                  ? 'up'
                  : 'down'
              }`,
            ]"
					/>
					<h2 class="text-4xl">{{ formatBigNum(orgDataAggregate.critical_vulnerabilities_last_month) }}</h2>
				</div>
				<div class="flex items-center" v-else>
					<font-awesome-icon
						class="text-xl mr-2 text-green-500"
						:class="
              (orgData?.critical_vulnerabilities_last_month || 0) >
              (orgData?.critical_vulnerabilities_last_of_last_month || 0)
                ? 'text-red-500'
                : 'text-green-500'
            "
						:icon="[
              'fa',
              `arrow-alt-circle-${
                (orgData?.critical_vulnerabilities_last_month || 0) >
                (orgData?.critical_vulnerabilities_last_of_last_month || 0)
                  ? 'up'
                  : 'down'
              }`,
            ]"
					/>
					<h2 class="text-4xl">{{ orgData?.critical_vulnerabilities_last_month }}</h2>
				</div>
				<p class="text-sm text-gray-400 text-center">critical vulnerabilities<br/>last month</p>
			</div>
			<!-- End Critical Vulnerability Last Month -->
			
			<!-- Spacer -->
			<div class="self-stretch border-l border-gray-700"></div>
			<!-- End Spacer -->
			
			<!-- Critical Vulnerability This Month -->
			<div class="flex flex-col items-center px-3 py-4 w-full">
				<div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
					<font-awesome-icon
						class="text-xl mr-2 text-green-500"
						:class="
              (orgDataAggregate.critical_vulnerabilities_this_month || 0) >
              (orgDataAggregate.critical_vulnerabilities_last_month || 0)
                ? 'text-red-500'
                : 'text-green-500'
            "
						:icon="[
              'fa',
              `arrow-alt-circle-${
                (orgDataAggregate.critical_vulnerabilities_this_month || 0) >
                (orgDataAggregate.critical_vulnerabilities_last_month || 0)
                  ? 'up'
                  : 'down'
              }`,
            ]"
					/>
					<h2 class="text-4xl">{{ formatBigNum(orgDataAggregate.critical_vulnerabilities_this_month) }}</h2>
				</div>
				<div class="flex items-center" v-else>
					<font-awesome-icon
						class="text-xl mr-2 text-green-500"
						:class="
              (orgData?.critical_vulnerabilities_this_month || 0) >
              (orgData?.critical_vulnerabilities_last_month || 0)
                ? 'text-red-500'
                : 'text-green-500'
            "
						:icon="[
              'fa',
              `arrow-alt-circle-${
                (orgData?.critical_vulnerabilities_this_month || 0) >
                (orgData?.critical_vulnerabilities_last_month || 0)
                  ? 'up'
                  : 'down'
              }`,
            ]"
					/>
					<h2 class="text-4xl">{{ orgData?.critical_vulnerabilities_this_month }}</h2>
				</div>
				<p class="text-sm text-gray-400 text-center">critical vulnerabilities<br/>this month</p>
			</div>
			<!-- End Critical Vulnerability This Month -->
			
			<!-- Spacer -->
			<div class="self-stretch border-l border-gray-700"></div>
			<!-- End Spacer -->
			
			<!-- Assets -->
			<div class="flex flex-col items-center justify-center h-full px-3 py-4 w-full">
				<div class="flex items-center">
					<font-awesome-icon
						class="text-xl mr-2 text-indigo-500"
						:icon="['fa', 'server']"
					/>
					<h2 class="text-4xl" v-if="isAggregateMode && org.org_type != 'VBU'">{{ formatBigNum(orgDataAggregate.num_assets) }}</h2>
					<h2 class="text-4xl" v-else>{{ orgData?.num_assets }}</h2>
				</div>
				<p class="text-sm text-gray-400 text-center">assets</p>
			</div>
			<!-- End Assets -->
			
		</div>
		<div class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg" v-else>
			<p class="py-8 opacity-50">No Vulnerability Management account connected.</p>
		</div>
	</router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';
import {formatBigNum} from '@/utils/utils';

export default {
	components: {
		BaseTooltip,
	},
	data() {
		return {
			isClicked: false
		}
	},
	props: {
		org: {
			type: Object,
			required: true,
		},
		orgData: {
			type: Object,
			required: true,
		}
	},
	computed: {
		isAggregateMode() {
			return this.$store.state.structure.isAggregateMode;
		},
		orgDataAggregate() {
			let VBUs = this.org.VBUs;
			let widgetsData = this.$store.state.widgets.widgetsData;
			
			if (VBUs && VBUs.length) {
				let dataArr = [];
				VBUs.forEach((VBUItem) => {
					widgetsData?.forEach((orgItem) => {
						if(VBUItem.org_id === +orgItem.org_id) {
							dataArr.push({
								critical_vulnerabilities_this_month: orgItem.data.vulnerability_management.critical_vulnerabilities_this_month || 0,
								critical_vulnerabilities_last_month: orgItem.data.vulnerability_management.critical_vulnerabilities_last_month || 0,
								critical_vulnerabilities_last_of_last_month: orgItem.data.vulnerability_management.critical_vulnerabilities_last_of_last_month || 0,
								num_assets: orgItem.data.vulnerability_management.num_assets || 0,
							})
						}
					})
				})
				
				let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
					return {
						critical_vulnerabilities_this_month: (sumObj.critical_vulnerabilities_this_month || 0) + (currentObj.critical_vulnerabilities_this_month || 0),
						critical_vulnerabilities_last_month: (sumObj.critical_vulnerabilities_last_month || 0) + (currentObj.critical_vulnerabilities_last_month || 0),
						critical_vulnerabilities_last_of_last_month: (sumObj.critical_vulnerabilities_last_of_last_month || 0) + (currentObj.critical_vulnerabilities_last_of_last_month || 0),
						num_assets: (sumObj.num_assets || 0) + (currentObj.num_assets || 0),
					}
				}, 0)
				
				return {
					critical_vulnerabilities_this_month: sumVBUs.critical_vulnerabilities_this_month,
					critical_vulnerabilities_last_month: sumVBUs.critical_vulnerabilities_last_month,
					critical_vulnerabilities_last_of_last_month: sumVBUs.critical_vulnerabilities_last_of_last_month,
					num_assets: sumVBUs.num_assets,
				};
			} else {
				return {}
			}
		}
	},
	methods: {
		formatBigNum,
	}
};
</script>

<style lang="scss" scoped>
.card {
	&--title {
		@apply transition-colors duration-300 relative;
	}
	
	&:hover {
		.card--title.clickable {
			background-color: rgba(52, 52, 62, 1);
			
			.icon {
				@apply text-gray-400;
			}
		}
	}
}

.bothp, .newp, .oldp {
	display: none;
}

.both:hover + .bothp {
	display: block;
}

.new:hover + .newp {
	display: block;
}

.old:hover + .oldp {
	display: block;
}

</style>
