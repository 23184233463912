<template>
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !orgData?.is_clickable}"
    :to="orgData?.is_clickable ? `/workday/${org.org_id}` : '#'"
    @click="isClicked = true"
  >
    <div
      class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
      :class="orgData?.has_data && 'clickable'"
    >
      <div>
        <span>HR Systems</span>
        <BaseTooltip
          class="relative ml-2 inline-block"
          :info="'Late Leavers Tracking description'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <div class="flex items-center">
        <img src="@/assets/workday_logo.png" class="h-6 mr-3 inline-block" />
        <div
          class="not-clickable-warning text-primary text-sm mr-3"
          v-if="!orgData?.is_clickable && isClicked"
        >
          <font-awesome-icon
            class="text-primary mr-1"
            :icon="['fa', 'info-circle']"
          />
          <span>No click-through data</span>
        </div>
        <font-awesome-icon
          v-if="orgData?.is_clickable"
          class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
          :icon="['fa', 'arrow-right']"
        />
      </div>
    </div>
    <div
      class="w-full h-full flex items-center border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode)"
    >
      <div class="flex flex-col items-center px-3 py-4 w-full">
        <div
          class="flex items-center"
          v-if="isAggregateMode && org.org_type != 'VBU'"
        >
          <font-awesome-icon
            class="text-xl mr-2"
            :icon="['fa', 'clock']"
            :style="{ color: `rgb(${maxProcessDelayIconColor})` }"
          />
          <h2 class="text-4xl">
            {{ orgDataAggregate.longest_leaver_delay_this_year || 0 }}
          </h2>
        </div>
        <div class="flex items-center" v-else>
          <font-awesome-icon
            class="text-xl mr-2"
            :icon="['fa', 'clock']"
            :style="{ color: `rgb(${maxProcessDelayIconColor})` }"
          />
          <h2 class="text-4xl">
            {{ orgData?.longest_leaver_delay_this_year || 0 }}
          </h2>
        </div>
        <p class="text-sm text-gray-400 text-center">
          longest leaver
          <br />
          delay this year
        </p>
      </div>

      <div class="self-stretch border-l border-gray-700"></div>

      <div class="flex flex-col items-center px-3 py-4 w-full">
        <div
          class="flex items-center"
          v-if="isAggregateMode && org.org_type != 'VBU'"
        >
          <font-awesome-icon
            class="text-xl mr-2"
            :icon="['fa', 'business-time']"
            :class="{
              'text-green-500':
                orgDataAggregate.avg_days_processing_leaver === 0 ||
                orgDataAggregate.late_leavers_prev_quarter === 0,
              'text-yellow-500':
                orgDataAggregate.late_leavers_prev_quarter > 0 &&
                orgDataAggregate.avg_days_processing_leaver > 0 &&
                orgDataAggregate.avg_days_processing_leaver <= 30,
              'text-red-500':
                orgDataAggregate.late_leavers_prev_quarter > 0 &&
                orgDataAggregate.avg_days_processing_leaver > 30,
            }"
          />
          <h2 class="text-4xl">
            {{ orgDataAggregate.late_leavers_prev_quarter }}
          </h2>
        </div>
        <div class="flex items-center" v-else>
          <font-awesome-icon
            class="text-xl mr-2"
            :icon="['fa', 'business-time']"
            :class="{
              'text-green-500':
                orgData?.avg_days_processing_leaver === 0 ||
                orgData?.late_leavers_prev_quarter === 0,
              'text-yellow-500':
                orgData?.late_leavers_prev_quarter > 0 &&
                orgData?.avg_days_processing_leaver > 0 &&
                orgData?.avg_days_processing_leaver <= 30,
              'text-red-500':
                orgData?.late_leavers_prev_quarter > 0 &&
                orgData?.avg_days_processing_leaver > 30,
            }"
          />
          <h2 class="text-4xl">
            {{ orgData?.late_leavers_prev_quarter || 0 }}
          </h2>
        </div>
        <p class="text-sm text-gray-400 text-center">
          late leavers for<br />
          previous quarter
        </p>
      </div>

      <div class="self-stretch border-l border-gray-700"></div>

      <div
        class="flex flex-col items-center justify-center h-full px-3 py-4 w-full"
      >
        <div
          class="flex items-center"
          v-if="isAggregateMode && org.org_type != 'VBU'"
        >
          <font-awesome-icon
            class="text-xl mr-2"
            :icon="['fa', 'user-clock']"
            :class="{
              'text-green-500':
                orgDataAggregate.avg_days_processing_leaver === 0 ||
                orgDataAggregate.late_leavers_this_quarter === 0,
              'text-yellow-500':
                orgDataAggregate.late_leavers_this_quarter > 0 &&
                orgDataAggregate.avg_days_processing_leaver > 0 &&
                orgDataAggregate.avg_days_processing_leaver <= 30,
              'text-red-500':
                orgDataAggregate.late_leavers_this_quarter > 0 &&
                orgDataAggregate.avg_days_processing_leaver > 30,
            }"
          />

          <h2 class="text-4xl">
            {{ orgDataAggregate.late_leavers_this_quarter }}
          </h2>
        </div>
        <div class="flex items-center" v-else>
          <font-awesome-icon
            class="text-xl mr-2"
            :icon="['fa', 'user-clock']"
            :class="{
              'text-green-500':
                orgData?.avg_days_processing_leaver === 0 ||
                orgData?.late_leavers_this_quarter === 0,
              'text-yellow-500':
                orgData?.late_leavers_this_quarter > 0 &&
                orgData?.avg_days_processing_leaver > 0 &&
                orgData?.avg_days_processing_leaver <= 30,
              'text-red-500':
                orgData?.late_leavers_this_quarter > 0 &&
                orgData?.avg_days_processing_leaver > 30,
            }"
          />

          <h2 class="text-4xl">
            {{ orgData?.late_leavers_this_quarter || 0 }}
          </h2>
        </div>
        <p class="text-sm text-gray-400 text-center">
          current late <br />
          leavers
        </p>
      </div>
    </div>
    <div
      class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-else
    >
      <p class="py-8 opacity-50">No users connected to Workday</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from "@/components/Base/BaseTooltip";
import { formatBigNum } from "@/utils/utils";

export default {
  components: {
    BaseTooltip,
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
    orgData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isClicked: false,
    };
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgDataAggregate() {
      let VBUs = this.org.VBUs;
      let widgetsData = this.$store.state.widgets.widgetsData;

      if (VBUs && VBUs.length) {
        let dataArr = [];
        VBUs.forEach((VBUItem) => {
          widgetsData?.forEach((orgItem) => {
            if (VBUItem.org_id === +orgItem.org_id) {
              dataArr.push({
                longest_leaver_delay_this_year:
                  orgItem.data.workday.longest_leaver_delay_this_year || 0,
                late_leavers_prev_quarter:
                  orgItem.data.workday.late_leavers_prev_quarter || 0,
                avg_days_processing_leaver:
                  orgItem.data.workday.avg_days_processing_leaver || 0,
                late_leavers_this_quarter:
                  orgItem.data.workday.late_leavers_this_quarter || 0,
              });
            }
          });
        });

        let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
          return {
            longest_leaver_delay_this_year:
              (sumObj.longest_leaver_delay_this_year || 0) <
              (currentObj.longest_leaver_delay_this_year || 0)
                ? currentObj.longest_leaver_delay_this_year || 0
                : sumObj.longest_leaver_delay_this_year || 0,
            late_leavers_prev_quarter:
              (sumObj.late_leavers_prev_quarter || 0) +
              (currentObj.late_leavers_prev_quarter || 0),
            avg_days_processing_leaver:
              (sumObj.avg_days_processing_leaver || 0) +
              (currentObj.avg_days_processing_leaver || 0),
            late_leavers_this_quarter:
              (sumObj.late_leavers_this_quarter || 0) +
              (currentObj.late_leavers_this_quarter || 0),
          };
        }, 0);

        return {
          longest_leaver_delay_this_year:
            sumVBUs.longest_leaver_delay_this_year,
          late_leavers_prev_quarter: sumVBUs.late_leavers_prev_quarter,
          avg_days_processing_leaver: sumVBUs.avg_days_processing_leaver,
          late_leavers_this_quarter: sumVBUs.late_leavers_this_quarter,
        };
      } else {
        return {};
      }
    },
    maxProcessDelayIconColor() {
      const colorList = [
        "16, 185, 129",
        "228, 248, 73",
        "248, 158, 73",
        "248, 108, 73",
        "239, 68, 68",
      ];
      let color = "";
      const maxProcessingDelay = this.isAggregateMode
        ? this.orgDataAggregate.longest_leaver_delay_this_year
        : this.orgData?.longest_leaver_delay_this_year;

      switch (true) {
        case maxProcessingDelay >= 0 && maxProcessingDelay < 3:
          color = colorList[0];
          break;
        case maxProcessingDelay >= 3 && maxProcessingDelay < 7:
          color = colorList[1];
          break;
        case maxProcessingDelay >= 7 && maxProcessingDelay < 14:
          color = colorList[2];
          break;
        case maxProcessingDelay >= 14 && maxProcessingDelay < 21:
          color = colorList[3];
          break;
        case maxProcessingDelay >= 21:
          color = colorList[4];
          break;
      }

      return color;
    },
  },
  methods: {
    formatBigNum,
  },
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>
