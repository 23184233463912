<template>
	<div>
		<BackButton/>
		
		<div>
			<div class="flex justify-between">
				<div>
					<h4 class="text-lg mr-auto">{{ org.name }}</h4>
					<p class="mb-6">
						Azure detail page
					</p>
				</div>
				<div class="text-center my-2">
					<img src="@/assets/azure_logo.png" class="h-10 inline-block"/>
				</div>
			</div>
			
			<div class="relative py-4">
				<div class="">
					<div class="flex justify-between items-center mb-2">
						<input
							class="outline-none text-black border-gray-700 rounded-lg px-4 py-2 mb-2"
							type="search"
							placeholder="Search ..."
							v-model="searchUsers"
						>
						<export-excel
							class="rounded-lg transition bg-green-600 hover:bg-green-700 transition cursor-pointer px-3 py-2"
							:data="excelInfo.dataList"
							:fields="excelInfo.titleList"
							type="csv"
							:name="`${org.name}-azure-data.csv`"
							:worksheet="`${org.name} azure data with id ${org.org_id}`"
						>
							<font-awesome-icon
								:icon="['fa', 'file-excel']"
								class="mr-4"
							/>Export
						</export-excel>
					</div>
					<div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
					<table class="w-full relative rounded-lg overflow-hidden">
						<thead>
							<tr>
								<th
									scope="col"
									class="px-2 py-3 text-center text-xs font-medium text-gray-200 uppercase bg-gray-700 z-20 sticky top-0 cursor-pointer hover:bg-gray-600 transition"
									:class="{'pl-5': (index === 0)}"
									@click="order(tableTitleItem.slug)"
									v-for="(tableTitleItem, index) in tableTitleList"
									:key="index"
								>
									<div class="flex items-center justify-between">
										<span style="user-select: none;">{{ tableTitleItem.title }}</span>
										<div class="flex flex-col">
											<font-awesome-icon
												class="text-white"
												:icon="['fa', 'chevron-up']"
												v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'ASC')"
											/>
											<font-awesome-icon
												class="text-white"
												:icon="['fa', 'chevron-down']"
												v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'DESC')"
											/>
										</div>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(item, index) in azureDataSearched"
								:key="index"
								class="bg-gray-800 hover:bg-gray-600 transition duration-300"
							>
								<th
									scope="col"
									class="px-2 pl-5 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
								>
									<div class="flex items-center">{{ item.user_principal_name }}</div>
								</th>
								<th
									scope="col"
									class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
								>
									<div class="flex items-center">{{ item.user_display_name }}</div>
								</th>
							</tr>
							<tr>
								<td
									colspan="7"
									class="text-center py-2"
									v-if="!azureDataSearched.length"
								>
									No result
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SpinnerBase from '@/components/Base/SpinnerBase';
import ProofpointChart from '@/components/ProofpointChart';
import BackButton from '@/components/Base/BackButton';
// import {} from '@/utils/time';

export default {
	data() {
		return {
			searchUsers: '',
			azureDataSearched: '',
			orderBy: {
				column: '',
				type: ''
			},
			tableTitleList: [
				{title: "User Email", slug: "user_principal_name"},
				{title: "User Name", slug: "user_display_name"}
			],
			excelInfo: {
				titleList: {
						"User Email": "user_principal_name",
						"User Name": "user_display_name"
				},
				dataList: []
			}
		}
	},
	components: {
		BackButton,
		SpinnerBase,
		ProofpointChart,
	},
	async created() {
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "azuremfa"
		});
		
		this.azureDataSearched = [...this.azureData];
		
		// set excel data
		this.excelInfo.dataList = this.azureData;
	},
	computed: {
		orgId() {
			return this.$route.params.id;
		},
		org() {
			return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
		},
		azureData() {
			return this.$store.state.systemsDetails.systemsDetailsData.data;
		},
	},
	methods: {
		order(column) {
			if (this.orderBy.column === column) {
				this.orderBy.type = (this.orderBy.type === "DESC") ? "ASC" : "DESC";
			} else {
				this.orderBy.type = "ASC";
			}
			
			this.orderBy.column = column;
			
			this.azureDataSearched.sort((a, b) => {
				let aItem = a[this.orderBy.column];
				let bItem = b[this.orderBy.column];
				
				if (this.orderBy.type === "ASC") {
					return (aItem > bItem) ? 1 : (aItem < bItem) ? -1 : 0;
				} else {
					return (aItem < bItem) ? 1 : (aItem > bItem) ? -1 : 0;
				}
			})
			
			this.setExcelData();
		},
		setExcelData() {
			this.excelInfo.dataList = this.azureDataSearched;
		}
	},
	watch: {
		azureData(val) {
			this.azureDataSearched = val;
		},
		searchUsers(keyword) {
			this.azureDataSearched = this.azureData.filter((item) => {
				let isMatch = false;
				let isS1Match = item.user_display_name.search(keyword) > -1;
				let isS2Match = item.user_principal_name.search(keyword) > -1;
				
				if (isS1Match || isS2Match) {
					isMatch = true;
				}
				
				return isMatch;
			})
			
			// set excel data after user search
			this.excelInfo.dataList = this.azureDataSearched;
		},
	}
};
</script>

<style lang="scss" scoped>
.blur-drop {
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(4px);
}

.tab-title-item {
	@apply px-5 py-3 rounded-tl-2xl rounded-tr-2xl border border-purple-500 border-b-0 mr-2 cursor-pointer;
}
</style>
