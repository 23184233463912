<template>
  <tr class="bg-gray-800 hover:bg-gray-600 transition duration-300">
    <th
      scope="col"
      class="px-2 pl-5 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
    >
      <div class="flex items-center">{{ asset.hostname }}</div>
    </th>
	  <th
		  scope="col"
		  class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
	  >
		  <div class="flex items-center">{{ asset.os }}</div>
	  </th>
	  <th
		  scope="col"
		  class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
	  >
		  <div class="flex items-center">{{ asset.mac_address }}</div>
	  </th>
	  <th
		  scope="col"
		  class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
	  >
		  <div class="flex items-center">{{ asset.asset_type }}</div>
	  </th>
	  <th
		  scope="col"
		  class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
	  >
		  <div class="flex items-center">{{ asset.first_seen }}</div>
	  </th>
	  <th
		  scope="col"
		  class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
	  >
		  <div class="flex items-center">{{ asset.last_seen }}</div>
	  </th>
	  <th
		  scope="col"
		  class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
	  >
		  <div class="flex items-center">
			  <input
				  type="checkbox"
				  class="w-4 h-4 cursor-pointer"
				  :checked="asset.false_positive"
			  >
		  </div>
	  </th>
  </tr>
</template>


<script>
import axios from 'axios'
export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    
    }
  },
  methods: {
		
  }
}
</script>
