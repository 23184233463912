<template>
	<div class="relative py-4" v-if="escalations">
		<div class="flex justify-between items-center">
			<h2 class="text-xl text-gray-300">Escalations</h2>
			<export-excel
				class="rounded-lg transition bg-green-600 hover:bg-green-700 transition cursor-pointer px-3 py-2"
				:data="excelInfo.dataList"
				:fields="excelInfo.titleList"
				type="csv"
				:name="`${orgName}-escalations.csv`"
				worksheet="Crowdstrike escalations"
				v-if="isExcelActive"
			>
				<font-awesome-icon
					:icon="['fa', 'file-excel']"
					class="mr-4"
				/>
				Export
			</export-excel>
		</div>
		<div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
		<table class="w-full relative rounded-lg overflow-hidden">
			<thead>
				<tr>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-200 uppercase bg-gray-700 z-20 sticky top-0 cursor-pointer hover:bg-gray-600 transition"
						:class="{'pl-5': (index === 0)}"
						@click="order(tableTitleItem.slug)"
						v-for="(tableTitleItem, index) in tableTitleList"
						:key="index"
					>
						<div class="flex items-center justify-between">
							<span style="user-select: none;">{{ tableTitleItem.title }}</span>
							<div class="flex flex-col">
								<font-awesome-icon
									class="text-white"
									:icon="['fa', 'chevron-up']"
									v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'ASC')"
								/>
								<font-awesome-icon
									class="text-white"
									:icon="['fa', 'chevron-down']"
									v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'DESC')"
								/>
							</div>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, index) in escalationsCopy"
					:key="index"
					class="bg-gray-800 hover:bg-gray-600 transition duration-300"
				>
					<th
						scope="col"
						class="px-2 pl-5 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
					>
						<div class="flex items-center">{{ item.id }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
					>
						<div class="flex items-center">{{ item.title }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
					>
						<div class="flex items-center">{{ item.status_description }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
					>
						<div class="flex items-center">{{ item.created_time }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
					>
						<div class="flex items-center">{{ item.last_modified_time }}</div>
					</th>
				</tr>
			</tbody>
		</table>
	</div>
	<div v-else>
		<h2 class="text-xl text-gray-300">No unresolved escalations</h2>
	</div>
</template>

<script>

export default {
  props: {
    escalations: {
      type: Object,
    },
	  orgName: {
			type: String,
		  default: ''
	  }
  },
	data() {
		return {
			orderBy: {
				column: '',
				type: ''
			},
			escalationsCopy: [],
			tableTitleList: [
				{title: "ID", slug: "id"},
				{title: "Title", slug: "title"},
				{title: "Status", slug: "status_description"},
				{title: "Created on", slug: "created_time"},
				{title: "Last Updated on", slug: "last_modified_time"},
			],
			excelInfo: {
				titleList: {
					"ID": "id",
					"Title": "title",
					"Status": "status_description",
					"Created on": "created_time",
					"Last Updated on": "last_modified_time"
				},
				dataList: []
			},
			isExcelActive: false
		}
	},
	created() {
		this.escalationsCopy = this.escalations;
		this.setExcelData();
	},
	methods: {
		order(column) {
			if (this.orderBy.column === column) {
				this.orderBy.type = (this.orderBy.type === "DESC") ? "ASC" : "DESC";
			} else {
				this.orderBy.type = "ASC";
			}
			
			this.orderBy.column = column;
			
			this.escalationsCopy.sort((a, b) => {
				let aItem = a[this.orderBy.column];
				let bItem = b[this.orderBy.column];
				
				if (this.orderBy.type === "ASC") {
					return (aItem > bItem) ? 1 : (aItem < bItem) ? -1 : 0;
				} else {
					return (aItem < bItem) ? 1 : (aItem > bItem) ? -1 : 0;
				}
			})
			
			this.setExcelData();
		},
		setExcelData() {
			this.excelInfo.dataList = this.escalationsCopy;
		}
	}
};
</script>

<style lang="scss" scoped></style>
