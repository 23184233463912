<template>
  <div>
    <div class="tooltip">
      <div class="icon">
        <slot />
      </div>
      <div class="info fadeIn">
        <p class="text-gray-300 whitespace-pre-line">{{ info }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: String,
      default: 'Please provide tooltip info',
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  @apply left-1;

  .icon {
    @apply opacity-50;
  }

  .info {
    @apply hidden;
  }
}

.tooltip:hover {
  .icon {
    @apply opacity-100;
  }

  .info {
    @apply block absolute right-full transform translate-x-1/2 top-full rounded-md p-1.5 text-sm w-64;

    background: rgba(#1d1d22, 0.7);
    backdrop-filter: blur(15px);
    z-index: 100;
  }
}
</style>
