import {
  ApolloClient,
  // createHttpLink,
  InMemoryCache,
} from '@apollo/client/core';
import { WebSocketLink } from 'apollo-link-ws';
import { createApolloProvider } from '@vue/apollo-option';
import store from './store';

function getToken() {
  const localToken = store.getters?.['auth/jwt']?.jwt_token || null;

  if (localToken) return localToken;

  return null;
}

// const API_URL = process.env.VUE_APP_API_URL || 'api-vg.redsmart.app';
const API_URL = process.env.VUE_APP_API_URL || 'api-ciso.redsmart.app';

// create an http link
const link = new WebSocketLink({
  uri: `wss://${API_URL}/v1/graphql`,
  options: {
    reconnect: true,
    timeout: 30000,
    connectionParams: () => ({
      headers: {
        authorization: getToken() ? `Bearer ${getToken()}` : '',
      },
    }),
  },
});

export const closeWebsocket = () => link.subscriptionClient.close();

export const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

export const changeSubscriptionToken = (token) => {
  if (link.subscriptionClient.connectionParams.authToken === token) return;

  link.subscriptionClient.connectionParams.authToken = token;
  // link.subscriptionClient.close()
  link.subscriptionClient.connect();
};

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
