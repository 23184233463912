import { apolloClient as apollo } from "@/apollo";

const defaultStoreState = () => ({
  tenant_logo: null,
  tenant_favicon: null,
});

const state = defaultStoreState();

const getters = {};

const mutations = {
  SET_LOGO_PATH: (state, logopath) => {
    state.tenant_logo = logopath[0].logo_source;
    state.tenant_favicon = logopath[0].favicon_source;
  },
  RESET_STORE: (store) => {
    Object.assign(store, defaultStoreState());
  },
};

const actions = {
  getLogoPath({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { tenant_logo },
        } = await apollo.query({
          query: require("@/graphql/get_logo.gql"),
        });
        commit("SET_LOGO_PATH", tenant_logo);

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
