<template>
  <div class="hello">
    <div class="flex justify-between items-center">
      <h2 class="text-2xl text-gray-200 mb-4">Dashboard</h2>
      <div class="buttons" v-if="!isSelectedHierarchyOnlyOneVBU">
        <div class="show-aggregate-button flex justify-between mb-2">
          <p class="text-base text-gray-300 mr-2">Show aggregate:</p>
          <BaseToggle
            @click.self.stop
            :name="'view_aggregate'"
            @change="toggleAggregateMode()"
            :value="isAggregateMode"
          />
        </div>
        <div class="show-children-button flex justify-between">
          <p class="text-base text-gray-300 mr-2">Show children:</p>
          <BaseToggle
            @click.self.stop
            :name="'view_children'"
            @change="toggleShowChildren()"
            :value="isChildrenVisible"
          />
        </div>
      </div>
    </div>
    <div v-if="selectedHierarchy.length">
      <DashboardObject
        v-for="organisation in selectedHierarchy"
        :organisation="organisation"
        :key="organisation.org_id"
      />
    </div>
<!--    <div v-else class="bg-bgLight py-8 text-center rounded-md">-->
<!--      <p>Please use filter for see organisations data.</p>-->
<!--      <button-->
<!--        class="bg-gray-700 text-gray-200 px-4 py-2 rounded-md mt-4 text-sm"-->
<!--        @click="showAll"-->
<!--        v-if="!isLoading"-->
<!--      >-->
<!--        Show All-->
<!--      </button>-->
<!--      <div class="w-10 h-10 mx-auto mt-4" v-else>-->
<!--        <SpinnerBase />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import BaseToggle from '@/components/Base/BaseToggle';
import SpinnerBase from '@/components/Base/SpinnerBase';
import DashboardObject from '@/components/DashboardObject';

var fromHeatmap = false;
export default {
  name: 'Dashboard',
  components: {
    BaseToggle,
    DashboardObject,
    SpinnerBase,
  },
  data() {
    return {
      isLoading: false,
      filteredOrg: ''
    };
  },
  beforeRouteEnter(to, from, next) {
    fromHeatmap = (from.name == "Heatmap");
    next()
  },
  methods: {
    toggleAggregateMode() {
      this.$store.commit('structure/SET_TOGGLE_AGGREGATE_MODE');
    },
    toggleShowChildren() {
      this.$store.commit('structure/SET_TOGGLE_CHILDREN_MODE');
    },
    showAll() {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
      }, 2000);

      setTimeout(() => {
        this.$store.commit('structure/SHOW_ALL');
      }, 50);
    },
    findFilteredOrg(hierarchy, orgId) {
      if(hierarchy.org_id == orgId)
        this.filteredOrg = hierarchy;

      if(hierarchy.children) {
        hierarchy.children.forEach((child) => {
          this.findFilteredOrg(child, orgId);
        })
      }
    }
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    isChildrenVisible() {
      return this.$store.state.structure.isChildrenVisible;
    },
    selectedHierarchy() {
      return this.$store.state.structure.selectedHierarchy;
    },
	  isSelectedHierarchyOnlyOneVBU() {
			return this.selectedHierarchy[0]?.org_type === "VBU";
	  }
  },
  mounted() {
    // scroll to previous area after back to this page
    let _this = this;
		this.$nextTick(() => {
			let clickedOrg = JSON.parse(localStorage.getItem('clickedOrganisationOnDashboard'));
			let clickedOrgElement = document.querySelector(`#id${clickedOrg?.org_id}`);
			
			if(clickedOrg && clickedOrgElement) {
				setTimeout(function(){
					window.location.href = `#id${clickedOrg.org_id}`;
				}, 1000)
			}
		})

    // show data
    if(!this.selectedHierarchy.length) {
      this.showAll()
    }

    // filter data when we came from security modal on heatmap page
    let hierarchy = this.$store.state.structure.hierarchy;
    let orgId = this.$route.params.orgId;
    if(orgId && fromHeatmap) {
      this.findFilteredOrg(hierarchy[0], orgId);

      this.$store.commit('structure/SELECT_HIERARCHY', {
        hierarchy: [this.filteredOrg],
      });
    }
  }
};
</script>
