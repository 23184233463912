<template>
<div class="flex-auto ml-5">
  
    <div class="flex justify-between items-center">
	    <h2 class="text-xl text-gray-300">Asset Management</h2>
	    <export-excel
		    class="rounded-lg bg-green-600 hover:bg-green-700 transition cursor-pointer px-3 py-2"
		    :data="excelInfo.dataList"
		    :fields="excelInfo.titleList"
		    type="csv"
		    :name="`asset-management.csv`"
		    worksheet="Asset Management"
	    >
		    <font-awesome-icon
			    :icon="['fa', 'file-excel']"
			    class="mr-4"
		    />Export
	    </export-excel>
    </div>
    <div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
		<div class="flex items-center justify-between mb-5">
			<select class="text-black w-72 px-1 py-2.5 outline-none rounded-lg">
				<option
					v-for="(org, index) in orgs"
					:key="index"
					:value="org.org_id"
				>{{ org.name }}</option>
			</select>
			<div class="show-children-button flex justify-between">
				<p class="text-base text-gray-300 mr-2">Hide False Positives:</p>
				<BaseToggle
					@click.self.stop
					:name="'hide_false_positives'"
					@change="toggleHideFalsePositives()"
					:value="isHideFalsePositives"
				/>
			</div>
		</div>
    <table class="w-full relative rounded-lg overflow-hidden">
      <thead>
        <tr>
          <th
            scope="col"
            class="px-2 py-3 text-center text-xs font-medium text-gray-200 uppercase bg-gray-700 z-20 sticky top-0 cursor-pointer hover:bg-gray-600 transition"
            :class="{'pl-5': (index === 0)}"
            @click="order(tableTitleItem.slug)"
            v-for="(tableTitleItem, index) in tableTitleList"
            :key="index"
          >
            <div class="flex items-center justify-between">
	            <span style="user-select: none;">{{ tableTitleItem.title }}</span>
	            <div class="flex flex-col">
		            <font-awesome-icon
			            class="text-white"
			            :icon="['fa', 'chevron-up']"
			            v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'ASC')"
		            />
		            <font-awesome-icon
			            class="text-white"
			            :icon="['fa', 'chevron-down']"
			            v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'DESC')"
		            />
	            </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody >
        <asset-management-table-item
          v-for="(assetItem, index) in fakeDataCopy"
          :asset="assetItem"
          :key="index"
          @order="order($event)"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import BaseToggle from '@/components/Base/BaseToggle';
import AssetManagementTableItem from "@/components/AssetManagementTableItem.vue";

export default {
  components: {
		BaseToggle,
	  AssetManagementTableItem
  },
  data() {
    return {
	    orderBy: {
		    column: '',
		    type: ''
	    },
	    isHideFalsePositives: false,
      fakeData: [
	      {
					hostname: "sample_1@hostname.com",
					os: "Windows",
					mac_address: "00-B0-D0-63-C2-26",
					asset_type: "Workstation",
					first_seen: "2022-03-05",
					last_seen: "2022-04-06",
					false_positive: true
	      },
	      {
		      hostname: "sample_2@hostname.com",
		      os: "Linux",
		      mac_address: "AB-B0-D0-63-C2-26",
		      asset_type: "Workstation",
		      first_seen: "2020-03-05",
		      last_seen: "2021-04-06",
		      false_positive: false
	      },
	      {
		      hostname: "sample_3@hostname.com",
		      os: "MacOS",
		      mac_address: "CB-B0-D0-63-C2-26",
		      asset_type: "Workstation",
		      first_seen: "2020-11-05",
		      last_seen: "2022-12-13",
		      false_positive: false
	      },
	      {
		      hostname: "sample_4@hostname.com",
		      os: "Windows",
		      mac_address: "33-B0-D0-63-C2-26",
		      asset_type: "Server",
		      first_seen: "2019-03-12",
		      last_seen: "2020-04-06",
		      false_positive: true
	      }
      ],
	    tableTitleList: [
		    {title: "Hostname", slug: "hostname"},
		    {title: "Operating System", slug: "os"},
		    {title: "MAC Address", slug: "mac_address"},
		    {title: "Asset Type", slug: "asset_type"},
		    {title: "First Seen", slug: "first_seen"},
		    {title: "Last Seen", slug: "last_seen"},
		    {title: "False Positive?", slug: "false_positive"}
	    ],
	    excelInfo: {
		    titleList: {
			    "Hostname": "hostname",
			    "Operating System": "os",
			    "MAC Address": "mac_address",
			    "Asset Type": "asset_type",
			    "First Seen": "first_seen",
			    "Last Seen": "last_seen",
			    "False Positive?": "false_positive"
		    },
		    dataList: []
	    }
    };
  },
	computed: {
		orgs() {
			console.log(this.$store.state.structure.data)
			return this.$store.state.structure.data;
		}
	},
	created() {
		this.fakeDataCopy = this.fakeData;
		this.setExcelData();
	},
	methods: {
	  order(column) {
			if(this.orderBy.column === column) {
				this.orderBy.type = (this.orderBy.type === "DESC") ? "ASC" : "DESC";
			} else {
				this.orderBy.type = "ASC";
			}
			
		  this.orderBy.column = column;
		
		  this.fakeDataCopy.sort((a, b) => {
				let aItem = a[this.orderBy.column];
				let bItem = b[this.orderBy.column];
				
				if(this.orderBy.type === "ASC") {
					return (aItem > bItem) ? 1 : (aItem < bItem) ? -1 : 0;
				} else {
					return (aItem < bItem) ? 1 : (aItem > bItem) ? -1 : 0;
				}
			})
		  
		  this.setExcelData();
	  },
	  toggleHideFalsePositives() {
			this.isHideFalsePositives = !this.isHideFalsePositives;
		
			if(this.isHideFalsePositives) {
				this.fakeDataCopy = this.fakeDataCopy.filter((item) => {
					return !item.false_positive;
				})
			} else {
				this.fakeDataCopy = this.fakeData;
			}
		  
		  this.setExcelData();
	  },
		setExcelData() {
			this.excelInfo.dataList = this.fakeDataCopy;
		}
  },
};
</script>
