import axios from 'axios';
import { changeSubscriptionToken } from '../../../src/apollo';

const defaultAuthStore = () => ({
  jwt: {
    jwt_token: null,
    jwt_token_expiry: null,
    refresh_token: null,
  },
});

const state = defaultAuthStore();

const getters = {
  jwt: (state) => state.jwt,
  parseJwt: (state) => {
    var base64Url = state.jwt.jwt_token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload)['https://hasura.io/jwt/claims'];
}
};

const actions = {
  updateJwt({ commit }, content) {
    commit('setJwt', content);
  },
  refreshToken({ dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        const refreshToken = await axios.post(
          'https://id-ciso.redsmart.app/refresh_token',
          { application: 'enexo' },
          {
            withCredentials: true,
          }
        );
        if (refreshToken.data.jwt_token) {
          changeSubscriptionToken(refreshToken.data.jwt_token);
          dispatch('updateJwt', refreshToken.data);
        }
        resolve();
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  },
};
// mutations
const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultAuthStore());
  },
  setJwt(state, content) {
    state.jwt = content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
