<template>
	<div>
		
		<BackButton />
		
		<div class="flex justify-between">
			<div>
				<h4 class="text-lg mr-auto">{{ org.name }}</h4>
				<p class="mb-6">
					SecurityScorecard history
				</p>
			</div>
			<div class="text-center my-2">
				<img src="@/assets/securityscorecard.png" class="h-10 inline-block" />
			</div>
		</div>
		<template v-for="(otherInfoItem, index) in otherInfoArray" :key="index">
			<div class="border border-gray-600 my-12" v-if="index != 0"></div>
			<div class="mt-12" v-if="index == 0"></div>
			<SecurityScoreCardChart
				:chartData="chartData[index]"
				:otherInfo="otherInfoArray[index]"
				v-if="isDataPrepared"
			/>
		</template>
		<div class="mb-12"></div>
	</div>
</template>

<script>


import SecurityScoreCardChart from "@/components/SecurityScoreCardChart";
import BackButton from "@/components/Base/BackButton";

export default {
	components: {
		SecurityScoreCardChart,
		BackButton
	},
	data() {
		return {
			isDataPrepared: false,
			otherInfo: {},
			chartData: [],
			otherInfoArray: [],
			chartDataAll: {}
		}
	},
	computed: {
		orgId() {
			return this.$route.params.id;
		},
		org() {
			return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
		},
	},
	async created() {
		// get data and populate
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "securityscorecard"
		});
		
		const data = this.$store.state.systemsDetails.systemsDetailsData.data;

		data.forEach((dataItem, index) => {
			
			// set other info
			this.otherInfo = { domain: dataItem.domain, issue_summary: dataItem.issue_summary }
			this.otherInfoArray.push(this.otherInfo);
			
			// set chart data
			let chartData = [...dataItem.details.slice(-7)].reverse(); // to get last seven-month data
			let chartDataItem = {
					datasets: [
						{
							label: 'Overall Grade',
							data: [],
							fill: false,
							borderColor: 'rgb(256, 256, 256)',
							tension: 0.1
						},
						{
							label: 'Patching Cadence',
							data: [],
							fill: false,
							borderColor: 'rgb(255, 0, 0)',
							tension: 0.1
						},
						{
							label: 'Network Security',
							data: [],
							fill: false,
							borderColor: 'rgb(128, 128, 128)',
							tension: 0.1
						},
						{
							label: 'Application Security',
							data: [],
							fill: false,
							borderColor: 'rgb(256, 15, 256)',
							tension: 0.1
						}
					],
					labels: []
				};
			
			chartData.forEach((item) => {
				
				// set chart labels
				let monthName = new Date(item.month).toLocaleString('en-us', { month: 'long' });
				chartDataItem.labels.push(monthName.toUpperCase())
				
				// set chart values
				chartDataItem.datasets[0].data.push(item.overall_grade);
				chartDataItem.datasets[1].data.push(item.patching_cadence);
				chartDataItem.datasets[2].data.push(item.network_security);
				chartDataItem.datasets[3].data.push(item.application_security);
				
			})
			
			this.chartData.push(chartDataItem);
			
		})
		
		this.isDataPrepared = true;
	}
}
;
</script>

<style lang="scss" scoped></style>
