<template>
	<div v-if="isDataPrepared">
		<BackButton/>
		
		<div>
			<div class="flex justify-between">
				<div>
					<h4 class="text-lg mr-auto">{{ org.name }}</h4>
					<p class="mb-6">Completion status for the 6 most recent campaigns</p>
				</div>
				<div class="text-center my-2">
					<img src="@/assets/knowbe4-logo.png" class="h-6 inline-block"/>
				</div>
			</div>
			<div class="relative py-4">
				<div
					v-if="!orgCampaigns?.length"
					class="bg-green-900 flex items-center justify-center inset-0 blur-drop text-center rounded-md absolute z-10"
				>
					<p>No campaigns found.</p>
				</div>
				<KnowBe4Chart :chartData="chartData" v-if="orgCampaigns?.length"/>
			</div>
		</div>
	</div>
</template>

<script>
import SpinnerBase from '@/components/Base/SpinnerBase';
import KnowBe4Chart from '@/components/KnowBe4Chart';
import {} from '@/utils/time';
import BackButton from '@/components/Base/BackButton';


export default {
	data() {
		return {
			isDataPrepared: false
		}
	},
	components: {
		BackButton,
		SpinnerBase,
		KnowBe4Chart,
	},
	async created() {
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "knowbe"
		});

		this.isDataPrepared = true;
	},
	computed: {
		orgId() {
			return this.$route.params.id;
		},
		org() {
			return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
		},
		orgCampaigns() {
			let knowbe4Data = this.$store.state.systemsDetails.systemsDetailsData.data;
			
			if (knowbe4Data?.length) {
				let data = [...knowbe4Data];
				return data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date)).splice(0, 6);
			}
			return null;
		},
		chartData() {
			const data = {
				labels: [],
				datasets: [
					{
						label: 'Not Started',
						data: [],
						backgroundColor: 'rgba(139, 92, 246,0.7)',
						// borderWidth: 1,
						// borderColor: 'rgba(245, 158, 11, 1)',
					},
					{
						label: 'In Progress',
						data: [],
						backgroundColor: 'rgba(245, 158, 11,0.7)',
						// borderWidth: 1,
						// borderColor: 'rgba(59, 130, 246, 1)',
					},
					{
						label: 'Past Due',
						data: [],
						backgroundColor: 'rgba(239, 68, 68,0.7)',
						// borderWidth: 1,
						// borderColor: 'rgba(59, 130, 246, 1)',
					},
					{
						label: 'Completed',
						data: [],
						backgroundColor: 'rgba(22, 184, 65,0.7)',
						// borderWidth: 1,
						// borderColor: 'rgba(139, 92, 246, 1)',
					},
				],
			};
			
			if (this.orgCampaigns?.length) {
				this.orgCampaigns.reverse().forEach((campaign, i) => {
					
					// set labels
					let labelArray = campaign.campaign_name.split(' ');
					let step = Math.ceil(labelArray.length / 3);
					let wordsForOneLineArray = [];
					let finalLabelArray = [];
					labelArray.forEach((word) => {
						if(wordsForOneLineArray.length <= step) {
							wordsForOneLineArray.push(word);
						} else {
							finalLabelArray.push(wordsForOneLineArray.join(' '))
							wordsForOneLineArray = [word]
						}
					})
	
					finalLabelArray.push(wordsForOneLineArray.join(' '))

					data.labels.push(finalLabelArray);
					// data.labels.push(campaign.campaign_name);

					// set data
					data.datasets.forEach((el) => el.data.push(0));

					data.datasets[0].data[i] = campaign['Not Started'];
					data.datasets[1].data[i] = campaign['In Progress'];
					data.datasets[2].data[i] = campaign['Past Due'];
					data.datasets[3].data[i] = campaign['Passed'];
				});
			}
			
			return data;
		},
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
.blur-drop {
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(4px);
}
</style>
