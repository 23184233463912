import { apolloClient as apollo } from "@/apollo";
import heatmap from "@/store/modules/heatmap";
import structure from "@/store/modules/structure";

const defaultStoreState = () => ({
  data: [],
  heatmapMinMaxAvgValues: {},
});

const state = defaultStoreState();

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultStoreState());
  },
  SET_DASHBOARD_SETTINGS(state, dashboardSettings) {
    state.data = dashboardSettings;
  },
  UPDATE_DASHBOARD_SETTINGS_BY_INDEX(state, { settings, activeTabKey }) {
    let allData = JSON.parse(JSON.stringify(state.data));

    allData[0][activeTabKey].green = settings[0];
    allData[0][activeTabKey].yellow = settings[1];
    allData[0][activeTabKey].orange = settings[2];
    allData[0][activeTabKey].darkorange = settings[3];
    allData[0][activeTabKey].red = settings[4];

    state.data = allData;
  },
  SET_MIN_MAX_AVG_CROWDSTRIKE(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.crowdstrike?.operational
          ?.last_month_detections || 0;
      let deploymentValue =
        heatmapDataItem?.data?.crowdstrike?.deployment?.percent_deployed || 0;

      allValues.operational.push(operationalValue);
      allValues.deployment.push(deploymentValue);
    }

    state.heatmapMinMaxAvgValues.crowdstrike = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: {
        min: Math.min(...allValues.deployment)
          ? Math.round(Math.min(...allValues.deployment))
          : 0,
        max: Math.max(...allValues.deployment)
          ? Math.round(Math.max(...allValues.deployment))
          : 0,
        avg: Math.round(
          allValues.deployment.reduce((prev, current) => prev + current) /
            totalNumbers
        ),
      },
    };
  },
  SET_MIN_MAX_AVG_PROOFPOINT(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.proofpoint?.operational
          ?.blocked_clicks_last_month || 0;
      let deploymentValue =
        heatmapDataItem?.data?.proofpoint?.deployment
          ?.allowed_clicks_last_month || 0;

      allValues.operational.push(operationalValue);
      allValues.deployment.push(deploymentValue);
    }

    state.heatmapMinMaxAvgValues.proofpoint = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: {
        min: Math.min(...allValues.deployment)
          ? Math.round(Math.min(...allValues.deployment))
          : 0,
        max: Math.max(...allValues.deployment)
          ? Math.round(Math.max(...allValues.deployment))
          : 0,
        avg: Math.round(
          allValues.deployment.reduce((prev, current) => prev + current) /
            totalNumbers
        ),
      },
    };
  },
  SET_MIN_MAX_AVG_SPYCLOUD(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.spycloud?.operational?.breaches_last_month || 0;

      allValues.operational.push(operationalValue);
    }

    state.heatmapMinMaxAvgValues.spycloud = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: null,
    };
  },
  SET_MIN_MAX_AVG_KNOWBE4(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.knowbe?.operational?.days_since_last_course || 0;
      let deploymentValue =
        heatmapDataItem?.data?.knowbe?.deployment?.percentage_users_in_group ||
        0;

      allValues.operational.push(operationalValue);
      allValues.deployment.push(deploymentValue);
    }

    state.heatmapMinMaxAvgValues.knowbe4 = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: {
        min: Math.min(...allValues.deployment)
          ? Math.round(Math.min(...allValues.deployment))
          : 0,
        max: Math.max(...allValues.deployment)
          ? Math.round(Math.max(...allValues.deployment))
          : 0,
        avg: Math.round(
          allValues.deployment.reduce((prev, current) => prev + current) /
            totalNumbers
        ),
      },
    };
  },
  SET_MIN_MAX_AVG_INVICTI(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.web_application_scans?.operational
          ?.total_detections || 0;
      let deploymentValue =
        heatmapDataItem?.data?.web_application_scans?.deployment
          ?.days_since_recent_scan || 0;

      allValues.operational.push(operationalValue);
      allValues.deployment.push(deploymentValue);
    }

    state.heatmapMinMaxAvgValues.invicti = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: {
        min: Math.min(...allValues.deployment)
          ? Math.round(Math.min(...allValues.deployment))
          : 0,
        max: Math.max(...allValues.deployment)
          ? Math.round(Math.max(...allValues.deployment))
          : 0,
        avg: Math.round(
          allValues.deployment.reduce((prev, current) => prev + current) /
            totalNumbers
        ),
      },
    };
  },
  SET_MIN_MAX_AVG_CISCO(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.ciscoumbrella?.operational?.last_month_threats ||
        0;

      allValues.operational.push(operationalValue);
    }

    state.heatmapMinMaxAvgValues.cisco = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: null,
    };
  },
  SET_MIN_MAX_AVG_RAPID7(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.vulnerability_management?.operational
          ?.total_vulnerabilites || 0;
      let deploymentValue =
        heatmapDataItem?.data?.vulnerability_management?.deployment
          ?.percent_deployed || 0;

      allValues.operational.push(operationalValue);
      allValues.deployment.push(deploymentValue);
    }

    state.heatmapMinMaxAvgValues.rapid7 = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: {
        min: Math.min(...allValues.deployment)
          ? Math.round(Math.min(...allValues.deployment))
          : 0,
        max: Math.max(...allValues.deployment)
          ? Math.round(Math.max(...allValues.deployment))
          : 0,
        avg: Math.round(
          allValues.deployment.reduce((prev, current) => prev + current) /
            totalNumbers
        ),
      },
    };
  },
  SET_MIN_MAX_AVG_AZURE(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.azuremfa?.operational?.percentage_users_mfa || 0;

      allValues.operational.push(operationalValue);
    }

    state.heatmapMinMaxAvgValues.azure = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: null,
    };
  },
  SET_MIN_MAX_AVG_WORKDAY(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.workday?.operational
          ?.avg_days_processing_leaver || 0;
      let deploymentValue =
        heatmapDataItem?.data?.workday?.deployment?.current_quarter_leavers ||
        0;

      allValues.operational.push(operationalValue);
      allValues.deployment.push(deploymentValue);
    }

    state.heatmapMinMaxAvgValues.workday = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: {
        min: Math.min(...allValues.deployment)
          ? Math.round(Math.min(...allValues.deployment))
          : 0,
        max: Math.max(...allValues.deployment)
          ? Math.round(Math.max(...allValues.deployment))
          : 0,
        avg: Math.round(
          allValues.deployment.reduce((prev, current) => prev + current) /
            totalNumbers
        ),
      },
    };
  },
  SET_MIN_MAX_AVG_SIX_CLICKS(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.sixclicks?.operational?.maturity_percentage || 0;

      allValues.operational.push(operationalValue);
    }

    state.heatmapMinMaxAvgValues.sixclicks = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: null,
    };
  },
  SET_MIN_MAX_AVG_SECURITY_SCORE_CARD(state) {
    const heatmapData = heatmap.state.heatmapData;
    let allValues = {
      operational: [],
      deployment: [],
    };
    let totalNumbers = heatmapData.length;

    for (let heatmapDataItem of heatmapData) {
      let operationalValue =
        heatmapDataItem?.data?.securityscorecard?.operational?.overall_grade ||
        0;

      allValues.operational.push(operationalValue);
    }

    state.heatmapMinMaxAvgValues.scorecard = {
      operational: {
        min: Math.min(...allValues.operational)
          ? +Math.min(...allValues.operational).toFixed(2)
          : 0,
        max: Math.max(...allValues.operational)
          ? +Math.max(...allValues.operational).toFixed(2)
          : 0,
        avg: +(
          allValues.operational.reduce((prev, current) => prev + current) /
          totalNumbers
        ).toFixed(2),
      },
      deployment: null,
    };
  },
};

const actions = {
  getDashboardSettings({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { dashboard_settings },
        } = await apollo.query({
          query: require("@/graphql/get_dashboard_settings.gql"),
        });

        commit("SET_DASHBOARD_SETTINGS", dashboard_settings);

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
  setMinMaxAvgValues({ commit }) {
    commit("SET_MIN_MAX_AVG_CROWDSTRIKE");
    commit("SET_MIN_MAX_AVG_PROOFPOINT");
    commit("SET_MIN_MAX_AVG_SPYCLOUD");
    commit("SET_MIN_MAX_AVG_KNOWBE4");
    commit("SET_MIN_MAX_AVG_INVICTI");
    commit("SET_MIN_MAX_AVG_CISCO");
    commit("SET_MIN_MAX_AVG_RAPID7");
    commit("SET_MIN_MAX_AVG_AZURE");
    commit("SET_MIN_MAX_AVG_WORKDAY");
    commit("SET_MIN_MAX_AVG_SIX_CLICKS");
    commit("SET_MIN_MAX_AVG_SECURITY_SCORE_CARD");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
