<template>
  <div>
    <label
      class="block mb-2 text-sm font-normal text-gray-400"
      :for="name"
      v-if="showLabel"
      v-html="label"
    />
    <div
      class="focus-within:shadow-sm focus-within:border-primary focus-within:outline-none block bg-transparent w-full text-sm border border-gray-700 rounded-lg transition ease-linear duration-200 relative"
      :class="invalid && 'border-red-500'"
    >
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        class="absolute left-4 top-1/2 transform -translate-y-1/2 text-primary"
      />
      <input
        class="w-full py-2.5 px-3.5 bg-transparent focus:outline-none placeholder-gray-500 rounded-lg"
        :class="icon && 'pl-12'"
        :type="type"
        :name="name"
        :id="name"
        :min="min"
        :placeholder="placeholder"
        :value="value"
        :required="required"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
        @input="$emit('input', parseValue($event))"
        @change="$emit('change', parseValue($event))"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputBase',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
    },
    placeholder: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [Number],
    },
    icon: {
      type: Array,
      required: false,
    },
  },
  methods: {
    parseValue(val) {
      if (this.type == 'number') {
        return parseFloat(val) || null;
      } else {
        return val;
      }
    },
  },
};
</script>

<style scoped>
input:disabled {
  @apply bg-gray-50;
}
</style>
