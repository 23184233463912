<template>
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !orgData?.is_clickable}"
    :to="orgData?.is_clickable ? `/azure/${org.org_id}` : '#'"
    @click="isClicked = true"
  >
    <div
      class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
      :class="orgData?.has_data && 'clickable'"
    >
      <div>
	      <span>Multi-Factor Authentication</span>
        <BaseTooltip
	        class="relative ml-2 inline-block"
          :info="'Multi-factor Authentication description'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <div class="flex items-center">
	      <img src="@/assets/azure_logo.png" class="h-5 mr-3 inline-block" />
        <div class="not-clickable-warning text-primary text-sm mr-3" v-if="!orgData?.is_clickable && isClicked">
          <font-awesome-icon
            class="text-primary mr-1"
            :icon="['fa', 'info-circle']"
          />
          <span>No click-through data</span>
        </div>
        <font-awesome-icon
          v-if="orgData?.is_clickable"
          class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
          :icon="['fa', 'arrow-right']"
        />
      </div>
    </div>
    <div
	    class="w-full h-full flex items-center border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-if="isAggregateMode || (orgData?.users_count && !isAggregateMode)"
    >
      <div class="flex flex-col items-center px-3 py-4 w-full">
        <div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
          <font-awesome-icon
            class="text-xl mr-2"
            :icon="['fa', 'fingerprint']"
            :class="
              {
                'text-green-500': calculatePercentage(orgDataAggregate) === 100,
                'text-red-500': calculatePercentage(orgDataAggregate) < 100
              }
            "
          />
          <h2 class="text-4xl">
            <!-- if dividing 0/0 means there are users, but all are incapable therefore mfa enabled is 100% -->
            {{calculatePercentage(orgDataAggregate)}}%
          </h2>
        </div>
        <div class="flex items-center" v-else>
          <font-awesome-icon
            class="text-xl mr-2"
            :icon="['fa', 'fingerprint']"
            :class="
              {
                'text-green-500': calculatePercentage(orgData) === 100,
                'text-red-500': calculatePercentage(orgData) < 100
              }
            "
          />
          <h2 class="text-4xl">
            <!-- if dividing 0/0 means there are users, but all are incapable therefore mfa enabled is 100% -->
            {{calculatePercentage(orgData)}}%
          </h2>
        </div>
        <p class="text-sm text-gray-400 text-center">
          percentage of users<br />
          with MFA enabled
        </p>
      </div>
	    
      <div class="self-stretch border-l border-gray-700"></div>
	    
      <div class="flex flex-col">
        <div class="px-2 py-3 border-b border-gray-700">
          <div
            class="flex flex-col items-center justify-center h-full px-6 w-full"
          >
            <div class="flex items-center">
              <font-awesome-icon
                class="mr-2 text-indigo-400"
                :icon="['fa', 'user-friends']"
              />
              <h4 v-if="isAggregateMode && org.org_type != 'VBU'">
                {{ orgDataAggregate.users_count }}
              </h4>
              <h4 v-else>
                {{ orgData?.users_count }}
              </h4>
            </div>
          </div>
          <p class="text-xs text-gray-400 text-center">Azure users</p>
        </div>
        <div class="px-2 py-3">
          <div
            class="flex flex-col items-center justify-center h-full px-6 w-full"
          >
            <div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
              <font-awesome-icon
                class="mr-2 text-yellow-400"
                :icon="['fa', 'user-shield']"
                :class="
                  {
                    'text-green-500': (orgDataAggregate.mfa_incapable === 0),
                    'text-yellow-400': (orgDataAggregate.mfa_incapable > 0)
                  }
                "
              />
              <h4>
                {{ Math.round(orgDataAggregate.mfa_incapable) }}
              </h4>
            </div>
            <div class="flex items-center" v-else>
              <font-awesome-icon
                class="mr-2"
                :icon="['fa', 'user-shield']"
                :class="
                  {
                    'text-green-500': (orgData?.mfa_incapable === 0),
                    'text-yellow-400': (orgData?.mfa_incapable > 0)
                  }
                "
              />
              <h4>
                {{ orgData?.mfa_incapable }}
              </h4>
            </div>
          </div>
          <p class="text-xs text-gray-400 text-center">MFA incapable users</p>
        </div>
      </div>
	    
    </div>
    <div class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg" v-else>
      <p class="py-8 opacity-50">No azure users found.</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';
import { formatBigNum } from '@/utils/utils';

export default {
  components: {
    BaseTooltip,
  },
  data() {
    return {
      isClicked: false
    }
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
	  orgData: {
		  type: Object,
		  required: true,
	  }
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
	  orgDataAggregate() {
		  let VBUs = this.org.VBUs;
		  let widgetsData = this.$store.state.widgets.widgetsData;
		
		  if (VBUs && VBUs.length) {
			  let dataArr = [];
			  VBUs.forEach((VBUItem) => {
				  widgetsData?.forEach((orgItem) => {
					  if(VBUItem.org_id === +orgItem.org_id) {
						  dataArr.push({
							  users_count: orgItem.data.azuremfa.users_count || 0,
							  mfa_enabled: orgItem.data.azuremfa.mfa_enabled || 0,
							  mfa_incapable: orgItem.data.azuremfa.mfa_incapable || 0
						  })
					  }
				  })
			  })
			
			  let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
				  return {
					  users_count: (sumObj.users_count || 0) + (currentObj.users_count || 0),
					  mfa_enabled: (sumObj.mfa_enabled || 0) + (currentObj.mfa_enabled || 0),
					  mfa_incapable: (sumObj.mfa_incapable || 0) + (currentObj.mfa_incapable || 0),
				  }
			  }, 0)
			
			  return {
				  users_count: sumVBUs.users_count,
				  mfa_enabled: sumVBUs.mfa_enabled,
				  mfa_incapable: sumVBUs.mfa_incapable
			  };
		  } else {
			  return {}
		  }
	  },
  },
  methods: {
    formatBigNum,
	  calculatePercentage(orgData) {
			let {mfa_enabled, users_count} = orgData || {};
			
			let percent = (+(mfa_enabled || 0) / (+(users_count || 0)));
			percent =	(![Infinity, NaN].includes(percent)) ? (percent * 100) : 100;
			
		  return Math.floor(percent) || 100;
	  }
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>
