<template v-if="isParentVisible">
  <div
    class="border border-gray-600 rounded-md shadow-standard my-4"
    :class="depth > 0 && 'bg-trans'"
    :style="indent"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
      throttle: 300,
    }"
    :id="`id${organisation.org_id}`"
    @click.stop="setClickedOrgInfo"
  >
    <button
      class="flex w-full justify-between items-center rounded-t-md bg-bgLighter border-b border-gray-700 px-6 py-3 text-gray-300 card--title cursor-default"
      :class="{
        'hover:bg-bgLight cursor-pointer':
          organisation?.children?.length && !isAggregateMode,
        'bg-blue-500 cursor-pointer':
          isAggregateMode && organisation.org_type == 'Portfolio',
        'bg-yellow-600 cursor-pointer':
          isAggregateMode && organisation.org_type == 'Sub-Portfolio',
        'bg-green-500 cursor-pointer':
          isAggregateMode && organisation.org_type == 'Group',
      }"
      @click="showChildren = !showChildren"
    >
      <h4 class="font-normal item--name ml-4" :class="organisation.org_type">
        <span :class="{ 'text-white': isAggregateMode }">{{
          organisation.name
        }}</span>
        <span> - </span>
        <div class="inline-block">
          <h4
            class="font-normal text-sm inline"
            :class="isAggregateMode ? 'text-white' : 'text-gray-400'"
          >
            Number of recorded devices:
          </h4>
          <p
            class="font-medium inline"
            :class="isAggregateMode ? 'text-white' : 'text-gray-400'"
            v-if="!isAggregateMode || organisation.org_type == 'VBU'"
          >
            <span v-if="orgData.crowdstrike.total_crowdstrike_assets">{{ orgData.crowdstrike.total_crowdstrike_assets }}</span>
            <span v-else>N/A</span>
          </p>
          <p
            class="inline"
            :class="isAggregateMode ? 'text-white' : 'text-gray-400'"
            v-else
          >
            {{ organisation.total_assets_aggregate_sum }}
          </p>
        </div>
        <div class="inline-block ml-2">
          <h4
            class="font-normal text-sm inline"
            :class="isAggregateMode ? 'text-white' : 'text-gray-400'"
          >
            Last Updated:
          </h4>
          <p
            class="font-medium inline"
            :class="isAggregateMode ? 'text-white' : 'text-gray-400'"
          >
            {{ organisation.updated_at }}
          </p>
        </div>
      </h4>

      <div class="ml-auto mr-4">
        <div class="inline-block mr-8" v-if="reportsTo">
          <h4
            class="font-normal text-sm inline mr-2"
            :class="isAggregateMode ? 'text-white' : 'text-gray-400'"
          >
            Reports to:
          </h4>
          <p
            class="inline"
            :class="isAggregateMode ? 'text-white' : 'text-gray-400'"
          >
            {{ reportsTo.name }}
          </p>
        </div>
      </div>

      <font-awesome-icon
        v-if="this.organisation?.children?.length"
        :icon="['fa', 'chevron-down']"
        class="transform transition-transform mt-0.5"
        :class="{
          'rotate-180': !this.showChildren,
          'text-white': isAggregateMode,
        }"
      />
    </button>
    <!-- System widget -->
    <div class="widgets flex flex-wrap m-2" v-if="isVisible">
      <CrowdstrikeWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.crowdstrike.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.crowdstrike"
        :org="organisation"
      />
      <VulnerabilityManagementWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.vulnerability_management.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.vulnerability_management"
        :org="organisation"
      />
      <SpyCloudWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.spycloud.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.spycloud"
        :org="organisation"
      />
      <ProofpointWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.proofpoint.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.proofpoint"
        :org="organisation"
      />
      <KnowBe4Widget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.knowbe.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.knowbe"
        :org="organisation"
      />
      <CiscoWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.ciscoumbrella.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.ciscoumbrella"
        :org="organisation"
      />
      <WebAppScansWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          orgData?.web_application_scans.has_data ||
          orgData?.web_application_scans.invict_eligible ||
          isAggregateMode
        "
        :orgData="orgData?.web_application_scans"
        :org="organisation"
      />
      <AzureWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.azuremfa.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.azuremfa"
        :org="organisation"
      />
      <WorkdayWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.workday.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.workday"
        :org="organisation"
      />
      <SixClicksWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.sixclicks.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.sixclicks"
        :org="organisation"
      />
      <SecurityScoreCardWidget
        class="p-2 flex-grow 2xl:flex-grow-0 2xl:w-1/2 3xl:w-1/3"
        v-if="
          organisation.org_type === 'VBU' ||
          orgData?.securityscorecard.has_data ||
          isAggregateMode
        "
        :orgData="orgData?.securityscorecard"
        :org="organisation"
      />
    </div>
    <div class="flex justify-center pt-8 pb-4" v-else>
      <div class="w-12 h-12">
        <SpinnerBase />
      </div>
    </div>

    <!-- EO SUMMARY -->
    <div class="px-2">
      <!-- Intro and itself info -->
      <!-- Recursive down -->
      <transition name="fade">
        <!--        <div v-show="showChildren && (!isAggregateMode || (isAggregateMode && organisation.org_type != 'VBU'))">-->
        <div v-if="showChildren">
          <DashboardObject
            v-for="node in organisation.children"
            :key="node.org_id"
            :organisation="node"
            :depth="depth + 1"
            :isParentVisible="isVisible"
            v-show="
              !isAggregateMode || (isAggregateMode && node.org_type != 'VBU')
            "
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SpinnerBase from "@/components/Base/SpinnerBase";

import CrowdstrikeWidget from "./CrowdstrikeWidget";
import WebAppScansWidget from "./WebAppScansWidget";
import SpyCloudWidget from "@/components/SpyCloudWidget";
import ProofpointWidget from "@/components/ProofpointWidget";
import KnowBe4Widget from "@/components/KnowBe4Widget";
import CiscoWidget from "@/components/CiscoWidget";
import VulnerabilityManagementWidget from "@/components/VulnerabilityManagementWidget";
import AzureWidget from "@/components/AzureWidget";
import WorkdayWidget from "@/components/WorkdayWidget";
import SixClicksWidget from "@/components/SixClicksWidget";
import SecurityScoreCardWidget from "@/components/SecurityScoreCardWidget";

export default {
  components: {
    SpinnerBase,
    SecurityScoreCardWidget,
    CrowdstrikeWidget,
    WebAppScansWidget,
    SpyCloudWidget,
    ProofpointWidget,
    KnowBe4Widget,
    CiscoWidget,
    VulnerabilityManagementWidget,
    AzureWidget,
    WorkdayWidget,
    SixClicksWidget,
  },
  name: "DashboardObject",
  props: {
    organisation: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
    isParentVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChildren: false,
      isVisible: false,
    };
  },
  mounted() {
    this.showChildren = this.isChildrenVisible;

    // let _this = this;
    // const elementToWatch = this.$refs.widgets;
    // const ob = new IntersectionObserver(function(){
    // 	console.log(_this.org.name)
    // 	_this.isVisitedByUser = true;
    // })
    // ob.observe(elementToWatch);
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
    },
    setClickedOrgInfo() {
      let orgInfo = {
        org_id: this.organisation.org_id,
        name: this.organisation.name,
        org_type: this.organisation.org_type,
      };

      localStorage.setItem(
        "clickedOrganisationOnDashboard",
        JSON.stringify(orgInfo)
      );
    },
  },
  computed: {
    indent() {
      return {
        marginLeft: `${this.depth / 10}rem`,
      };
    },
    reportsTo() {
      if (this.organisation.reports_to) {
        return this.$store.getters["structure/GET_ORGANISATION"](
          this.organisation.reports_to
        );
      } else {
        return false;
      }
    },
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    isChildrenVisible() {
      return this.$store.state.structure.isChildrenVisible;
    },
    orgData() {
      let widgetsData = this.$store.state.widgets.widgetsData;
      let orgWidgetsData = widgetsData.find(
        (item) => item.org_id == this.organisation.org_id
      )?.data;

      return {
        crowdstrike: orgWidgetsData?.crowdstrike || {},
        web_application_scans: orgWidgetsData?.web_application_scans || {},
        spycloud: orgWidgetsData?.spycloud || {},
        proofpoint: orgWidgetsData?.proofpoint || {},
        knowbe: orgWidgetsData?.knowbe || {},
        ciscoumbrella: orgWidgetsData?.ciscoumbrella || {},
        vulnerability_management:
          orgWidgetsData?.vulnerability_management || {},
        azuremfa: orgWidgetsData?.azuremfa || {},
        workday: orgWidgetsData?.workday || {},
        sixclicks: orgWidgetsData?.sixclicks || {},
        securityscorecard: orgWidgetsData?.securityscorecard || {},
      };
    },
  },
  watch: {
    isChildrenVisible() {
      this.showChildren = this.isChildrenVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.shadow-standard {
  box-shadow: -5px 5px 8px -3px rgba(0, 0, 0, 0.35);
}

.bg-trans {
  background-color: rgba(255, 255, 255, 0.025);
}

.item--name {
  @apply relative;

  &::after {
    @apply absolute top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full;

    content: "";
    left: -1.5rem;
  }

  &.vbu::after {
    @apply bg-gray-400;
  }

  &.group::after {
    @apply bg-green-500;
  }

  &.portfolio::after {
    @apply bg-blue-500;
  }
}
</style>
