<template>
	<div>
		<BackButton/>
		
		<div class="mb-12">
			<div class="flex justify-between">
				<div>
					<h4 class="text-lg mr-auto">{{ org.name }}</h4>
					<p class="mb-6">
						Number of blocked and permitted clicks over the preceding 6 months split into types
					</p>
				</div>
				<div class="text-center my-2">
					<img src="@/assets/proofpoint_logo.png" class="h-8 inline-block"/>
				</div>
			</div>

			<!--<div v-if="!proofpointData.emails_num_aggregate_count" class="bg-bgLight py-8 text-center rounded-md">-->
			<!--  <p>There's no emails coupled with this organisation.</p>-->
			<!--</div>-->
			<div class="relative py-4" v-if="!isLoading">
				<div
					v-if="!chartData?.labels?.length"
					class="bg-green-900 flex items-center justify-center inset-0 blur-drop text-center rounded-md absolute z-10"
				>
					<p>No detections found.</p>
				</div>
				<ProofpointChart :chartData="chartData" />
				<ProofpointVeryAttackedPeopleTable :veryAttackedPeople="veryAttackedPeople" :orgName="org.name" />
			</div>
		</div>
	
	</div>
</template>

<script>
import SpinnerBase from '@/components/Base/SpinnerBase';
import ProofpointChart from '@/components/ProofpointChart';
import BackButton from '@/components/Base/BackButton';
import ProofpointVeryAttackedPeopleTable from "@/components/ProofpointVeryAttackedPeopleTable";
// import {} from '@/utils/time';

export default {
	components: {
		ProofpointVeryAttackedPeopleTable,
		BackButton,
		SpinnerBase,
		ProofpointChart,
	},
	data() {
		return {
			isLoading: true,
		}
	},
	async mounted() {
		// get data and populate
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "proofpoint"
		});
		
		this.isLoading = false;
	},
	computed: {
		months() {
			var today = new Date();
			var d;
			var month;
			var newArr = [];
			for (var i = 5; i > 0; i -= 1) {
				d = new Date(today.getFullYear(), today.getMonth() - i, 1);
				month = d.toLocaleString('en-GB', {month: 'long'}).toUpperCase();
				newArr.push(`${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? `0${(d.getMonth() + 1)}` : (d.getMonth() + 1)}`);
			}
			newArr.push(`${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1)}`);
			return newArr;
		},
		orgId() {
			return this.$route.params.id;
		},
		org() {
			return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
		},
		chartData() {
			const blockedClicks = this.$store.state.systemsDetails.systemsDetailsData.data[0].blocked_clicks;
			const allowedClicks = this.$store.state.systemsDetails.systemsDetailsData.data[0].permitted_clicks;
			const chartData = {
				labels: [],
				datasets: [
					{
						label: 'Allowed Spam',
						data: [],
						backgroundColor: 'rgba(138,138,143,0.7)',
					},
					{
						label: 'Allowed Phish',
						data: [],
						backgroundColor: 'rgba(63,160,255,0.7)',
					},
					{
						label: 'Allowed Malware',
						data: [],
						backgroundColor: 'rgba(69,229,124,0.57)',
					},
					{
						label: 'Blocked Spam',
						data: [],
						backgroundColor: 'rgba(139, 92, 246, 0.7)',
					},
					{
						label: 'Blocked Phish',
						data: [],
						backgroundColor: 'rgba(245, 158, 11, 0.7)',
					},
					{
						label: 'Blocked Malware',
						data: [],
						backgroundColor: 'rgba(239, 68, 68, 0.57)',
					}
				],
			}

			// set labels
			this.months.forEach((month) => {
				chartData.labels.push(new Date(month).toLocaleString('en-GB', {month: 'long'}).toUpperCase());
			})
			
			// set data
			const months = this.months;
			
			months.forEach((month) => {
				let selectedAllowedClicksMonthData = {};
				allowedClicks.forEach((dataItem) => {
					let dataItemDate = `${dataItem.month.split('-')[0]}-${dataItem.month.split('-')[1]}`; // YYYY-MM date format
					if (month === dataItemDate) {
						selectedAllowedClicksMonthData = dataItem;
					}
				})
				
				chartData.datasets[0].data.push(selectedAllowedClicksMonthData?.spam || 0);
				chartData.datasets[1].data.push(selectedAllowedClicksMonthData?.phish || 0);
				chartData.datasets[2].data.push(selectedAllowedClicksMonthData?.malware || 0);
				
				
				let selectedBlockedClicksMonthData = {};
				blockedClicks.forEach((dataItem) => {
					let dataItemDate = `${dataItem.month.split('-')[0]}-${dataItem.month.split('-')[1]}`; // YYYY-MM date format
					if (month === dataItemDate) {
						selectedBlockedClicksMonthData = dataItem;
					}
				})
				
				chartData.datasets[3].data.push(selectedBlockedClicksMonthData?.spam || 0);
				chartData.datasets[4].data.push(selectedBlockedClicksMonthData?.phish || 0);
				chartData.datasets[5].data.push(selectedBlockedClicksMonthData?.malware || 0);
			});
			
			return chartData;
		},
		veryAttackedPeople() {
			return this.$store.state.systemsDetails.systemsDetailsData.data[0].attacked_people;
		}
	}
};
</script>

<style lang="scss" scoped>
.blur-drop {
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(4px);
}
</style>
