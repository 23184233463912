<template>
	<div v-if="isDataPrepared">
		<BackButton/>
		
		<div>
			<div class="flex justify-between">
				<div>
					<h4 class="text-lg mr-auto">{{ org.name }}</h4>
					<p class="mb-6">Historical assessments maturity percentage</p>
				</div>
				<div class="text-center my-2">
					<img src="@/assets/6clicks-logo.png" class="h-14 inline-block"/>
				</div>
			</div>
			<MaturityPercentageChart :chart-data="chartData"/>
			<SixClicksTable :org="org" :tableData="ineffectiveControls"/>
		</div>
	</div>
</template>

<script>
import SpinnerBase from '@/components/Base/SpinnerBase';
import BackButton from '@/components/Base/BackButton';
import SixClicksTable from "@/components/SixClicksTable";
import MaturityPercentageChart from "@/components/MaturityPercentageChart";

export default {
	data() {
		return {
			orderBy: {
				column: '',
				type: ''
			},
			ineffectiveControls: [],
			isDataPrepared: false
		}
	},
	components: {
		MaturityPercentageChart,
		SixClicksTable,
		BackButton,
		SpinnerBase
	},
	async created() {
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "sixclicks"
		});
		
		this.ineffectiveControls = this.$store.state.systemsDetails.systemsDetailsData.data[0].ineffective_controls;
		
		this.isDataPrepared = true;
		
		window.scrollTo({top: 0})
	},
	computed: {
		orgId() {
			return this.$route.params.id;
		},
		org() {
			return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
		},
		chartData() {
			let maturityScoreData = this.$store.state.systemsDetails.systemsDetailsData.data[0].maturity_score_data;
			
			if (!maturityScoreData || !maturityScoreData.length) return;
			
			// get latest six data
			maturityScoreData = [...maturityScoreData.slice(-6)];
			
			const data = {
				labels: [],
				datasets: [
					{
						label: 'Assessment maturity percentage',
						data: [],
						backgroundColor: 'rgba(63,160,255,0.7)'
					}
				],
			};

			maturityScoreData.forEach((dataItem) => {
				data.labels.push([dataItem.submitted_date, `${dataItem.maturity_score}/${dataItem.target_score}`])
				data.datasets[0].data.push(dataItem.percentage)
			});
			
			return data;
		},
	},
	methods: {}
};
</script>

<style lang="scss" scoped>
.blur-drop {
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(4px);
}

.tab-title-item {
	@apply px-5 py-3 rounded-tl-2xl rounded-tr-2xl border border-purple-500 border-b-0 mr-2 cursor-pointer;
}
</style>
