import { apolloClient as apollo } from '@/apollo';

const defaultStoreState = () => ({
  colorblindMode: false,
});

const state = defaultStoreState();

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultStoreState());
  },
  SET_COLORBLIND: (state, boolean) => {
    state.colorblindMode = boolean;
  }
};

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
