<template>
  <router-link
    class="card flex flex-col"
    :class="{ 'cursor-auto': !orgData?.is_clickable }"
    :to="orgData?.is_clickable ? `/spycloud/${org.org_id}` : '#'"
    @click="isClicked = true"
  >
    <div
      class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
      :class="orgData?.has_data && 'clickable'"
    >
      <div>
        <span>Digital Risk Management</span>
        <BaseTooltip
          class="relative ml-2 inline-block"
          :info="'Account Takeover Protection description'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <div class="flex items-center">
        <img
          src="@/assets/SpyCloud-logotype-white.png"
          class="h-6 mr-3 inline-block"
        />
        <div
          class="not-clickable-warning text-primary text-sm mr-3"
          v-if="!orgData?.is_clickable && isClicked"
        >
          <font-awesome-icon
            class="text-primary mr-1"
            :icon="['fa', 'info-circle']"
          />
          <span>No click-through data</span>
        </div>
        <font-awesome-icon
          v-if="orgData?.is_clickable"
          class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
          :icon="['fa', 'arrow-right']"
        />
      </div>
    </div>
    <div
      class="w-full h-full flex items-center border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode)"
    >
      <!-- Last Month -->
      <div class="flex flex-col items-center p-3 w-full">
        <div
          class="flex items-center"
          v-if="isAggregateMode && org.org_type != 'VBU'"
        >
          <font-awesome-icon
            v-if="
              (orgDataAggregate?.critical_last_month +
                orgDataAggregate?.high_last_month || 0) -
              (orgDataAggregate?.critical_last_of_last_month +
                orgDataAggregate?.high_last_of_last_month || 0)
            "
            class="text-xl mr-2"
            :class="
              (orgDataAggregate?.critical_last_month +
                orgDataAggregate?.high_last_month || 0) >
              (orgDataAggregate?.critical_last_of_last_month +
                orgDataAggregate?.high_last_of_last_month || 0)
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                (orgDataAggregate?.critical_last_month +
                  orgDataAggregate?.high_last_month || 0) >
                (orgDataAggregate?.critical_last_of_last_month +
                  orgDataAggregate?.high_last_of_last_month || 0)
                  ? 'up'
                  : 'down'
              }`,
            ]"
          />
          <font-awesome-icon
            v-else
            class="text-xl mr-2"
            :class="
              !(
                orgDataAggregate?.critical_last_month +
                orgDataAggregate?.high_last_month
              ) &&
              !(
                orgDataAggregate?.critical_last_of_last_month +
                orgDataAggregate?.high_last_of_last_month
              )
                ? 'text-green-500'
                : 'text-yellow-500'
            "
            :icon="['fa', 'key']"
          />
          <h2 class="text-4xl">
            {{
              +(
                +orgDataAggregate?.critical_last_month +
                +orgDataAggregate?.high_last_month
              ).toFixed(2) || 0
            }}
          </h2>
        </div>
        <div class="flex items-center" v-else>
          <font-awesome-icon
            v-if="
              (orgData?.critical_last_month + orgData?.high_last_month || 0) -
              (orgData?.critical_last_of_last_month +
                orgData?.high_last_of_last_month || 0)
            "
            class="text-xl mr-2"
            :class="
              (orgData?.critical_last_month + orgData?.high_last_month || 0) >
              (orgData?.critical_last_of_last_month +
                orgData?.high_last_of_last_month || 0)
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                (orgData?.critical_last_month + orgData?.high_last_month || 0) >
                (orgData?.critical_last_of_last_month +
                  orgData?.high_last_of_last_month || 0)
                  ? 'up'
                  : 'down'
              }`,
            ]"
          />
          <font-awesome-icon
            v-else
            class="text-xl mr-2"
            :class="
              !(orgData?.critical_last_month + orgData?.high_last_month) &&
              !(
                orgData?.critical_last_of_last_month +
                orgData?.high_last_of_last_month
              )
                ? 'text-green-500'
                : 'text-yellow-500'
            "
            :icon="['fa', 'key']"
          />
          <h2 class="text-4xl">
            {{ orgData?.critical_last_month + orgData?.high_last_month || 0 }}
          </h2>
        </div>
        <p class="text-sm text-gray-400 text-center">
          critical or high compromises <br />
          last month
        </p>
      </div>
      <!-- End Last Month -->

      <div class="self-stretch border-l border-gray-700"></div>

      <!-- This Month -->
      <div class="flex flex-col items-center justify-center h-full p-3 w-full">
        <div
          class="flex items-center"
          v-if="isAggregateMode && org.org_type != 'VBU'"
        >
          <font-awesome-icon
            v-if="
              (orgDataAggregate?.critical_this_month +
                orgDataAggregate?.high_this_month || 0) -
              (orgDataAggregate?.critical_last_month +
                orgDataAggregate?.high_last_month || 0)
            "
            class="text-xl mr-2"
            :class="
              (orgDataAggregate?.critical_this_month +
                orgDataAggregate?.high_this_month || 0) >
              (orgDataAggregate?.critical_last_month +
                orgDataAggregate?.high_last_month || 0)
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                (orgDataAggregate?.critical_this_month +
                  orgDataAggregate?.high_this_month || 0) >
                (orgDataAggregate?.critical_last_month +
                  orgDataAggregate?.high_last_month || 0)
                  ? 'up'
                  : 'down'
              }`,
            ]"
          />
          <font-awesome-icon
            v-else
            class="text-xl mr-2"
            :class="
              !(
                orgDataAggregate?.critical_this_month +
                orgDataAggregate?.high_this_month
              ) &&
              !(
                orgDataAggregate?.critical_last_month +
                orgDataAggregate?.high_last_month
              )
                ? 'text-green-500'
                : 'text-yellow-500'
            "
            :icon="['fa', 'key']"
          />
          <h2 class="text-4xl">
            {{
              +(
                +orgDataAggregate?.critical_this_month +
                +orgDataAggregate?.high_this_month
              ).toFixed(2) || 0
            }}
          </h2>
        </div>
        <div class="flex items-center" v-else>
          <font-awesome-icon
            v-if="
              (orgData?.critical_this_month + orgData?.high_this_month || 0) -
              (orgData?.critical_last_month + orgData?.high_last_month || 0)
            "
            class="text-xl mr-2"
            :class="
              (orgData?.critical_this_month + orgData?.high_this_month || 0) >
              (orgData?.critical_last_month + orgData?.high_last_month || 0)
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                (orgData?.critical_this_month + orgData?.high_this_month || 0) >
                (orgData?.critical_last_month + orgData?.high_last_month || 0)
                  ? 'up'
                  : 'down'
              }`,
            ]"
          />
          <font-awesome-icon
            v-else
            class="text-xl mr-2"
            :class="
              !(orgData?.critical_this_month + orgData?.high_this_month) &&
              !(orgData?.critical_last_month + orgData?.high_last_month)
                ? 'text-green-500'
                : 'text-yellow-500'
            "
            :icon="['fa', 'key']"
          />
          <h2 class="text-4xl">
            {{ orgData?.critical_this_month + orgData?.high_this_month || 0 }}
          </h2>
        </div>
        <p class="text-sm text-gray-400 text-center">
          critical or high compromises <br />
          this month
        </p>
      </div>
      <!-- End This Month -->
    </div>
    <div
      class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-else
    >
      <p class="py-8 opacity-50">No users found.</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from "@/components/Base/BaseTooltip";
import {
  currentMonth,
  prevMonth,
  prevOfPrevMonth,
  sixMonthsAgo,
} from "@/utils/time";

export default {
  components: {
    BaseTooltip,
  },
  data() {
    return {
      isClicked: false,
    };
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
    orgData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgDataAggregate() {
      let VBUs = this.org.VBUs;
      let widgetsData = this.$store.state.widgets.widgetsData;

      if (VBUs && VBUs.length) {
        let dataArr = [];
        VBUs.forEach((VBUItem) => {
          widgetsData?.forEach((orgItem) => {
            if (VBUItem.org_id === +orgItem.org_id) {
              dataArr.push({
                high_this_month: orgItem.data.spycloud.high_this_month || 0,
                critical_this_month:
                  orgItem.data.spycloud.critical_this_month || 0,
                high_last_month: orgItem.data.spycloud.high_last_month || 0,
                critical_last_month:
                  orgItem.data.spycloud.critical_last_month || 0,
                high_last_of_last_month:
                  orgItem.data.spycloud.high_last_of_last_month || 0,
                critical_last_of_last_month:
                  orgItem.data.spycloud.critical_last_of_last_month || 0,
              });
            }
          });
        });

        let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
          return {
            high_this_month:
              (sumObj.high_this_month || 0) + (currentObj.high_this_month || 0),
            critical_this_month:
              (sumObj.critical_this_month || 0) +
              (currentObj.critical_this_month || 0),
            high_last_month:
              (sumObj.high_last_month || 0) + (currentObj.high_last_month || 0),
            critical_last_month:
              (sumObj.critical_last_month || 0) +
              (currentObj.critical_last_month || 0),
            high_last_of_last_month:
              (sumObj.high_last_of_last_month || 0) +
              (currentObj.high_last_of_last_month || 0),
            critical_last_of_last_month:
              (sumObj.critical_last_of_last_month || 0) +
              (currentObj.critical_last_of_last_month || 0),
          };
        }, 0);

        return {
          high_this_month: sumVBUs.high_this_month,
          critical_this_month: sumVBUs.critical_this_month,
          high_last_month: sumVBUs.high_last_month,
          critical_last_month: sumVBUs.critical_last_month,
          high_last_of_last_month: sumVBUs.high_last_of_last_month,
          critical_last_of_last_month: sumVBUs.critical_last_of_last_month,
        };
      } else {
        return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>
