<template>
  <div class="hello flex">
    <div class="w-52">
      <nav class="space-y-1" aria-label="Sidebar">
        <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->

        <a href="#" @click.prevent="currentComponent = 'UserAdminTable'"
          class="flex items-center px-3 py-2 text-sm font-medium rounded-md"
          :class="currentComponent === 'UserAdminTable' ? 'bg-gray-100 text-gray-900' : 'text-white hover:bg-gray-50 hover:text-gray-900'">
          <span class="truncate"> Your Team </span>
        </a>
        <a v-if="isAdmin" href="#" @click.prevent="currentComponent = 'UserAdminInviteUser'"
          class="flex items-center px-3 py-2 text-sm font-medium rounded-md"
          :class="currentComponent === 'UserAdminInviteUser' ? 'bg-gray-100 text-gray-900' : 'text-white hover:bg-gray-50 hover:text-gray-900'">
          <span class="truncate"> Invite User </span>
        </a>

        <a v-if="this.$store.getters['auth/parseJwt']" href="#" @click.prevent="currentComponent = 'UserUpdatePassword'"
          class="flex items-center px-3 py-2 text-sm font-medium rounded-md"
          :class="currentComponent === 'UserUpdatePassword' ? 'bg-gray-100 text-gray-900' : 'text-white hover:bg-gray-50 hover:text-gray-900'"
          aria-current="page">
          <span class="truncate"> Update Password </span>
        </a>

        <a href="#" @click.prevent="currentComponent = 'UserAdminSettings'"
          class="flex items-center px-3 py-2 text-sm font-medium rounded-md"
          :class="currentComponent === 'UserAdminSettings' ? 'bg-gray-100 text-gray-900' : 'text-white hover:bg-gray-50 hover:text-gray-900'"
          aria-current="page">
          <span class="truncate"> Settings (In Dev) </span>
        </a>

        <a href="#" @click.prevent="currentComponent = 'AssetManagement'"
          class="flex items-center px-3 py-2 text-sm font-medium rounded-md"
          :class="currentComponent === 'AssetManagement' ? 'bg-gray-100 text-gray-900' : 'text-white hover:bg-gray-50 hover:text-gray-900'"
          aria-current="page">
          <span class="truncate"> Assets (In Dev) </span>
        </a>

      </nav>
    </div>
    <div class="flex-auto ml-5">
      <component :is="currentComponent" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SpinnerBase from "@/components/Base/SpinnerBase";
import UserUpdatePassword from "@/components/UserUpdatePassword.vue";
import UserAdminTable from "@/components/UserAdminTable.vue";
import UserAdminInviteUser from "@/components/UserAdminInviteUser.vue";
import UserAdminSettings from "@/components/UserAdminSettings.vue";
import AssetManagement from "@/components/AssetManagement.vue"

import InputBase from "@/components/Base/InputBase";

export default {
  name: "Dashboard",
  components: {
    InputBase,
    SpinnerBase,
    UserUpdatePassword,
    UserAdminTable,
    UserAdminInviteUser,
    UserAdminSettings,
    AssetManagement
  },
  data() {
    return {
      currentComponent: "UserAdminTable",
      currentPw: "",
      newPw: "",
      newPwConfirm: "",
      isLoading: false,
      error: "",
      message: "",
    };
  },
  // mixins: [validationMixin],
  // validations: {
  //   currentPw: {
  //     required,
  //   },
  //   newPw: {
  //     required,
  //   },
  //   newPwConfirm: {
  //     required,
  //     sameAs: sameAs('newPw'),
  //   },
  // },
  methods: {
    async updatePassword() {
      // this.$v.$touch();

      this.error = "";
      this.message = "";

      const data = {
        password: this.currentPw,
        passwordNew: this.newPw,
        passwordNewConfirm: this.newPwConfirm,
      };

      try {
        this.isLoading = true;

        const res = await axios.post(
          `https:/id.redsmart.app/auth/password-update`,
          data,
          {
            withCredentials: true,
          }
        );

        if (res.status > 399 && res.status < 200) {
          throw new Error();
        }

        this.message = "Your password has been updated";

        this.isLoading = false;
      } catch (e) {
        console.log(e.response.data.message);
        this.error = e.response.data.message;

        this.isLoading = false;
      }
    },
  },
  computed: {
    isAdmin() {
      const adminStatus = this.$store.getters['auth/parseJwt']["x-hasura-default-role"];
      if (adminStatus === 'tenant_admin') {
        return true
      }
      else return false
    }
  }
}
</script>
