<template>
	<div>
		<BackButton/>
		
		<div>
			<div class="flex justify-between">
				<div>
					<h4 class="text-lg mr-auto">{{ org.name }}</h4>
					<p class="mb-6">
						Number of issues detected in the 6 most recent website scans
					</p>
				</div>
				<div class="text-center my-2">
					<img src="@/assets/invicti_logo.png" class="h-12 inline-block"/>
				</div>
			</div>
			
			<div
				class="border-t border-gray-800 p-8"
				v-for="(website, index) in orgWebsites"
				:key="index"
			>
				<p class="text-gray-500 text-lg mb-2">
					Website:
					<span class="text-white">{{ website.root_url }}</span>
				</p>
				<p class="text-gray-500 text-lg">
					Outstanding issues:
					<span class="text-white mr-1">
            {{ website?.most_recent_details?.completed_scan_outstanding_issues || 0 }}
          </span>
					<!-- <font-awesome-icon
						class="text-yellow-400 text-xs inline-block"
						v-if="website?.scan_details[0].issues_aggregate?.aggregate?.count > 0"
						:icon="['fa', 'exclamation-triangle']"
					/> -->
				</p>
				<p class="text-gray-500 text-lg">
					Last scan status:
					<span class="text-white">{{ website.most_recent_details.last_scan_status || 0 }}</span>
				</p>
				<p class="text-gray-500 text-lg">
					Scan failure reason:
					<span class="text-white">{{ website.most_recent_details.failure_reason || 0 }}</span>
				</p>
				<p class="text-gray-500 text-lg">
					Days since last scan:
					<span class="text-white">{{ website.most_recent_details.days_since_last_scan || 0 }}</span>
				</p>
				<p class="text-gray-500 text-lg">
					Scanning tool:
					<span class="text-white">Invicti</span>
				</p>
				<div class="spacer mt-3"></div>
				<WebAppScansChart :scans="website.details"/>
			</div>
		</div>
		<!--<div class="w-10 h-10 mx-auto my-4" v-else>-->
		<!--  <SpinnerBase />-->
		<!--</div>-->
	</div>
</template>

<script>
import WebAppScansChart from '@/components/WebAppScansChart';
import SpinnerBase from '@/components/Base/SpinnerBase';
import BackButton from '@/components/Base/BackButton';
import systems_details from "@/store/modules/systems_details";

export default {
	data() {
		return {
			orgWebsites: []
		}
	},
	components: {
		BackButton,
		WebAppScansChart,
		SpinnerBase,
	},
	async created() {
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "invicti"
		});
		
		this.orgWebsites = this.$store.state.systemsDetails.systemsDetailsData.data;
	},
	computed: {
		orgId() {
			return this.$route.params.id;
		},
		org() {
			return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
		}
	}
};
</script>

<style lang="scss" scoped></style>
