import { apolloClient as apollo } from "@/apollo";
import {
  getColor,
  coloursScale,
  getSpycloudDeploymentColor,
  getSixClicksDeploymentColor,
  getScorecardOperationalColor,
} from "@/utils/colors";
import structure from "@/store/modules/structure";

const defaultStoreState = () => ({
  heatmapData: [],
  heatmapColorsValues: {},
});

const state = defaultStoreState();

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultStoreState());
  },
  // Set Data First Time
  SET_HEATMAP_DATA: (state, { heatmapData }) => {
    state.heatmapData = [...heatmapData];
    // console.log("Heatmap ->", state.heatmapData)
  },

  // Set Systems Colors (Heatmap)
  SET_CROWDSTRIKE_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    if (data) {
      let operationalValue = data?.operational?.last_month_detections;
      let deploymentValue = data?.deployment?.percent_deployed;

      heatmapValues = {
        operational: {
          value:
            isNaN(operationalValue) || !Math.round(deploymentValue)
              ? "notValid"
              : operationalValue || 0,
          color:
            isNaN(operationalValue) || !Math.round(deploymentValue)
              ? NaN
              : getColor(
                  settings.crowdstrike_operational,
                  data?.operational?.detections_per_asset
                ),
          colorBase: data?.operational?.detections_per_asset,
        },
        deployment: {
          value: Math.round(deploymentValue) || "notValid",
          color: !deploymentValue
            ? NaN
            : getColor(
                settings.crowdstrike_deployment,
                Math.round(deploymentValue)
              ),
        },
      };
    }

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };

    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      crowdstrike: heatmapValues,
    };
  },
  SET_WEB_APPLICATION_SCANS_COLORS(state, { data, settings, org }) {
    let initValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    if (data?.deployment && data?.operational) {
      let lastScanDays = data?.deployment?.days_since_recent_scan;
      initValues.deployment = {
        value: lastScanDays >= 0 ? lastScanDays : "notValid",
        color: getColor(settings.invicti_deployment, lastScanDays),
      };

      // find vulnerability
      let hasAnyVulnerability = data?.operational?.critical_detections > 0;
      let totalDetections = data?.operational?.total_detections;
      let noScan =
        data?.operational?.total_detections === null &&
        data?.operational?.critical_detections === null
          ? true
          : false;
      initValues.operational = {
        value: totalDetections >= 0 ? totalDetections : "notValid",
        color: hasAnyVulnerability
          ? getColor(settings.invicti_operational, totalDetections, false, true)
          : noScan
          ? getColor(settings.invicti_operational, totalDetections, false, true)
          : getColor(settings.invicti_operational, totalDetections),
        colorBase: data?.operational?.critical_detections,
      };
    }

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type) || (org.invicti_eligible === "0" && !(data?.deployment || data?.operational))) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        initValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!initValues.operational.color)
      initValues.operational.color = { normal: NaN, blind: NaN };

    if (!initValues.deployment.color)
      initValues.deployment.color = { normal: NaN, blind: NaN };

    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      web_application_scans: initValues,
    };
  },
  SET_PROOFPOINT_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    if (data?.deployment && data?.operational) {
      const operationalValue = data?.operational?.blocked_clicks_last_month;
      const deploymentValue = data?.deployment?.allowed_clicks_last_month;

      heatmapValues = {
        operational: {
          value: operationalValue,
          color: getColor(
            settings.proofpoint_operational,
            data?.operational?.blocked_clicks_per_employee
          ),
          colorBase: data?.operational?.blocked_clicks_per_employee,
        },
        deployment: {
          value: deploymentValue,
          color: getColor(
            settings.proofpoint_deployment,
            data?.deployment?.allowed_clicks_per_employee
          ),
          colorBase: data?.deployment?.allowed_clicks_per_employee,
        },
      };
    }

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };

    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      proofpoint: heatmapValues,
    };
  },
  SET_KNOWBE_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    if (data?.deployment && data?.operational) {
      const operationalValue = data?.operational?.days_since_last_course;
      // const deploymentValue = data?.deployment?.percentage_users_in_group;
      const deploymentValue =
        data?.deployment?.percentage_azure_users_on_knowbe4;
      const numUsers = data?.deployment?.num_knowbe4_users;

      const isPositiveNumber =
        typeof operationalValue === "number" && operationalValue >= 0;
      const isNegativeNumberOrNull =
        operationalValue === null ||
        (typeof operationalValue === "number" && operationalValue < 0);

      heatmapValues = {
        operational: {
          value: isPositiveNumber
            ? operationalValue
            : isNegativeNumberOrNull
            ? "none"
            : "notValid",
          color:
            typeof operationalValue === "number" && operationalValue >= 0
              ? getColor(settings.knowbe4_operational, operationalValue)
              : operationalValue === null || operationalValue < 0
              ? getColor(
                  settings.knowbe4_operational,
                  operationalValue,
                  true,
                  true
                )
              : NaN,
        },
        deployment: {
          value:
            deploymentValue > 0
              ? Math.round(deploymentValue)
              : numUsers > 0
              ? "n/a"
              : "notValid",
          color: !deploymentValue
            ? getColor(settings.knowbe4_deployment, Math.round(deploymentValue))
            : getColor(
                settings.knowbe4_deployment,
                Math.round(deploymentValue)
              ),
        },
      };

      // console.log(org.name, data, (data?.operational?.days_since_last_course === null))
    }
    // else { // todo: should be removed
    //   console.log(org.name, data, (data?.operational?.days_since_last_course === null))
    // }

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };
    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      knowbe: heatmapValues,
    };
    // console.log(org.org_id, org.name, heatmapValues)
  },
  SET_SPYCLOUD_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    // DEPLOYMENT VALUE
    let deploymentStatus = data?.deployment?.status;
    heatmapValues.deployment = {
      value: ["!", null, undefined].includes(deploymentStatus)
        ? "notValid"
        : deploymentStatus,
      color: ["ourvolaris", "!"].includes(deploymentStatus)
        ? NaN
        : getSpycloudDeploymentColor(deploymentStatus),
    };

    // OPERATIONAL VALUE
    let lastMonthBreaches = data?.operational?.breaches_last_month;

    heatmapValues.operational = {
      value: lastMonthBreaches >= 0 ? lastMonthBreaches : "notValid",
      color: getColor(settings.spycloud_operational, lastMonthBreaches),
    };

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };

    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      spycloud: heatmapValues,
    };
  },
  SET_CISCO_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    // DEPLOYMENT VALUE
    const deploymentValue = data?.deployment?.status === "active" || data?.deployment?.status ==="active*" || data?.deployment?.status ==="active**";
    const activeS = data?.deployment?.status ==="active*"
    const activeSs = data?.deployment?.status ==="active**"

    // OPERATIONAL VALUE
    let operationalValue = data?.operational?.last_month_threats;

    if (activeS) {

      heatmapValues.operational = {
        value: 'N/A.',
        color: getColor(settings.cisco_operational, null),
      };
      heatmapValues.deployment = {
        value: deploymentValue ? "active*" : "notValid",
        color: getColor(null, null, true),
      };

    } else if (activeSs) {

      heatmapValues.deployment = {
        value: deploymentValue ? "active**" : "notValid",
        color: getColor(settings.cisco_operational, 1),
      };

      if (operationalValue == 0) {
        heatmapValues.operational = {
          value: 'N/A.',
          color: getColor(settings.cisco_operational, null),
        };
      } else {
        heatmapValues.operational = {
          value: isNaN(operationalValue) ? "notValid" : operationalValue || 0,
          color: getColor(settings.cisco_operational, null),
        };
      }

    } else {

      heatmapValues.operational = {
        value: isNaN(operationalValue) ? "notValid" : operationalValue || 0,
        color: isNaN(operationalValue)
          ? NaN
          : getColor(settings.cisco_operational, operationalValue),
      };

      heatmapValues.deployment = {
        value: deploymentValue ? "active" : "notValid",
        color: !deploymentValue ? NaN : getColor(null, deploymentValue, true),
      };
    }

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };

    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      cisco: heatmapValues,
    };
  },
  SET_VULNERABILITY_MANAGEMENT_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    if (data?.deployment && data?.operational) {
      // DEPLOYMENT VALUE
      let deploymentValue = data?.deployment?.percent_deployed;

      heatmapValues.deployment = {
        value: Math.round(deploymentValue),
        color: getColor(
          settings.rapid7_deployment,
          Math.round(deploymentValue)
        ),
      };

      // OPERATIONAL VALUE
      const operationalValue = data?.operational?.total_vulnerabilites;

      heatmapValues.operational = {
        value: [0, null].includes(deploymentValue) ? "N/A" : operationalValue,
        color: [0, null].includes(deploymentValue)
          ? getColor(
              settings.rapid7_operational,
              data?.operational?.vulnerabilities_per_asset,
              true,
              true
            )
          : getColor(
              settings.rapid7_operational,
              data?.operational?.vulnerabilities_per_asset
            ),
        colorBase: data?.operational?.vulnerabilities_per_asset,
      };
    }

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };
    // console.log(org.org_id, org.name, data?.operational, data?.deployment, heatmapValues)
    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      vulnerability_management: heatmapValues,
    };
  },
  SET_AZURE_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    // DEPLOYMENT VALUE
    let deploymentValue = data?.deployment?.status === "active";
    heatmapValues.deployment = {
      value: deploymentValue ? "active" : "notValid",
      color: !deploymentValue ? NaN : getColor(null, deploymentValue, true),
    };

    // OPERATIONAL VALUE
    let percentageUsersMfa = data?.operational?.percentage_users_mfa;
    let operationalValue =
      typeof percentageUsersMfa == "number"
        ? Math.round(percentageUsersMfa)
        : percentageUsersMfa;
    heatmapValues.operational = {
      value: ![null, undefined].includes(operationalValue)
        ? operationalValue
        : "notValid",
      color: getColor(settings.azure_operational, operationalValue),
    };

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };
    // console.log(org.org_id, org.name, data?.operational, heatmapValues)
    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      azure: heatmapValues,
    };
  },
  SET_WORKDAY_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    if (data?.deployment && data?.operational) {
      const operationalValue = data?.operational?.avg_days_processing_leaver;
      const deploymentValue = data?.deployment?.current_quarter_leavers;

      heatmapValues = {
        operational: {
          value:
            typeof operationalValue == "number" ? operationalValue : "notValid",
          color:
            operationalValue <= 0
              ? getColor(settings.workday_operational, 0)
              : isNaN(operationalValue) || operationalValue == null
              ? NaN
              : getColor(settings.workday_operational, operationalValue),
        },
        deployment: {
          value: deploymentValue,
          color:
            deploymentValue > 0
              ? getColor(settings.workday_deployment, false, true)
              : getColor(settings.workday_deployment, true, true),
        },
      };
    }

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };
    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      workday: heatmapValues,
    };
    // console.log(org.org_id, org.name, heatmapValues)
  },
  SET_SIX_CLICKS_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    // deployment
    let deploymentValue = data?.deployment?.status;
    heatmapValues.deployment = {
      value:
        !deploymentValue || deploymentValue === "!"
          ? "notValid"
          : deploymentValue,
      color: [null, undefined, "!"].includes(deploymentValue)
        ? NaN
        : getSixClicksDeploymentColor(deploymentValue),
    };

    // operational
    let operationalValue = data?.operational?.maturity_percentage;
    heatmapValues.operational = {
      value: [undefined].includes(operationalValue)
        ? "notValid"
        : operationalValue,
      color:
        isNaN(operationalValue) || [null, "!"].includes(deploymentValue)
          ? NaN
          : getColor(settings.sixclicks_operational, operationalValue),
    };
    if (operationalValue === null && deploymentValue === "none") {
      heatmapValues = {
        operational: {
          color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
          value: "N/A",
        },
        deployment: {
          color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
          value: "N/A",
        },
      };
    }

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };

    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      sixclicks: heatmapValues,
    };
  },
  SET_SECURITY_SCORE_CARD_COLORS(state, { data, settings, org }) {
    let heatmapValues = {
      operational: { color: NaN, value: "notValid" },
      deployment: { color: NaN, value: "notValid" },
    };

    // deployment
    let deploymentValue = data?.deployment?.status;
    heatmapValues.deployment = {
      value:
        !deploymentValue || deploymentValue === "!"
          ? "notValid"
          : deploymentValue,
      color: [null, undefined, "!"].includes(deploymentValue)
        ? NaN
        : getColor(
            settings.scorecard_deployment,
            deploymentValue === "active",
            true
          ),
    };

    // operational
    let operationalValue = data?.operational?.overall_grade;
    heatmapValues.operational = {
      value:
        !operationalValue || operationalValue === "!"
          ? "notValid"
          : operationalValue,
      color: [null, undefined, "!"].includes(operationalValue)
        ? NaN
        : getScorecardOperationalColor(operationalValue),
    };

    if (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type)) {
      if (!["Volaris Consolidated", "Lumine Group"].includes(org.name)) {
        heatmapValues = {
          operational: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
          deployment: {
            color: { normal: "23 ,23 ,33", blind: "23 ,23 ,33" },
            value: "N/A",
          },
        };
      }
    }

    if (!heatmapValues.operational.color)
      heatmapValues.operational.color = { normal: NaN, blind: NaN };

    if (!heatmapValues.deployment.color)
      heatmapValues.deployment.color = { normal: NaN, blind: NaN };

    state.heatmapColorsValues[org.org_id] = {
      ...state.heatmapColorsValues[org.org_id],
      securityscorecard: heatmapValues,
    };
  },
};

const actions = {
  getHeatmapData({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { dash_two_heatmap },
        } = await apollo.query({
          query: require("@/graphql/get_heatmap_data.gql"),
        });

        // console.log("Heatmap data", dash_two_heatmap)

        commit("SET_HEATMAP_DATA", { heatmapData: dash_two_heatmap });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
  setHeatmapColors({ commit, state, rootState }) {
    state.heatmapData.forEach((heatmapItem, index) => {
      // add colors to heatmap data
      state.heatmapData[index] = { ...heatmapItem, colors: {} };
      let settings = rootState.dashboardSettings?.data[0] || {};
      let org = rootState.structure?.data?.find(
        (org) => org.org_id == heatmapItem.org_id
      );

      if (!org) return; // if org not exist on structure don't do anything

      // set all systems colors for heatmap
      commit("SET_CROWDSTRIKE_COLORS", {
        data: heatmapItem.data.crowdstrike,
        settings,
        org,
      });

      commit("SET_WEB_APPLICATION_SCANS_COLORS", {
        data: heatmapItem.data.web_application_scans,
        settings,
        org,
      });

      commit("SET_PROOFPOINT_COLORS", {
        data: heatmapItem.data.proofpoint,
        settings,
        org,
      });

      commit("SET_KNOWBE_COLORS", {
        data: heatmapItem.data.knowbe,
        settings,
        org,
      });

      commit("SET_SPYCLOUD_COLORS", {
        data: heatmapItem.data.spycloud,
        settings,
        org,
      });

      commit("SET_CISCO_COLORS", {
        data: heatmapItem.data.ciscoumbrella,
        settings,
        org,
      });

      commit("SET_VULNERABILITY_MANAGEMENT_COLORS", {
        data: heatmapItem.data.vulnerability_management,
        settings,
        org,
      });

      commit("SET_AZURE_COLORS", {
        data: heatmapItem.data.azuremfa,
        settings,
        org,
      });

      commit("SET_WORKDAY_COLORS", {
        data: heatmapItem.data.workday,
        settings,
        org,
      });

      commit("SET_SIX_CLICKS_COLORS", {
        data: heatmapItem.data.sixclicks,
        settings,
        org,
      });

      commit("SET_SECURITY_SCORE_CARD_COLORS", {
        data: heatmapItem.data.securityscorecard,
        settings,
        org,
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
