<template>
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !orgData?.is_clickable}"
    :to="orgData?.is_clickable ? `/sixclicks/${org.org_id}` : '#'"
    @click="isClicked = true"
  >
    <div
      class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
      :class="orgData?.has_data && 'clickable'"
    >
      <div>
	      <span>GRC & Risk Management</span>
        <BaseTooltip class="relative ml-2 inline-block" :info="'GRC & Risk Management description'">
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <div class="flex items-center">
	      <img src="@/assets/6clicks-logo.png" class="h-7 mr-3 inline-block"/>
        <div class="not-clickable-warning text-primary text-sm mr-3" v-if="!orgData?.is_clickable && isClicked || isClicked && orgData?.vendor_only">
          <font-awesome-icon
            class="text-primary mr-1"
            :icon="['fa', 'info-circle']"
          />
          <span>No click-through data</span>
        </div>
        <font-awesome-icon
          v-if="orgData?.is_clickable && !orgData?.vendor_only"
          class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
          :icon="['fa', 'arrow-right']"
        />
      </div>

    </div>
    <div
	    class="h-full grid grid-cols-3 border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode && !orgData?.vendor_only)"
    >
     
	    <!-- First Column -->
      <div
        class="col-span-2 flex flex-col items-center justify-center border-r border-gray-700 p-3"
      >
        <div v-if="isAggregateMode && org.org_type != 'VBU'">
	        <div class="flex items-center">
		        <font-awesome-icon
			        class="text-xl mr-2"
			        :class="{
							'text-red-500': (orgDataAggregate?.current_maturity_percentage || 0) < 20,
							'text-yellow-500': (orgDataAggregate?.current_maturity_percentage || 0) >= 20 && (orgDataAggregate?.current_maturity_percentage || 0) < 40,
							'text-yellow-400': (orgDataAggregate?.current_maturity_percentage || 0) >= 40 && (orgDataAggregate?.current_maturity_percentage || 0) < 60,
							'text-yellow-300': (orgDataAggregate?.current_maturity_percentage || 0) >= 60 && (orgDataAggregate?.current_maturity_percentage || 0) < 80,
							'text-green-500': (orgDataAggregate?.current_maturity_percentage || 0) >= 80,
			      }"
			        :icon="['fa', 'edit']"
		        />
		        <h2 class="text-4xl">{{ orgDataAggregate?.current_maturity_percentage || 0 }}%</h2>
	        </div>
	        <span class="flex font-thin text-xs justify-center text-gray-400">{{ orgData?.maturity_score_this_month || 0 }}</span>
        </div>
	      <div v-else>
		      <div class="flex flex-col items-center">
				<span class="flex flex-row content-center">
			      <font-awesome-icon
				      class="flex self-center text-xl mr-1"
				      :class="{
							'text-red-500': (orgData?.current_maturity_percentage || 0) < 20,
							'text-yellow-500': (orgData?.current_maturity_percentage || 0) >= 20 && (orgData?.current_maturity_percentage || 0) < 40,
							'text-yellow-400': (orgData?.current_maturity_percentage || 0) >= 40 && (orgData?.current_maturity_percentage || 0) < 60,
							'text-yellow-300': (orgData?.current_maturity_percentage || 0) >= 60 && (orgData?.current_maturity_percentage || 0) < 80,
							'text-green-500': (orgData?.current_maturity_percentage || 0) >= 80,
			      }"
				      :icon="['fa', 'edit']"
			      />
			      <h2 class="text-4xl">{{ orgData?.current_maturity_percentage || 0 }}%</h2>
				</span>
		      </div>
		      <span class="flex font-thin text-sm justify-center text-gray-400">{{ orgData?.current_maturity_score || 0 }}</span>
			  <span class="text-sm text-gray-400">percentage of compliance to the baseline of 67 controls</span>
	      </div>
            <span class="text-xs text-gray-400" v-if="!isAggregateMode">assessment submitted date - {{ orgData?.current_maturity_date }}</span>
			<span class="text-sm text-gray-400 text-center" v-else> average maturity </span>
        </div>
	    <!-- End First Column -->
	    
	    <!-- Second Column -->
      <div class="flex flex-col justify-center">
        <div class="px-2 py-3 border-b border-gray-700">
          <div class="flex items-center justify-center" v-if="isAggregateMode && org.org_type != 'VBU'">
	          <font-awesome-icon
		          class="mr-2"
		          :class="
		            (orgDataAggregate.issues_this_month || 0) >
		            (orgDataAggregate.issues_last_month || 0)
		            ? 'text-red-500' :
		              'text-green-500'
							"
			          :icon="[
	              'fa',
	              `arrow-alt-circle-${
									(orgDataAggregate.issues_this_month || 0) >
									(orgDataAggregate.issues_last_month || 0)
									? 'up' :
										'down'
								}`,
	            ]"
	          />
	          <h4 class="uppercase">{{ orgDataAggregate.issues_this_month }}</h4>
          </div>
	        <div class="flex items-center justify-center" v-else>
		        <font-awesome-icon
			        class="mr-2"
			        :class="
		            (orgData?.issues_this_month || 0) >
		            (orgData?.issues_last_month || 0)
		            ? 'text-red-500' :
		              'text-green-500'
							"
			        :icon="[
	              'fa',
	              `arrow-alt-circle-${
									(orgData?.issues_this_month || 0) >
									(orgData?.issues_last_month || 0)
									? 'up' :
										'down'
								}`,
	            ]"
		        />
		        <h4 class="uppercase">{{ orgData?.issues_this_month }}</h4>
	        </div>
          <p class="text-xs text-gray-400 text-center">issues</p>
        </div>
        <div class="px-2 py-3">
	        <div class="flex items-center justify-center" v-if="isAggregateMode && org.org_type != 'VBU'">
		        <font-awesome-icon
			        class="mr-2"
			        :class="
		            (orgDataAggregate.risks_this_month || 0) >
		            (orgDataAggregate.risks_last_month || 0)
		            ? 'text-red-500' :
		              'text-green-500'
							"
			        :icon="[
	              'fa',
	              `arrow-alt-circle-${
									(orgDataAggregate.risks_this_month || 0) >
									(orgDataAggregate.risks_last_month || 0)
									? 'up' :
										'down'
								}`,
	            ]"
		        />
		        <h4 class="uppercase">{{ orgDataAggregate.risks_this_month }}</h4>
	        </div>
	        <div class="flex items-center justify-center" v-else>
		        <font-awesome-icon
			        class="mr-2"
			        :class="
		            (orgData?.risks_this_month || 0) >
		            (orgData?.risks_last_month || 0)
		            ? 'text-red-500' :
		              'text-green-500'
							"
			        :icon="[
	              'fa',
	              `arrow-alt-circle-${
									(orgData?.risks_this_month || 0) >
									(orgData?.risks_last_month || 0)
									? 'up' :
										'down'
								}`,
	            ]"
		        />
		        <h4 class="uppercase">{{ orgData?.risks_this_month }}</h4>
	        </div>
          <p class="text-xs text-gray-400 text-center">risks</p>
        </div>
      </div>
	    <!-- End Second Column -->
	    
    </div>
    <div class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg" v-else>
      <p class="py-8 opacity-50 text-center">
        Organisation is in the GRC platform but has no data for issues, risks or assessments
      </p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
  components: {
    BaseTooltip,
  },
  data() {
    return {
      isClicked: false
    }
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
	  orgData: {
		  type: Object,
		  required: true,
	  }
  },
	computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
	  orgDataAggregate() {
		  let VBUs = this.org.VBUs;
		  let widgetsData = this.$store.state.widgets.widgetsData;
		
		  if (VBUs && VBUs.length) {
			  let dataArr = [];
			  VBUs.forEach((VBUItem) => {
				  widgetsData?.forEach((orgItem) => {
					  if(VBUItem.org_id === +orgItem.org_id) {
						  dataArr.push({
							  current_maturity_percentage: orgItem.data.sixclicks.current_maturity_percentage || 0,
							  previous_maturity_percentage: orgItem.data.sixclicks.previous_maturity_percentage || 0,
							  maturity_score_this_month: orgItem.data.sixclicks.maturity_score_this_month || 0,
							  issues_this_month: orgItem.data.sixclicks.issues_this_month || 0,
							  issues_last_month: orgItem.data.sixclicks.issues_last_month || 0,
							  risks_this_month: orgItem.data.sixclicks.risks_this_month || 0,
							  risks_last_month: orgItem.data.sixclicks.risks_last_month || 0,
							  current_maturity_date: orgItem.data.sixclicks.current_maturity_date || 0,
						  })
					  }
				  })
			  })
			
			  let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
				  return {
					  current_maturity_percentage: (sumObj.current_maturity_percentage || 0) + (currentObj.current_maturity_percentage || 0),
					  previous_maturity_percentage: (sumObj.previous_maturity_percentage || 0) + (currentObj.previous_maturity_percentage || 0),
					  maturity_score_this_month: (sumObj.maturity_score_this_month || 0) + (currentObj.maturity_score_this_month || 0),
					  issues_this_month: (sumObj.issues_this_month || 0) + (currentObj.issues_this_month || 0),
					  issues_last_month: (sumObj.issues_last_month || 0) + (currentObj.issues_last_month || 0),
					  risks_this_month: (sumObj.risks_this_month || 0) + (currentObj.risks_this_month || 0),
					  risks_last_month: (sumObj.risks_last_month || 0) + (currentObj.risks_last_month || 0),
				  }
			  }, 0)
			
			  return {
				  current_maturity_percentage: Number.isInteger(sumVBUs.current_maturity_percentage / dataArr.length) ?
					  (sumVBUs.current_maturity_percentage / dataArr.length) : (sumVBUs.current_maturity_percentage / dataArr.length).toFixed(2),
				  previous_maturity_percentage: Number.isInteger(sumVBUs.previous_maturity_percentage / dataArr.length) ?
					  (sumVBUs.previous_maturity_percentage / dataArr.length) : (sumVBUs.previous_maturity_percentage / dataArr.length).toFixed(2),
				  maturity_score_this_month: Number.isInteger(sumVBUs.maturity_score_this_month / dataArr.length) ?
					  (sumVBUs.maturity_score_this_month / dataArr.length) : (sumVBUs.maturity_score_this_month / dataArr.length).toFixed(2),
				  issues_this_month: sumVBUs.issues_this_month,
				  issues_last_month: sumVBUs.issues_last_month,
				  risks_this_month: sumVBUs.risks_this_month,
				  risks_last_month: sumVBUs.risks_last_month,
			  };
		  } else {
			  return {}
		  }
	  },
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;

    h4 {
      @apply relative pl-2;

      &::before {
        @apply w-2 h-2 rounded-full bg-white absolute top-2 -left-2 opacity-20;

        content: '';
      }

      // border-bottom: 1px rgba(255, 255, 255, 0.467) dashed;

      &.group::before {
        @apply opacity-100;
        background-color: rgb(90, 192, 30);
      }

      &.portfolio::before {
        @apply opacity-100 bg-blue-500;
      }
    }
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>
