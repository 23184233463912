<template>
	<div class="slider-container inline-flex flex-col">
		<div class="slider-min text-center mb-1.5">{{ minMaxValues.min }}</div>
		<div class="slider-border flex rounded-full border-white border-8" style="box-shadow: #00000040 0px 0px 5px 3px inset;">
			<div class="slider inline-block w-18 h-96 rounded-full overflow-hidden" ref="slider">
				<template v-if="settings[0] && settings[0]['colorPercent']">
					<div
						v-for="(color, index) in colorList"
						:class="`${color.name} relative`"
						:key="index"
						:style="`background: rgb(${color[colorMode]});height: ${settings[index]['colorPercent']}%;`"
					>
				    <span
					    class="line absolute -bottom-1 w-full h-2 cursor-ns-resize z-10"
					    v-if="index !== 4"
					    draggable="true"
					    @dragstart="sliderDragStart()"
					    @drag="sliderDraging()"
					    @dragend="sliderDragEnd()"
				    ></span>
					</div>
				</template>
			</div>
		</div>
		<div class="slider-max text-center mt-1.5">{{ minMaxValues.max }}</div>
	</div>
</template>

<script>
export default {
	name: 'UserAdminSettingsSlider',
	data() {
		return {
			drag: {
				start: null,
				end: null,
				current: null
			},
			slider: null,
			sliderHeight: 0
		};
	},
	props: {
		colorList: {
			type: Array,
			default: []
		},
		minMaxValues: {
			type: Object,
			default: {}
		},
		settings: {
			type: Object,
			default: {}
		},
		reversed: {
			type: Boolean
		},
		activeTabKey: {
			type: String
		}
	},
	computed: {
		colorMode() {
			return this.$store.state.ui.colorblindMode ? 'blind' : 'normal';
		},
	},
	beforeMount() {
	
	},
	mounted() {
		this.slider = this.$refs.slider;
		this.sliderHeight = this.slider.clientHeight;
		
		this.setPercentForEachColor();
	},
	methods: {
		sliderDragStart() {
			// console.log("drag start pageY: ", event.pageY)
			
			this.drag.current = event.pageY;
		},
		sliderDragEnd() {
			// console.log("drag end pageY: ", event.pageY)
			
			let newObject = {};
			if (this.reversed) {
				let keys = Object.keys(this.settings).reverse();
				keys.forEach((value, index) => {
					newObject[index] = this.settings[value]
				})
			}
		
			this.$store.commit('dashboardSettings/UPDATE_DASHBOARD_SETTINGS_BY_INDEX', {
				settings: this.reversed ? newObject : this.settings,
				activeTabKey: this.activeTabKey
			})
		},
		sliderDraging() {
			// console.log("draging pageY: ", event.pageY)
			
			let delimiter = event.target;
			let delimiterParent = delimiter.parentNode;
			let nextSibling = delimiterParent.nextElementSibling;
			let nextSiblingHeight = nextSibling.offsetHeight;
			let delimiterParentHeight = delimiterParent.offsetHeight;
			let movementAmount = this.drag.current ? this.drag.current - event.pageY : 0;
			this.drag.current = event.pageY;
			
			let delimiterParentNewHeight = delimiterParentHeight;
			if (delimiterParentHeight - movementAmount >= 1) delimiterParentNewHeight = delimiterParentHeight - movementAmount;
			
			let nextSiblingNewHeight = nextSiblingHeight;
			if (nextSiblingHeight + movementAmount >= 1) nextSiblingNewHeight = nextSiblingHeight + movementAmount;
			
			
			let sliderSections = Array.from(this.slider.childNodes).filter((item) => item.clientHeight);
			let delimiterParentIndex = sliderSections.indexOf(delimiterParent);
			let sliderSectionsSumHeights = 0;
			sliderSections.forEach((item, index) => {
				if ([delimiterParentIndex, delimiterParentIndex + 1].indexOf(index) == -1) {
					sliderSectionsSumHeights += item.offsetHeight;
				}
			})
			sliderSectionsSumHeights += (delimiterParentNewHeight + nextSiblingNewHeight)
			
			if (sliderSectionsSumHeights <= (this.sliderHeight + 1)) {
				if (nextSiblingNewHeight > 1) {
					delimiterParent.style.height = `${delimiterParentNewHeight}px`;
					nextSibling.style.height = `${nextSiblingNewHeight}px`;
				}
			}
			
			// set new value
			let offsetPositions = {
				current: {
					index: delimiterParentIndex,
					offsetTop: delimiterParent.offsetTop,
					offsetBottom: delimiterParent.offsetTop + delimiterParent.offsetHeight,
					newValue: function (sliderHeight, minMaxValues) {
						let totalMax = isNaN(minMaxValues.max) ? Number(minMaxValues.max.replace(/,/g, "")) : minMaxValues.max;
						
						return {
							min: +((this.offsetTop / sliderHeight) * totalMax),
							max: +((this.offsetBottom / sliderHeight) * totalMax)
						};
					}
				},
				next: {
					index: delimiterParentIndex + 1,
					offsetTop: nextSibling.offsetTop,
					offsetBottom: nextSibling.offsetTop + nextSibling.offsetHeight,
					newValue: function (sliderHeight, minMaxValues) {
						let totalMax = isNaN(minMaxValues.max) ? Number(minMaxValues.max.replace(/,/g, "")) : minMaxValues.max;
						
						return {
							min: +((this.offsetTop / sliderHeight) * totalMax),
							max: +((this.offsetBottom / sliderHeight) * totalMax)
						};
					}
				}
			}
		
			this.settings[offsetPositions.current.index].min = offsetPositions.current.newValue(this.sliderHeight, this.minMaxValues).min;
			this.settings[offsetPositions.current.index].max = offsetPositions.current.newValue(this.sliderHeight, this.minMaxValues).max;
			this.settings[offsetPositions.next.index].min = offsetPositions.next.newValue(this.sliderHeight, this.minMaxValues).min;
			this.settings[offsetPositions.next.index].max = offsetPositions.next.newValue(this.sliderHeight, this.minMaxValues).max;
			
			this.setPercentForEachColor();
		},
		setPercentForEachColor() {
			let sumFourNumber = 0;
			for (let i in this.settings) {
				let min = this.settings[i].min;
				let max = this.settings[i].max;
				// let min = this.settings[i].min || this.minMaxValues.min;
				// let max = this.settings[i].max || this.minMaxValues.max;
				let totalMax = isNaN(this.minMaxValues.max) ? Number(this.minMaxValues.max.replace(/,/g, "")) : this.minMaxValues.max;
				let colorPercent = Math.abs(+((max - min) / totalMax) * 100);
				
				this.settings[i].colorPercent = colorPercent;
				
				if (i < 4) sumFourNumber += colorPercent;
			}
			this.settings[4].colorPercent = 100 - sumFourNumber;
		},
	},
	watch: {
		colorList: {
			handler(newValue, oldValue) {
				this.setPercentForEachColor();
			},
			deep: true
		}
	}
};
</script>


<style lang="scss" scoped>
.slider-container {
	.slider {
		position: relative;
		
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			@apply rounded-full;
			box-shadow: #00000038 0px 0px 5px 6px inset;
		}
	}
}
</style>
