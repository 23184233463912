<template>
  <router-link
    class="card flex flex-col"
    :class="{'cursor-auto': !orgData?.is_clickable}"
    :to="orgData?.is_clickable? `/cisco/${org.org_id}` : '#'"
    @click="isClicked = true"
  >
    <div
      class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
      :class="orgData?.has_data && 'clickable'"
    >
      <div>
	      <span>Web Security Gateway</span>
        <BaseTooltip
	        class="relative ml-2 inline-block"
          :info="'Secure Internet Gateway description'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <div class="flex items-center">
	      <img src="@/assets/cisco-logo.png" class="h-7 mr-3 inline-block" />
        <div class="not-clickable-warning text-primary text-sm mr-3" v-if="!orgData?.is_clickable && isClicked">
          <font-awesome-icon
            class="text-primary mr-1"
            :icon="['fa', 'info-circle']"
          />
          <span>No click-through data</span>
        </div>
        <font-awesome-icon
          v-if="orgData?.has_data"
          class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
          :icon="['fa', 'arrow-right']"
        />
      </div>
    </div>
    <div
      class="w-full h-full flex items-center border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode)"
    >
      <div class="flex flex-col items-center px-3 py-4 w-full">
        <div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
          <font-awesome-icon
            class="text-xl mr-2"
            :class="
              orgDataAggregate.last_month_blocks > orgDataAggregate.last_of_last_month_blocks
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                orgDataAggregate.last_month_blocks > orgDataAggregate.last_of_last_month_blocks
                  ? 'up'
                  : 'down'
              }`,
            ]"
          />
          <h2 class="text-4xl">
            {{ formatBigNum(orgDataAggregate.last_month_blocks || 0) }}
          </h2>
        </div>
        <div class="flex items-center" v-else>
          <font-awesome-icon
            class="text-xl mr-2"
            :class="
              orgData?.last_month_blocks > orgData?.last_of_last_month_blocks
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="[
              'fa',
              `arrow-alt-circle-${
                orgData?.last_month_blocks > orgData?.last_of_last_month_blocks
                  ? 'up'
                  : 'down'
              }`,
            ]"
          />
          <h2 class="text-4xl">
            {{ formatBigNum(orgData?.last_month_blocks) }}
          </h2>
        </div>

        <p class="text-sm text-gray-400 text-center">
          requests blocked <br />
          last month
        </p>
      </div>
	    
      <div class="self-stretch border-l border-gray-700"></div>
	
      <div class="flex flex-col">
        <div class="p-3 border-b border-gray-700">
          <div
            class="flex flex-col items-center justify-center h-full px-6 w-full"
          >
            <div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
	            <font-awesome-icon
		            class="text-xl mr-2"
		            :class="{
									'text-red-500': orgDataAggregate.num_roaming_clients == 0,
									'text-green-500': orgDataAggregate.num_roaming_clients > 0
		            }"
		            :icon="['fa', 'user-shield']"
	            />
              <h4>{{ formatBigNum(orgDataAggregate.num_roaming_clients) }}</h4>
            </div>
	          <div class="flex items-center" v-else>
		          <font-awesome-icon
			          class="text-xl mr-2"
			          :class="{
									'text-red-500': orgData?.num_roaming_clients == 0,
									'text-green-500': orgData?.num_roaming_clients > 0
		            }"
			          :icon="['fa', 'user-shield']"
		          />
		          <h4>{{ formatBigNum(orgData?.num_roaming_clients) }}</h4>
	          </div>
          </div>
          <p class="text-xs text-gray-400 text-center">
            number of <br />
            roaming clients
          </p>
        </div>
        <div class="p-3">
          <div
            class="flex flex-col items-center justify-center h-full px-6 w-full"
          >
            <div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
	            <font-awesome-icon
		            v-if="(orgDataAggregate?.this_month_threats - orgDataAggregate?.last_month_threats) != 0"
		            class="mr-2"
		            :class="{
									'text-red-500': orgDataAggregate?.this_month_threats > orgDataAggregate?.last_month_threats,
									'text-green-500': orgDataAggregate?.this_month_threats < orgDataAggregate?.last_month_threats
			          }"
		            :icon="[
									'fa',
									orgDataAggregate?.this_month_threats > orgDataAggregate?.last_month_threats ?
										'arrow-alt-circle-up' : 'arrow-alt-circle-down'
								]"
	            />
	            <font-awesome-icon
		            v-if="orgDataAggregate?.this_month_threats == 0 && orgDataAggregate?.last_month_threats == 0"
		            class="mr-2 text-green-500"
		            :icon="['fa', 'shield-alt']"
	            />
              <h4>{{ orgDataAggregate.this_month_threats }}</h4>
            </div>
	          <div class="flex items-center" v-else>
		          <font-awesome-icon
			          v-if="(orgData?.this_month_threats - orgData?.last_month_threats) != 0"
			          class="mr-2"
			          :class="{
									'text-red-500': orgData?.this_month_threats > orgData?.last_month_threats,
									'text-green-500': orgData?.this_month_threats < orgData?.last_month_threats
			          }"
			          :icon="[
									'fa',
									orgData?.this_month_threats > orgData?.last_month_threats ?
										'arrow-alt-circle-up' : 'arrow-alt-circle-down'
								]"
		          />
		          <font-awesome-icon
			          v-if="orgData?.this_month_threats == 0 && orgData?.last_month_threats == 0"
			          class="mr-2 text-green-500"
			          :icon="['fa', 'shield-alt']"
		          />
		          <h4>{{ orgData?.this_month_threats || 0 }}</h4>
	          </div>
          </div>
          <p class="text-xs text-gray-400 text-center">
            number of <br /> threats
          </p>
        </div>
      </div>
	    
	    <div class="self-stretch border-l border-gray-700"></div>
	    
	    <div class="flex flex-col">
		    <div class="p-3 border-b border-gray-700">
			    <div
				    class="flex flex-col items-center justify-center h-full px-6 w-full"
			    >
				    <div class="flex items-center">
					    <font-awesome-icon
						    class="mr-2 text-yellow-500"
						    :icon="['fa', 'exclamation-triangle']"
					    />
					    <h4 v-if="isAggregateMode && org.org_type != 'VBU'">
						    {{ formatBigNum(orgDataAggregate.this_month_blocks || 0) }}
					    </h4>
					    <h4 v-else>
						    {{ formatBigNum(orgData?.this_month_blocks) }}
					    </h4>
				    </div>
			    </div>
			    <p class="text-xs text-gray-400 text-center">
				    request blocked <br />
				    this month
			    </p>
		    </div>
		    <div class="p-3">
			    <div
				    class="flex flex-col items-center justify-center h-full px-6 w-full"
			    >
				    <div class="flex items-center">
					    <font-awesome-icon
						    class="mr-2 text-indigo-400"
						    :icon="['fa', 'shield-virus']"
					    />
					    <h4 v-if="isAggregateMode && org.org_type != 'VBU'">
						    {{ percentageBlocked(orgDataAggregate) }}
					    </h4>
					    <h4 v-else>
						    {{ percentageBlocked(orgData) }}
					    </h4>
				    </div>
			    </div>
			    <p class="text-xs text-gray-400 text-center">
				    percent of requests blocked this month
			    </p>
		    </div>
	    </div>
    </div>
    <div class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg" v-else>
      <p class="py-8 opacity-50">No cisco account connected.</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';
import { formatBigNum } from '@/utils/utils';

export default {
  components: {
    BaseTooltip,
  },
  data() {
    return {
      isClicked: false
    }
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
	  orgData: {
		  type: Object,
		  required: true,
	  }
  },
	computed: {
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgDataAggregate() {
	    let VBUs = this.org.VBUs;
	    let widgetsData = this.$store.state.widgets.widgetsData;
	
	    if (VBUs && VBUs.length) {
		    let dataArr = [];
		    VBUs.forEach((VBUItem) => {
			    widgetsData?.forEach((orgItem) => {
				    if(VBUItem.org_id === +orgItem.org_id) {
					    dataArr.push({
						    this_month_blocks: orgItem.data.ciscoumbrella.this_month_blocks || 0,
						    last_month_blocks: orgItem.data.ciscoumbrella.last_month_blocks || 0,
						    last_of_last_month_blocks: orgItem.data.ciscoumbrella.last_of_last_month_blocks || 0,
						    this_month_requests: orgItem.data.ciscoumbrella.this_month_requests || 0,
						    num_roaming_clients: orgItem.data.ciscoumbrella.num_roaming_clients || 0,
						    this_month_threats: orgItem.data.ciscoumbrella.this_month_threats || 0,
						    last_month_threats: orgItem.data.ciscoumbrella.last_month_threats || 0
					    })
				    }
			    })
		    })
		
		    let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
			    return {
				    this_month_blocks: (sumObj.this_month_blocks || 0) + (currentObj.this_month_blocks || 0),
				    last_month_blocks: (sumObj.last_month_blocks || 0) + (currentObj.last_month_blocks || 0),
				    last_of_last_month_blocks: (sumObj.last_of_last_month_blocks || 0) + (currentObj.last_of_last_month_blocks || 0),
				    this_month_requests: (sumObj.this_month_requests || 0) + (currentObj.this_month_requests || 0),
				    num_roaming_clients: (sumObj.num_roaming_clients || 0) + (currentObj.num_roaming_clients || 0),
				    number_of_alarms: (sumObj.number_of_alarms || 0) + (currentObj.number_of_alarms || 0),
				    this_month_threats: (sumObj.this_month_threats || 0) + (currentObj.this_month_threats || 0),
				    last_month_threats: (sumObj.last_month_threats || 0) + (currentObj.last_month_threats || 0)
			    }
		    }, 0)
		
		    return {
			    this_month_blocks: sumVBUs.this_month_blocks ,
			    last_month_blocks: sumVBUs.last_month_blocks,
			    last_of_last_month_blocks: sumVBUs.last_of_last_month_blocks,
			    this_month_requests: sumVBUs.this_month_requests,
			    num_roaming_clients: sumVBUs.num_roaming_clients,
			    number_of_alarms: sumVBUs.number_of_alarms,
			    this_month_threats: sumVBUs.this_month_threats,
			    last_month_threats: sumVBUs.last_month_threats,
		    };
	    } else {
		    return {}
	    }
    }
  },
  methods: {
    formatBigNum,
    percentageBlocked(data) {
      if (!data?.this_month_requests) return '-';
      const blocked = (data?.this_month_blocks / data?.this_month_requests) * 100;

      if (blocked < 1) {
        return '<1%';
      } else {
        return `${Number.isInteger(blocked) ? blocked : blocked.toFixed(2)}%`;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>
