<template>
  <div>
    <label
      class="block mb-2 text-sm font-medium text-gray-300"
      :for="name"
      v-if="showLabel"
    >
      {{ label }}
    </label>

    <div
      class="focus-within:shadow-sm focus-within:border-yellow-500 w-full border border-gray-700 rounded-md flex justify-between relative transition ease-linear duration-200"
      :class="invalid && 'border-red-500'"
    >
      <select
        class="text-sm py-2 px-3 pr-8 appearance-none disabled:text-gray-300 w-full focus:outline-none rounded-md"
        :name="name"
        :id="name"
        :required="required"
        :disabled="disabled"
      >
        <option value="" :selected="!value" disabled>{{ placeholder }}</option>
        <option v-if="nullable" :value="null" :selected="false">None</option>
        <option
          v-for="(option, i) in options"
          :value="option"
          :key="i"
          :selected="value == option"
        >
          {{ option }}
        </option>
        <!-- Dropdown arrow -->
      </select>
      <div
        class="self-stretch border-l border-gray-700 px-1 text-gray-700 absolute right-0 top-0 h-full pointer-events-none rounded-r-md transition ease-linear duration-200"
      >
        <div
          class="w-6 h-full flex items-center justify-center"
          iconName="arrow down"
        >
          <font-awesome-icon :icon="['fa', 'chevron-down']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputBase',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Boolean],
    },
    placeholder: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholderSelected: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
select {
  @apply bg-transparent;

  cursor: pointer;

  &:invalid {
    @apply text-gray-400;
  }

  option {
    @apply text-gray-600;
  }

  &:disabled {
    @apply bg-transparent;
  }
}

[disabled] {
  @apply text-gray-400;
}

select:hover + div {
  @apply bg-bgLighter text-white;
}

select:active + div {
  @apply bg-gray-100;
}
</style>
