<template>
	<div class="flex-auto ml-5">
		
		<div class="table-top-header flex items-center justify-between">
			<h2 class="text-xl text-gray-300">Your Team</h2>
			<div class="search-and-excel flex items-center">
				<input
					class="outline-none text-black border-gray-700 rounded-lg px-4 py-2 mr-3"
					type="search"
					placeholder="Search ..."
					v-model="searchedKeyword"
					@input="searchEmail()"
				>
				<export-excel
					class="rounded-lg transition bg-green-600 hover:bg-green-700 transition cursor-pointer px-3 py-2"
					:data="excelInfo.dataList"
					:fields="excelInfo.titleList"
					type="csv"
					:name="`user-admin-table.csv`"
					:worksheet="`User Admin Table`"
					v-if="isExcelActive"
				>
					<font-awesome-icon
						:icon="['fa', 'file-excel']"
						class="mr-4"
					/>Export
				</export-excel>
			</div>
		</div>
		<div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
		<modal-base :show="openSwitchModal" @hide="closeSwitchModal">
			Are you sure you wish to remove this user?
			
			<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
				<button @click="removeUser" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Remove User</button>
				<button @click="closeSwitchModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">Cancel</button>
			</div>
		</modal-base>
		<table class="w-full relative rounded-lg overflow-hidden">
			<thead>
				<tr>
					<th
						scope="col"
						class="px-2 py-3 text-xs font-medium text-gray-200 uppercase bg-gray-700 z-20 sticky top-0 cursor-pointer hover:bg-gray-600 transition"
						:class="{'pl-5': (index === 0)}"
						@click="order(tableTitleItem.slug)"
						v-for="(tableTitleItem, index) in tableTitleList"
						:key="index"
					>
						<div class="flex items-center justify-between">
							<span style="user-select: none;">{{ tableTitleItem.title }}</span>
							<div class="flex flex-col ml-3">
								<font-awesome-icon
									class="text-white"
									:icon="['fa', 'chevron-up']"
									v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'ASC')"
								/>
								<font-awesome-icon
									class="text-white"
									:icon="['fa', 'chevron-down']"
									v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'DESC')"
								/>
							</div>
						</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-xs font-medium text-gray-200 uppercase bg-gray-700 z-20 sticky top-0"
					>
					</th>
				</tr>
			</thead>
			<tbody v-if="!isDataPrepared">
				<tr class="bg-gray-500">
					<td class="text-center p-3" colspan="5">
						<div class="flex justify-center items-center w-8 h-8 m-auto">
							<SpinnerBase />
						</div>
					</td>
				</tr>
			</tbody>
			<tbody v-else-if="userCopy.length">
				<user-admin-table-item
					v-for="item in userCopy"
					:user="item"
					:key="item.index"
					@openSwitchModal="clickRemoveUser(item)"
				/>
			</tbody>
			<tbody v-else>
				<tr class="bg-gray-500">
					<td class="text-center p-3" colspan="5">No result!</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import UserAdminTableItem from "@/components/UserAdminTableItem.vue";
import UserAdminEditUser from "@/components/UserAdminEditUser";
import ModalBase from "@/components/Base/ModalBase";
import SpinnerBase from "@/components/Base/SpinnerBase";

export default {
	components: {
		SpinnerBase,
		UserAdminTableItem,
		UserAdminEditUser,
		ModalBase,
	},
	apollo: {
		$subscribe: {
			user: {
				query: require("@/graphql/user_tenant_admin.gql"),
				result({data}) {
					let changedData = data.user_tenant_admin.map((item) => {
						return {
							username: item.username,
							status: item.status,
							is_tenant_admin: item.is_tenant_admin ? "Administrator" : "User"
						}
					})
					
					this.user = this.userCopy = changedData;
			
					this.setExcelData();
					this.isDataPrepared = true;
				},
			},
		},
	},
	data() {
		return {
			openSwitchModal: false,
			editId: null,
			user: [],
			userCopy: [],
			editingUser: {},
			isDataPrepared: false,
			searchedKeyword: '',
			tableTitleList: [
				{title: "Email", slug: "username"},
				{title: "Role", slug: "is_tenant_admin"},
				{title: "Status", slug: "status"}
			],
			orderBy: {
				column: '',
				type: ''
			},
			excelInfo: {
				titleList: {
					"Email": "username",
					"Role": "is_tenant_admin",
					"Status": "status"
				},
				dataList: []
			},
			isExcelActive: true
		};
	},
	methods: {
		clickRemoveUser(user) {
			console.log(user);
			this.openSwitchModal = true;
			return (this.editId = user.id);
		},
		async removeUser() {
			const id = this.editId
			await this.$apollo.mutate({
				mutation: require('@/graphql/remove_user.gql'),
				variables: {
					id
				}
			})
			this.closeSwitchModal()
		},
		closeSwitchModal() {
			this.openSwitchModal = false;
		},
		order(column) {
			if (this.orderBy.column === column) {
				this.orderBy.type = (this.orderBy.type === "DESC") ? "ASC" : "DESC";
			} else {
				this.orderBy.type = "ASC";
			}
			
			this.orderBy.column = column;
			
			this.userCopy.sort((a, b) => {
				let aItem = a[this.orderBy.column];
				let bItem = b[this.orderBy.column];
				
				if (this.orderBy.type === "ASC") {
					return (aItem > bItem) ? 1 : (aItem < bItem) ? -1 : 0;
				} else {
					return (aItem < bItem) ? 1 : (aItem > bItem) ? -1 : 0;
				}
			})
			
			this.user.sort((a, b) => {
				let aItem = a[this.orderBy.column];
				let bItem = b[this.orderBy.column];
				
				if (this.orderBy.type === "ASC") {
					return (aItem > bItem) ? 1 : (aItem < bItem) ? -1 : 0;
				} else {
					return (aItem < bItem) ? 1 : (aItem > bItem) ? -1 : 0;
				}
			})
			
			this.setExcelData();
		},
		setExcelData() {
			this.excelInfo.dataList = this.userCopy;
		},
		searchEmail() {
			let filteredItems = this.user.filter((item) => {
				return item.username.search(this.searchedKeyword) > -1;
			});
			
			this.userCopy = [...filteredItems];
			
			// set excel data after user search
			this.setExcelData();
		}
	},
};
</script>
