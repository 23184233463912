<template>
  <router-link
    class="card flex flex-col"
    :class="{ 'cursor-auto': !orgData?.is_clickable }"
    :to="orgData?.is_clickable ? `/knowbe4/${org.org_id}` : '#'"
    @click="isClicked = true"
  >
    <div
      class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
      :class="orgData?.has_data && 'clickable'"
    >
      <div class="flex items-center">
	      <span>Security Training/Awareness</span>
        <BaseTooltip
	        class="relative ml-2 inline-block"
          :info="'Cyber Security Training description'"
        >
          <font-awesome-icon
            class="text-gray-500"
            :icon="['fa', 'info-circle']"
          />
        </BaseTooltip>
      </div>
      <!-- this div below just holds the header size beacuse logo is smaller -->
      <div class="h-6"></div>
      <div class="flex items-center">
	      <img src="@/assets/knowbe4-logo.png" class="h-5 mr-3" />
        <div class="not-clickable-warning text-primary text-sm mr-3" 
        v-if="!orgData?.is_clickable && isClicked">
          <font-awesome-icon
            class="text-primary mr-1"
            :icon="['fa', 'info-circle']"
          />
          <span>No click-through data</span>
        </div>
        <font-awesome-icon
          v-if="orgData?.is_clickable"
          class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
          :icon="['fa', 'arrow-right']"
        />
      </div>
    </div>
    <div
      class="w-full h-full flex items-center border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode)"
    >
      <div class="flex flex-col items-center p-3 w-full">
        <div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
          <font-awesome-icon
            v-if="orgData.has_latest_course"
            class="text-xl mr-2"
            :class="
              orgDataAggregate.last_course_completion_percentage < 50
                ? 'text-red-500'
                : 'text-green-500'
            "
            :icon="['fa', 'certificate']"
          />
          <h2 class="text-4xl">
            {{
              orgDataAggregate.last_course_completion_percentage
                ? orgDataAggregate.last_course_completion_percentage + "%"
                : "-"
            }}
          </h2>
        </div>
	      <div class="flex items-center" v-else>
		      <font-awesome-icon
			      v-if="orgData?.has_latest_course"
			      class="text-xl mr-2"
			      :class="
              orgData?.last_course_completion_percentage < 50
                ? 'text-red-500'
                : 'text-green-500'
            "
			      :icon="['fa', 'certificate']"
		      />
		      <h2 class="text-4xl">
			      {{
				      orgData?.last_course_completion_percentage
					      ? orgData?.last_course_completion_percentage + "%"
					      : "0%"
			      }}
		      </h2>
	      </div>
        <p class="text-sm text-gray-400 text-center max-w-sm" v-if="!isAggregateMode">
          completion of most recent course:
          <span class="text-sm text-gray-400 italic">{{
            orgData?.last_course_campaign_name || "-"
          }}</span>
        </p>
	      <p class="text-sm text-gray-400 text-center max-w-sm" v-else>
		      average completion percentage for all of the recently deployed courses
	      </p>
      </div>
      <div class="self-stretch border-l border-gray-700"></div>
	    
      <div class="flex flex-col items-center justify-center h-full w-80">
        <div class="flex flex-col items-center border-b border-gray-700 w-full px-2 py-3">
          <div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
	          <font-awesome-icon
		          class="mr-2"
		          :class="
                (orgDataAggregate.average_phish_prone_percentage_this_month || 0) >
                (orgDataAggregate.average_phish_prone_percentage_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
		          :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgDataAggregate.average_phish_prone_percentage_this_month || 0) >
                  (orgDataAggregate.average_phish_prone_percentage_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
	          />
	          <h4>{{ orgDataAggregate.average_phish_prone_percentage_this_month }}%</h4>
          </div>
	        <div class="flex items-center" v-else>
		        <font-awesome-icon
			        class="mr-2"
			        :class="
                (orgData?.average_phish_prone_percentage_this_month || 0) >
                (orgData?.average_phish_prone_percentage_last_month || 0)
                  ? 'text-red-500'
                  : 'text-green-500'
              "
			        :icon="[
                'fa',
                `arrow-alt-circle-${
                  (orgData?.average_phish_prone_percentage_this_month || 0) >
                  (orgData?.average_phish_prone_percentage_last_month || 0)
                    ? 'up'
                    : 'down'
                }`,
              ]"
		        />
		        <h4>{{ orgData?.average_phish_prone_percentage_this_month }}%</h4>
	        </div>
	        <p class="text-xs text-gray-400 text-center">average phish prone<br>percentage</p>
        </div>
	      <div class="flex flex-col items-center w-full px-2 py-3">
		      <div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
			      <font-awesome-icon
				      class="text-blue-400 text-sm mr-2"
				      :icon="['fa', 'calendar-day']"
			      />
			      <h4>{{ orgDataAggregate.days_since_most_recent_course }}</h4>
		      </div>
		      <div class="flex items-center" v-else>
			      <font-awesome-icon
				      class="text-blue-400 text-sm mr-2"
				      :icon="['fa', 'calendar-day']"
			      />
			      <h4>{{ orgData?.days_since_most_recent_course }}</h4>
		      </div>
		      <p class="text-xs text-gray-400 text-center" v-if="!isAggregateMode">
			      number of days since <br/> most recent course
		      </p>
		      <p class="text-xs text-gray-400 text-center" v-else>
			      average days since all <br/> recently deployed courses
		      </p>
	      </div>
      </div>
	    
    </div>
    <div
      class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-else
    >
      <p class="py-8 opacity-50">No users found.</p>
    </div>
  </router-link>
</template>

<script>
import SpinnerBase from "@/components/Base/SpinnerBase";
import BaseTooltip from "@/components/Base/BaseTooltip";

export default {
  props: {
    org: {
      type: Object,
      required: true,
    },
	  orgData: {
		  type: Object,
		  required: true,
	  }
  },
  components: { SpinnerBase, BaseTooltip },
  data() {
    return {
      isClicked: false
    };
  },
  methods: {
    percentage(partial, total) {
      return Math.round(Number((partial / total) * 100));
    },
  },
  computed: {
	  isAggregateMode() {
		  return this.$store.state.structure.isAggregateMode;
	  },
	  orgDataAggregate() {
		  let VBUs = this.org.VBUs;
		  let widgetsData = this.$store.state.widgets.widgetsData;
	
		  if (VBUs && VBUs.length) {
			  let dataArr = [];
			  VBUs.forEach((VBUItem) => {
				  widgetsData?.forEach((orgItem) => {
					  if(VBUItem.org_id === +orgItem.org_id) {
						  dataArr.push({
							  last_course_completion_percentage: orgItem.data.knowbe.last_course_completion_percentage || 0,
							  average_phish_prone_percentage_this_month: orgItem.data.knowbe.average_phish_prone_percentage_this_month || 0,
							  average_phish_prone_percentage_last_month: orgItem.data.knowbe.average_phish_prone_percentage_last_month || 0,
							  days_since_most_recent_course: orgItem.data.knowbe.days_since_most_recent_course || 0,
						  })
					  }
				  })
			  })
			
			  let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
				  return {
					  last_course_completion_percentage: (sumObj.last_course_completion_percentage || 0) + (currentObj.last_course_completion_percentage || 0),
					  average_phish_prone_percentage_this_month: (sumObj.average_phish_prone_percentage_this_month || 0) + (currentObj.average_phish_prone_percentage_this_month || 0),
					  average_phish_prone_percentage_last_month: (sumObj.average_phish_prone_percentage_last_month || 0) + (currentObj.average_phish_prone_percentage_last_month || 0),
					  days_since_most_recent_course: (sumObj.days_since_most_recent_course || 0) + (currentObj.days_since_most_recent_course || 0),
				  }
			  }, 0)
			
			  return {
				  last_course_completion_percentage: Number.isInteger(sumVBUs.last_course_completion_percentage / dataArr.length) ?
					  (sumVBUs.last_course_completion_percentage / dataArr.length) : (sumVBUs.last_course_completion_percentage / dataArr.length).toFixed(2),
				  average_phish_prone_percentage_this_month: Number.isInteger(sumVBUs.average_phish_prone_percentage_this_month / dataArr.length) ?
					  (sumVBUs.average_phish_prone_percentage_this_month / dataArr.length) : (sumVBUs.average_phish_prone_percentage_this_month / dataArr.length).toFixed(2),
				  average_phish_prone_percentage_last_month: Number.isInteger(sumVBUs.average_phish_prone_percentage_last_month / dataArr.length) ?
					  (sumVBUs.average_phish_prone_percentage_last_month / dataArr.length) : (sumVBUs.average_phish_prone_percentage_last_month / dataArr.length).toFixed(2),
				  days_since_most_recent_course: Number.isInteger(sumVBUs.days_since_most_recent_course / dataArr.length) ?
					  (sumVBUs.days_since_most_recent_course / dataArr.length) : (sumVBUs.days_since_most_recent_course / dataArr.length).toFixed(2),
			  };
		  } else {
			  return {}
		  }
	  }
  }
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>
