<template>
  <div v-if="isDataPrepared">
    <BackButton />

    <div >
      <div class="flex justify-between">
        <div>
          <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">
           Late leavers from last 4 quarters
          </p>
        </div>
        <div class="text-center my-2">
          <img
            src="@/assets/workday_logo.png"
            class="h-8 inline-block"
          />
        </div>
      </div>

      <div class="relative py-4" >
        <div
          v-if="!chartData.labels.length"
          class="bg-green-900 flex items-center justify-center inset-0 blur-drop text-center rounded-md absolute z-10"
        >
          <p>No leavers found.</p>
        </div>
        <WorkdayChart :chartData="chartData" />
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerBase from '@/components/Base/SpinnerBase';
import WorkdayChart from '@/components/WorkdayChart';
import {} from '@/utils/time';
import BackButton from '@/components/Base/BackButton';


export default {
  components: {
    BackButton,
    SpinnerBase,
    WorkdayChart,
  },
  data() {
    return {
      currentQuarter: Math.floor((new Date().getMonth() + 3) / 3),
      currentYear: new Date().getFullYear(),
	    isDataPrepared: false
    };
  },
	async created() {
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "workday"
		});

		this.isDataPrepared = true;
	},
  computed: {
    quartersArr() {
      let year = this.currentYear;
      let quarter = this.currentQuarter;
      const newArr = [];
      newArr.push({ year, quarter });
      const howManyTimesToGoBack = 3;
      for (let index = 0; index < howManyTimesToGoBack; index++) {
        // as long as the quarter is greater than 1, we can simply -- (minus 1) the quarter
        if (quarter > 1) {
          quarter--;
        } else if (quarter === 1) {
          // if the quarter is equal to 1 (lowest it can be) then set the quarter to 4 and -- (minus 1) the year
          quarter = 4;
          year--;
        }
        newArr.push({ year, quarter });
      }
      return newArr
    },
    orgId() {
      return this.$route.params.id;
    },
    org() {
      return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
    },
    lateLeaversByOrg() {
	    return this.$store.state.systemsDetails.systemsDetailsData.data || null;
    },
    chartData() {
      const quartersArr = this.quartersArr

      const data = {
        labels: [],
        datasets: [
          {
            data: [],
            fill: true,
            label: 'Late Leavers',
            backgroundColor: 'rgba(239, 68, 68, 0.57)',
            //borderColor: 'rgb(185, 28, 28)'
          },
        ],
      };
			
      quartersArr.forEach((quarter) => {
        data.labels.push(
          `Q${quarter.quarter} ${quarter.year}`
        );
        const filteredData = this.lateLeaversByOrg.find((f) => {
					const dataYear = new Date(f.quarter_end).getFullYear();
					const dataQuarter = Math.floor((new Date(f.quarter_end).getMonth() + 3) / 3);

	        return dataYear === quarter.year && dataQuarter === quarter.quarter;
        })
        if (filteredData) {
          data.datasets[0].data.push(filteredData.late_leavers)
        } else {
          data.datasets[0].data.push(0)
        }
        
      });

      data.labels = data.labels.reverse()
      data.datasets[0].data = data.datasets[0].data.reverse()

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.blur-drop {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}
</style>
