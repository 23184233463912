import { apolloClient as apollo } from "@/apollo";
import widgets from "@/store/modules/widgets";

const defaultStoreState = () => ({
  data: [],
  hierarchy: [],
  selectedHierarchy: [],
  securitySummaryModalInfo: {
    index: null,
    org: {},
    systems: [],
  },
  isOrderByRankMode: false,
  isAggregateMode: false,
  isCyberHealthMode: false,
  isShowPortfolioOnly: false,
  isChildrenVisible: false,
  sidebarMenuMode: "collapse",
});

const state = defaultStoreState();

const getters = {
  GET_TYPE: (state) => (orgId) => {
    if (state.portfolios.find((el) => el.org_id == orgId)) {
      return "portfolio";
    } else if (state.groups.find((el) => el.org_id == orgId)) {
      return "group";
    } else {
      return "vbu";
    }
  },
  GET_ORGANISATION: (state) => (orgId) => {
    return state.data.find((el) => el.org_id == orgId) || null;
  },
  GET_SELECTED_HIERARCHY: (state) => {
    return state.selectedHierarchy;
  },
};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultStoreState());
  },
  SET_STRUCTURE: (state, { structure, commit }) => {
    state.data = structure.map((org) => {
      const widgetData = widgets.state.widgetsData.find(
        (item) => item.org_id === org.org_id
      );
      const updatedAt = widgetData?.updated_at;
      const hasData = widgetData?.data?.crowdstrike?.has_data;
      let total_assets;
      if (hasData === false) {
        total_assets = null;
      } else {
        total_assets = widgetData?.data?.crowdstrike?.total_crowdstrike_assets;
      }

      const updatedAtDate = updatedAt
        ? new Date(updatedAt).toISOString().split("T")[0]
        : "";
      const updatedAtTime = updatedAt
        ? new Date(updatedAt).toISOString().split("T")[1]?.slice(0, 5)
        : "";

      return {
        ...org,
        total_assets: total_assets,
        updated_at: `${updatedAtDate} ${updatedAtTime}`,
      };
    });

    commit("MAKE_HIERARCHY", { structure: state.data });
  },
  SELECT_HIERARCHY: (state, { hierarchy }) => {
    state.selectedHierarchy = hierarchy;
  },
  MAKE_HIERARCHY: (state, { structure }) => {
    const levelOne = structure.filter((el) => !el.reports_to);

    state.hierarchy = levelOne.map((el) => structureLevel(el));

    // helpers
    function findChildren(org_id) {
      return structure.filter((el) => el.reports_to === org_id);
    }

    function structureLevel(levelObj) {
      if (findChildren(levelObj.org_id).length == 0) return levelObj;

      const obj = {
        ...levelObj,
        children: findChildren(levelObj.org_id).map((el) => structureLevel(el)),
      };

      return obj;
    }
  },
  SHOW_ALL: (state) => {
    state.selectedHierarchy = state.hierarchy;
  },
  SET_SECURITY_SUMMARY_MODAL_INFO: (state, info) => {
    state.securitySummaryModalInfo.index = info.index;
    state.securitySummaryModalInfo.org = info.org;
    state.securitySummaryModalInfo["systems"][info.system] = info.color;
    state.securitySummaryModalInfo["systems"][info.system]["name"] =
      info.systemName;
  },
  SET_AGGREGATE_FOR_HIERARCHY: (state, allStates) => {
    addAggregate(state.hierarchy[0]);

    function addAggregate(obj) {
      if (obj.children) {
        // find VUBs children for Portfolio or Sub-Portfolio or Group
        let VBUsArray = [];
        getObjectVBUs(obj, VBUsArray);
        // calculate actual devices aggregate for Portfolio or Sub-Portfolio or Group
        let VBUsSum = VBUsArray.reduce(
          (s, o) => s + Number(o.total_assets ? o.total_assets : 0),
          0
        );
        let VBUsAverage = VBUsSum / VBUsArray.length;
        obj.total_assets_aggregate_sum = Number.isInteger(VBUsSum)
          ? VBUsSum
          : VBUsSum.toFixed(2);
        obj.total_assets_aggregate_average = Number.isInteger(VBUsAverage)
          ? VBUsAverage
          : VBUsAverage.toFixed(2);

        obj.VBUs = VBUsArray;

        // iterate hierarchy nested object
        obj.children.forEach((item) => {
          addAggregate(item);
        });
      }
    }

    function getObjectVBUs(obj, VBUsArray) {
      if (obj.children) {
        obj.children.forEach((item) => {
          getObjectVBUs(item, VBUsArray);
        });
      }

      // if(obj.type == 'VBU' && !obj.children) {
      if (obj.org_type == "VBU") {
        VBUsArray.push({
          org_id: +obj.org_id,
          total_assets: obj.total_assets,
        });
      }
    }
  },
  SET_TOGGLE_ORDER_BY_RANK_MODE(state) {
    state.isOrderByRankMode = !state.isOrderByRankMode;
  },
  SET_TOGGLE_AGGREGATE_MODE(state) {
    state.isAggregateMode = !state.isAggregateMode;
  },
  SET_CYBER_HEALTH_MODE(state) {
    state.isCyberHealthMode = !state.isCyberHealthMode;
  },
  SET_TOGGLE_SHOW_PORTFOLIO_ONLY(state) {
    state.isShowPortfolioOnly = !state.isShowPortfolioOnly;
  },
  SET_TOGGLE_CHILDREN_MODE(state) {
    state.isChildrenVisible = !state.isChildrenVisible;
  },
  SET_EXPAND_COLLAPSE_SIDEBAR_MENU(state, mode) {
    state.sidebarMenuMode =
      state.sidebarMenuMode == "collapse" ? "expand" : "collapse";
  },
};

const actions = {
  // change report statement s3_business_travel
  getStructure({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { dash_two_organisation },
        } = await apollo.query({
          query: require("@/graphql/get_structure.gql"),
        });

        // console.log("raw(first) structure", dash_two_organisation)

        commit("SET_STRUCTURE", { structure: dash_two_organisation, commit });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
