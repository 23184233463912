<template>
  <div>
    <div class="main-header flex px-8 border-b border-gray-700 bg-bgDarkest items-center relative pt-12">
      <router-link to="/" class="absolute left-8 top-1/2 transform -translate-y-1/2">
        <img v-if="this.$store.state.customise?.tenant_logo" class="h-8"
          :src="require('@/assets/' + this.$store.state.customise.tenant_logo)" />
      </router-link>

      <nav class="mx-auto self-end">
        <ul class="flex gap-x-8">

          <li><router-link :to="{ name: 'Dashboard' }">Dashboard</router-link></li>
          <li><router-link to="/heatmap">Heatmap</router-link></li>
          <li><router-link to="/admin">Admin</router-link></li>
        </ul>
      </nav>
      <div>
        <button class="sidebar-toggle-button self-end w-10 h-10 rounded-full text-sm mb-0.5 fixed top-6 right-8 z-40"
          :class="
            showSidebar
              ? 'bg-red-500 hover:bg-red-600'
              : 'bg-purple-500 hover:bg-purple-600'
          " @click="showSidebar = !showSidebar">
          <font-awesome-icon v-if="!showSidebar" :icon="['fa', 'filter']" />
          <font-awesome-icon v-else :icon="['fa', 'times']" />
        </button>
      </div>
    </div>


    <HierarchySidebar
      class="fixed h-screen top-0 right-0 overflow-x-hidden overflow-y-auto p-12 bg-bgLight border-l border-gray-700 transform transition-transform duration-500 ease-in-out z-20"
      :class="!showSidebar && 'translate-x-full'" @close="showSidebar = false" />
    <div class="p-8 w-full" v-if="!isLoading">
      <router-view />
    </div>
    <div class="w-10 h-10 mx-auto my-12" v-else>
      <SpinnerBase />
    </div>
    <button
      class="flex justify-center items-center py-3 px-3 ml-1 bg-blue-500 hover:bg-blue-700 rounded-full transition fixed top-18 right-8 z-40 text-white transform rotate-180"
      @click="scrollToTop" v-show="showScrollButton" ref="scrollTopButton"> <font-awesome-icon class="text-white"
        :icon="['fa', 'chevron-down']" />
    </button>
  </div>
</template>

<script>
import HierarchySidebar from '@/components/HierarchySidebar';
import SpinnerBase from '@/components/Base/SpinnerBase';
export default {
  name: 'Home',
  components: {
    HierarchySidebar,
    SpinnerBase,
  },
  data() {
    return {
      showSidebar: false,
      isLoading: true,
      showScrollButton: false,
    };
  },
  methods: {
    getImage(img) {
      return require(img);
    },
    closeSidebar() {
      this.showSidebar = false
    },
    changeView(view) {
      if (view == 'Dashboard' && this.$route.path !== '/') {
        this.$router.push('/');
      } else if (view == 'Heat Map' && this.$route.path !== '/crowdstrike') {
        this.$router.push('/heatmap');
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleScroll() {
      if (scrollY > 700) {
        this.showScrollButton = true
      } else {
        this.showScrollButton = false
      }
    },
  },

  created () {
  window.addEventListener('scroll', this.handleScroll);
  },
  
  async mounted() {
    if (!this.$store.state.widgets.widgetsData.length) {
      await this.$store.dispatch('widgets/getWidgetsData');
    }

    if (!this.$store.state.structure?.data.length) {
      await this.$store.dispatch('structure/getStructure');
    }

    if (!this.$store.state.dashboardSettings?.data.length) {
      await this.$store.dispatch('dashboardSettings/getDashboardSettings');
    }
    if (!this.$store.state.customise?.tenant_logo) {
      await this.$store.dispatch('customise/getLogoPath').then(() => {
        const favicon = document.querySelector('link[rel="icon"]');
        // Set the favicon path with a dynamic value
        favicon.href = `/${this.$store.state.customise?.tenant_logo}`;
      }
      );
    }

    const promises = [];

    await Promise.all(promises);

    await this.$store.commit('structure/SET_AGGREGATE_FOR_HIERARCHY', this.$store.state);

    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

html {
  scroll-behavior: smooth;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fadeQuick-enter-active,
.fadeQuick-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.fadeQuick-enter-from,
.fadeQuick-leave-to {
  opacity: 0;
}

nav {
  li {
    a {
      @apply block text-gray-500 pb-4 pt-2 px-3 border-b-2 border-transparent transition-colors;

      &.router-link-active {
        @apply border-indigo-500 text-gray-300;
      }

      &:hover:not(.router-link-active) {
        @apply border-gray-500 text-gray-400;
      }
    }
  }
}
</style>
