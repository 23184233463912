<template>
	<div class="w-full">
		
		<!-- Systems Header -->
		<HeatmapHeader
			:infoLeft="'Operational:\n\nSecurity Scorecard Overall Rating:\n\nThe highest security rating is an “A,” indicating a low number of vulnerabilities, threat indicators, and issues; the ratings descend as the severity and number of threat indicators increases.\n\nCompanies with an F rating are 7.7 times more likely to be victims of data breaches than those with an A rating.'"
			:infoRight="'Deployment:\n\nSecurity Scorecard:\n\nGreen and a status of \'active\' indicates that we are receiving Security Scorecard data linked to this organisation.\n\nIf grey, Security Scorecard is not active and you should contact your IT department if you are responsible for this organisation'"
		>
			<div class="flex justify-center items-center h-full w-full px-1 py-1">
				<span class="text-sm text-center">3rd Party Risk Management</span>
			</div>
		</HeatmapHeader>
		<!-- End Systems Header -->
		
		<!-- Operational and Deployment Columns -->
		<div class="grid grid-cols-2 gap-1">
			
			<!-- Operational Column -->
			<div class="w-full">
				<div
					v-for="(org, i) in flatStructure"
					:key="i"
					class="heatmap-item"
					:class="{
						'border border-gray-200': getColorBaseOnAggregateMode(org) ? (i == hoveredRow && getColorBaseOnAggregateMode(org)?.operational.value != 'N/A') : null,
						'not-applicable': getColorBaseOnAggregateMode(org) ? getColorBaseOnAggregateMode(org)?.operational.value == 'N/A' : null
          }"
					:style="`background-color: rgba(${getColorBaseOnAggregateMode(org)?.operational.color[colorMode]}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
					@mouseover="$emit('onRowHover', i)"
					@mouseleave="$emit('onRowHover', null)"
					@click="heatmapItemClicked(i, getColorBaseOnAggregateMode(org), org, 'operational')"
					v-show="!isOrderByRankMode || (isOrderByRankMode && !isAllRowBlack(org))"
				>
					<p v-show="(showValue || i == hoveredRow) && getColorBaseOnAggregateMode(org)?.operational.value != 'N/A'">
						<span v-if="getColorBaseOnAggregateMode(org) && getColorBaseOnAggregateMode(org)?.operational.value !== 'notValid'">
							{{getColorBaseOnAggregateMode(org)?.operational.value}}
						</span>
						<span v-else>&#10071;</span>
					</p>
				</div>
			</div>
			<!-- End Operational Column -->
			
			<!-- Deployment Column -->
			<div class="w-full">
				<div
					v-for="(org, i) in flatStructure"
					:key="i"
					class="heatmap-item"
					:class="{
						'border border-gray-200': (i == hoveredRow && getColorBaseOnAggregateMode(org)?.deployment.value != 'N/A'),
						'not-applicable': getColorBaseOnAggregateMode(org)?.deployment.value == 'N/A'
          }"
					:style="`background-color: rgba(${getColorBaseOnAggregateMode(org)?.deployment.color[colorMode]}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
					@mouseover="$emit('onRowHover', i)"
					@mouseleave="$emit('onRowHover', null)"
					@click="heatmapItemClicked(i, getColorBaseOnAggregateMode(org), org, 'deployment')"
					v-show="!isOrderByRankMode || (isOrderByRankMode && !isAllRowBlack(org))"
				>
					<p v-show="(showValue || i == hoveredRow) && getColorBaseOnAggregateMode(org)?.deployment.value != 'N/A'">
							<span v-if="getColorBaseOnAggregateMode(org)?.deployment.value !== 'notValid'">
								{{getColorBaseOnAggregateMode(org)?.deployment.value}}
							</span>
						<span v-else>&#10071;</span>
					</p>
				</div>
			</div>
			<!-- End Deployment Column -->
			
		</div>
		<!-- End Operational and Deployment Columns -->
		
	</div>
</template>

<script>
import HeatmapHeader from '@/components/HeatmapHeader';

export default {
	components: {
		HeatmapHeader,
	},
	props: {
		flatStructure: {
			type: Array,
			required: true,
		},
		hoveredRow: {
			type: [Number, null],
			default: null,
		},
		clickedRow: {
			type: [Number, null],
			default: null,
		},
		showValue: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
    isOrderByRankMode() {
      return this.$store.state.structure.isOrderByRankMode;
    },
		colorMode() {
			return this.$store.state.ui.colorblindMode ? 'blind' : 'normal';
		},
		heatmapColorsValues() {
			return this.$store.state.heatmap.heatmapColorsValues;
		},
		isAggregateMode() {
			return this.$store.state.structure.isAggregateMode;
		},
		heatmapAggregate() {
			return this.$store.state.heatmapAggregate.heatmapAggregate;
		}
	},
	methods: {
		heatmapItemClicked(i, color, org, columnType) {
			this.$emit('onRowClick', i)
			
			this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
				system: 'securityScoreCard',
				systemName: 'SecurityScoreCard',
				index: i,
				color: color,
				org: org
			})
			
			if (color[columnType].value != 'N/A') {
				this.$vfm.show('security-summary-modal');
			}
		},
		getHeatmapColorNormalMode(orgId) {
			if (!this.heatmapColorsValues[orgId]) return null
			return this.heatmapColorsValues[orgId].securityscorecard;
		},
		getHeatmapAggregateMode(orgId) {
			return this.heatmapAggregate[orgId]?.securityscorecard;
		},
		getColorBaseOnAggregateMode(org) {
			// if (this.isAggregateMode && org.org_type != "VBU")
			// 	return this.getHeatmapAggregateMode(org.org_id);
			// else
				return this.getHeatmapColorNormalMode(org.org_id);
		},
    isAllRowBlack(org) {
      if (['Portfolio', 'Sub-Portfolio', 'Group'].includes(org.org_type)) {
        if (!['Volaris Consolidated', 'Lumine Group'].includes(org.name)) {
          return true;
        }
      }

      return false;
    }
	},
	watch: {
		clickedRow(clickedRowIndex) {
			this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
				system: 'securityScoreCard',
				systemName: 'SecurityScoreCard',
				index: clickedRowIndex,
				color: this.getColorBaseOnAggregateMode(this.flatStructure[clickedRowIndex]),
				org: this.flatStructure[clickedRowIndex]
			})
		}
	}
};
</script>

<style lang="scss" scoped></style>
