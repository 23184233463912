<template>
  <button class="
              flex
              justify-center
              items-center
              h-10
              w-10
              ml-2 
              bg-gray-700
              hover:bg-gray-600
              rounded-full
              transition
            " type="submit" @click.prevent="logout">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 32">
      <title>Log Out</title>
      <g fill="#fff">
        <path d="M30,0H14V2H29V30H14v2H30a.945.945,0,0,0,1-1V1A.945.945,0,0,0,30,0Z" fill="#fff"></path>
        <path d="M14,22a1,1,0,0,0,1.6.8l8-6a1,1,0,0,0,0-1.6l-8-6A1,1,0,0,0,14,10v5H2a1,1,0,0,0,0,2H14Z"></path>
      </g>
    </svg>
  </button>
</template>

<script>
import { apolloClient as apollo, closeWebsocket } from "@/apollo";
import axios from "axios";

export default {
  name: "ExpandCollapseButton",

  computed: {
    sidebarMenuMode() {
      return this.$store.state.structure.sidebarMenuMode;
    },
  },
  methods: {
    logout() {
      axios
        .post(
          `https://id-ciso.redsmart.app/logout`,
          {},
          {
            withCredentials: true,
          }
        )
        .then(() => {
          this.$store.dispatch('resetStore');
          apollo.cache.data.clear();
          closeWebsocket();
          sessionStorage.setItem("lastNav", "/");
          this.$router.push("/login");
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>


