<template>
  <div v-if="isDataPrepared">
    <BackButton />

    <div>
      <div class="flex justify-between">
        <div>
          <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">
	          Number of requests blocked over the preceding 6 months and any current threats detected
          </p>
        </div>
        <div class="text-center my-2">
          <img src="@/assets/cisco-logo.png" class="h-20 inline-block" />
        </div>
      </div>

      <div class="relative py-4">
        <div
          v-if="!chartData.labels.length"
          class="bg-green-900 flex items-center justify-center inset-0 blur-drop text-center rounded-md absolute z-10"
        >
          <p>No detections found.</p>
        </div>
        <CiscoChart :chartData="chartData" />
	      <CiscoThreatsTable :threats="currentThreatsTableData" />
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerBase from '@/components/Base/SpinnerBase';
import CiscoChart from '@/components/CiscoChart';
import BackButton from '@/components/Base/BackButton';
import CiscoThreatsTable from "@/components/CiscoThreatsTable";
// import {} from '@/utils/time';

export default {
	data() {
		return {
			isDataPrepared: false
		}
	},
  components: {
	  CiscoThreatsTable,
    BackButton,
    SpinnerBase,
    CiscoChart,
  },
	async mounted() {
		// get data and populate
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "ciscoumbrella"
		});
		
		this.isDataPrepared = true;
	},
  computed: {
	  orgId() {
		  return this.$route.params.id;
	  },
	  org() {
		  return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
	  },
    months() {
      var today = new Date();
      var d;
      var month;
      var newArr = [];
      for (var i = 5; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = d.toLocaleString('en-GB', { month: 'long' }).toUpperCase();
        newArr.push(month);
      }
      newArr.push(
        new Date().toLocaleString('en-GB', { month: 'long' }).toUpperCase()
      );
      return newArr;
    },
    chartData() {
      const blockedClicks = this.$store.state.systemsDetails.systemsDetailsData.data[0].chart_data;
	 
	    const months = this.months;
      if (!blockedClicks || !this.months) return;
      const data = {
        labels: months,
        datasets: [
          {
	          label: 'Requests Blocked',
            data: months.map((d) => 0),
            backgroundColor: 'rgba(139, 92, 246, 0.7)',
          },
	        {
		        label: 'Threats',
            data: months.map((d) => 0),
		        backgroundColor: 'rgba(239, 68, 68, 0.57)',
          }
        ],
      };

      if (blockedClicks && blockedClicks.length) {
        blockedClicks.forEach((c) => {
          const dateMonth = new Date(c.month)
            .toLocaleString('en-GB', { month: 'long' })
            .toUpperCase();
	        
          data.datasets[0].data[months.indexOf(dateMonth)] = c.requests_blocked;
          data.datasets[1].data[months.indexOf(dateMonth)] = c.threats;
        });
      }

      return data;
    },
	  currentThreatsTableData() {
			return this.$store.state.systemsDetails.systemsDetailsData.data[0]?.current_threats || [];
	  }
  },
};
</script>

<style lang="scss" scoped>
.blur-drop {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}
</style>
