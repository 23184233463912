<template>
  <div>
    <bar-chart :chartData="chartData" :options="options" />
  </div>
</template>

<script>
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  props: {
    chartData: {
      type: Object,
    }
  },
  components: {
    BarChart,
  },
  setup(props) {
	  const options = {
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 9,
            boxHeight: 9,
            usePointStyle: true,
            color: 'rgba(255,255,255, 0.8)',
            padding: 15,
          },
          title: {
            display: true,
            color: 'rgba(255,255,255, 0.8)',
            text: "Attack Type - click on different types to toggle on/off on the bar chart",
            padding: 5,
            font: {
              size: 14,
            },
          },
        },
      },
      scales: {
        y: {
          stacked: true,
          grid: {
            color: 'rgba(255, 255, 255, .1)',
          },
          ticks: {
            color: 'rgba(255, 255, 255, .65)',
            precision: 0,
            padding: 8,
          },
          title: {
            display: true,
            color: 'rgba(255, 255, 255, .8)',
            text: "Number of Allowed and Blocked Clicks",
            padding: 8,
            font: {
              size: 13,
            },
          },
        },
        x: {
          stacked: true,
          grid: {
            color: 'rgba(255, 255, 255, .1)',
          },
          ticks: {
            color: 'rgba(255, 255, 255, .65)',
            padding: 10,
          },
        },
      },
      // legend: { display: true },
    };

    // return { options, chartData };
    return { options };
  },
};
</script>

<style lang="scss" scoped></style>
