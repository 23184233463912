<template>
  <button
    @click="$router.back();"
    class="rounded-lg transition bg-blue-600 hover:bg-blue-700 px-3 py-2 mb-6"
  >
    <font-awesome-icon
      :icon="['fa', 'arrow-left']"
      class="mr-4"
    />
    Back
  </button>
</template>

<script>
export default {
  name: 'BackButton'
}
</script>

