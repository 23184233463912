<template>
  <tr class="bg-gray-800 hover:bg-gray-600 transition duration-300">
    <th
      scope="col"
      class="px-2 pl-5 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
    >
      <div class="flex items-center">
        {{ user.username }}
      </div>
    </th>
    <th
      scope="col"
      class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
    >
      <div class="flex items-center">
        <div v-if="parseJwt['x-hasura-user-id'] === user.id"></div>
        <div v-else-if="parseJwt && parseJwt['x-hasura-default-role'] === 'tenant_admin'">
          <select
            v-model="userType"
            class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-gray-700"
          >
            <option>User</option>
            <option>Administrator</option>
          </select>
        </div>
        <div v-else>
        {{ user.is_tenant_admin }}
        </div>
      </div>
    </th>
    <th
      scope="col"
      class="px-2 py-3 text-center text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
    >
      <div class="flex items-center">
        <span
          v-if="user.status === 'active'"
          class="bg-green-500 w-30 h-5 text-white px-3 rounded-full flex justify-center items-center py-1"
          >Active</span
        >
        <span
          v-else-if="user.status === 'invited'"
          class="bg-blue-500 w-30 h-5 text-white px-3 rounded-full flex justify-center items-center py-1"
          >Invite Sent</span
        >
      </div>
    </th>
    <th
      scope="col"
      class="px-2 py-3 font-medium text-gray-300 uppercase z-20 sticky top-0"
    >
      <div class="flex items-center justify-center">
        <button
          v-if="!user.is_tenant_admin"
          @click="$emit('openSwitchModal')"
          type="button"
          class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mr-3"
        >
          Remove
        </button>
        <button
          v-if="user.status === 'invited' && !inviteResent"
          @click="resendInvite(user.username)"
          type="button"
          class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Re-Send Invite
        </button>
        <p v-if="inviteResent" class="text-sm text-gray-100">Invite resent</p>
      </div>
    </th>
  </tr>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex"
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inviteResent: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['parseJwt']),
    userType: {
      get() {
	      return this.user.is_tenant_admin;
      },
      async set(val) {
        console.log(val)
        const is_tenant_admin = val === 'Administrator' ? true : val === 'User' ? false : undefined
        if (is_tenant_admin === undefined) return
        const tryMutate = await this.$apollo.mutate({
          mutation: require('@/graphql/update_user_role.gql'),
          variables: {
            username: this.user.username,
            is_tenant_admin
          }
        })
        console.log(tryMutate)
      }
    }
  },
  methods: {
    async resendInvite(email) {
      this.isLoading = true;

      try {
        await axios({
          method: "POST",
          url: "https://id-ciso.redsmart.app/invite_resend",
          data: {
            email: email,
          },
        });

        this.inviteResent = true;

        return (this.isLoading = false);
      } catch (e) {
        console.log(e);

        return (this.isLoading = false);
      }
    },
  }
}
</script>
