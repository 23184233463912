<template>
	<div class="relative py-4" v-if="threats.length">
		<div class="flex justify-between items-center">
			<h2 class="text-xl text-gray-300">Currently detected threats</h2>
			<export-excel
				class="rounded-lg transition bg-green-600 hover:bg-green-700 transition cursor-pointer px-3 py-2"
				:data="excelInfo.dataList"
				:fields="excelInfo.titleList"
				type="csv"
				:name="`${orgName}-threats.csv`"
				worksheet="Crowdstrike threats"
				v-if="isExcelActive"
			>
				<font-awesome-icon
					:icon="['fa', 'file-excel']"
					class="mr-4"
				/>
				Export
			</export-excel>
		</div>
		<div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
		<table class="w-2/3 relative rounded-lg overflow-hidden m-auto">
				<thead>
					<tr>
						<th
							scope="col"
							class="px-2 py-3 text-center text-xs font-medium text-gray-200 uppercase bg-gray-700 z-20 sticky top-0 cursor-pointer hover:bg-gray-600 transition"
							:class="{'pl-5': (index === 0)}"
							@click="order(tableTitleItem.slug)"
							v-for="(tableTitleItem, index) in tableTitleList"
							:key="index"
						>
							<div class="flex items-center justify-between">
								<span style="user-select: none;">{{ tableTitleItem.title }}</span>
								<div class="flex flex-col">
									<font-awesome-icon
										class="text-white"
										:icon="['fa', 'chevron-up']"
										v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'ASC')"
									/>
									<font-awesome-icon
										class="text-white"
										:icon="['fa', 'chevron-down']"
										v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'DESC')"
									/>
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, index) in threatsCopy"
						:key="index"
						class="bg-gray-800 hover:bg-gray-600 transition duration-300"
					>
						<th
							scope="col"
							class="px-2 pl-5 py-3 text-center text-md font-medium text-gray-300 z-20 sticky top-0"
						>
							<div class="flex items-center">
								<font-awesome-icon
									class="text-white mr-2"
									:icon="threatIcon(item.threattype)"
								/>
								{{ item.threattype }}
							</div>
						</th>
						<th
							scope="col"
							class="px-2 py-3 text-xs font-medium text-gray-300 z-20 sticky top-0"
						>
							<div class="flex items-center">{{ item.threat }}</div>
						</th>
						<th
							scope="col"
							class="px-2 py-3 text-xs font-medium text-gray-300 z-20 sticky top-0"
						>
							<div class="flex items-center">{{ item.count }}</div>
						</th>
					</tr>
				</tbody>
			</table>
	</div>
	<div v-else>
		<h2 class="text-xl text-gray-300">No threats</h2>
	</div>
</template>

<script>

export default {
  props: {
    threats: {
      type: Object,
    },
	  orgName: {
			type: String,
		  default: ''
	  }
  },
	data() {
		return {
			orderBy: {
				column: '',
				type: ''
			},
			threatsCopy: [],
			tableTitleList: [
				{title: "Threat Type", slug: "threattype"},
				{title: "Threat Name", slug: "threat"},
				{title: "Count", slug: "count"}
			],
			excelInfo: {
				titleList: {
					"Threat Type": "threattype",
					"Threat Name": "threat",
					"Count": "count"
				},
				dataList: []
			},
			isExcelActive: false,
			threatTypeList: [
				{type: 'Adware', icon: 'fas fa-exclamation-triangle'},
				{type: 'APT', icon: 'fas fa-sync-alt'},
				{type: 'Backdoor', icon: 'fas fa-door-open'},
				{type: 'Botnet', icon: 'fas fa-robot'},
				{type: 'Browser Hijacker', icon: 'fas fa-globe'},
				{type: 'Bulletproof Hosting', icon: 'fas fa-mask'},
				{type: 'Cryptojacking', icon: 'fab fa-bitcoin'},
				{type: 'Drive-by Download', icon: 'fas fa-car-crash'},
				{type: 'Dropper', icon: 'fas fa-eye-dropper'},
				{type: 'Exploit Kit', icon: 'fas fa-search'},
				{type: 'Fast Flux Botnet', icon: 'fas fa-people-arrows'},
				{type: 'Information Stealer', icon: 'fas fa-user-secret'},
				{type: 'Loader', icon: 'fas fa-spinner'},
				{type: 'Malvertising', icon: 'fas fa-theater-masks'},
				{type: 'Mobile Trojan', icon: 'fas fa-mobile-alt'},
				{type: 'Point-of-sale Malware', icon: 'fas fa-cash-register'},
				{type: 'Ransomware', icon: 'fas fa-skull-crossbones'},
				{type: 'RAT', icon: 'fas fa-laptop-house'},
				{type: 'Rootkit', icon: 'fas fa-bug'},
				{type: 'Scareware', icon: 'fas fa-exclamation-circle'},
				{type: 'Sinkhole', icon: 'fas fa-sink'},
				{type: 'Spam', icon: 'fas fa-envelope'},
				{type: 'Spyware', icon: 'fas fa-user-secret'},
				{type: 'Trojan', icon: 'fas fa-horse-head'},
				{type: 'Worm', icon: 'fas fa-radiation'}
			]
		}
	},
	created() {
		this.threatsCopy = [...this.threats];
		this.setExcelData();
	},
	methods: {
		order(column) {
			if (this.orderBy.column === column) {
				this.orderBy.type = (this.orderBy.type === "DESC") ? "ASC" : "DESC";
			} else {
				this.orderBy.type = "ASC";
			}
			
			this.orderBy.column = column;
			
			this.threatsCopy.sort((a, b) => {
				let aItem = a[this.orderBy.column];
				let bItem = b[this.orderBy.column];
				
				if (this.orderBy.type === "ASC") {
					return (aItem > bItem) ? 1 : (aItem < bItem) ? -1 : 0;
				} else {
					return (aItem < bItem) ? 1 : (aItem > bItem) ? -1 : 0;
				}
			})
			
			this.setExcelData();
		},
		setExcelData() {
			this.excelInfo.dataList = this.threatsCopy;
		},
		threatIcon(threatType) {
			let icon = this.threatTypeList.find((threat) => threat.type === threatType)?.icon.split(' ');

			return [icon[0], icon[1].substr(3)];
		}
	}
};
</script>
<style lang="scss" scoped></style>
