export const coloursScale = () => {
  return [
    { normal: "117, 226, 70", blind: "182, 219, 255" },
    { normal: "228, 248, 73", blind: "109, 182, 255" },
    { normal: "248, 158, 73", blind: "182, 109, 255" },
    { normal: "248, 108, 73", blind: "0, 109, 219" },
    { normal: "248, 73, 73", blind: "73, 0, 146" },
  ];
};

export const getColor = (
  minMaxValues,
  baseValue,
  isTrueFalseBaseValue = false,
  isAlwaysRed = false
) => {
  let colors = [...coloursScale()];

  // if the color is always red
  if (isAlwaysRed) {
    return colors[4];
  }

  // if we don't have minimum and maximum value (only true and false value)
  if (isTrueFalseBaseValue) {
    if (baseValue) {
      return colors[0];
    } else {
      return colors[4];
    }
  }
  if (baseValue === null) {
    return colors[4];
  }

  let minMaxValuesChanged = {};
  for (let colorIndex in minMaxValues) {
    minMaxValuesChanged[colorIndex] = {
      min:
        minMaxValues[colorIndex].min == null
          ? -Infinity
          : minMaxValues[colorIndex].min,
      max:
        minMaxValues[colorIndex].max == null
          ? Infinity
          : minMaxValues[colorIndex].max,
    };
  }

  let color;
  switch (true) {
    case baseValue >= minMaxValuesChanged.green.min &&
      baseValue < minMaxValuesChanged.green.max:
      color = colors[0];
      break;
    case baseValue >= minMaxValuesChanged.yellow.min &&
      baseValue < minMaxValuesChanged.yellow.max:
      color = colors[1];
      break;
    case baseValue >= minMaxValuesChanged.orange.min &&
      baseValue < minMaxValuesChanged.orange.max:
      color = colors[2];
      break;
    case baseValue >= minMaxValuesChanged.darkorange.min &&
      baseValue < minMaxValuesChanged.darkorange.max:
      color = colors[3];
      break;
    case baseValue >= minMaxValuesChanged.red.min &&
      baseValue < minMaxValuesChanged.red.max:
      color = colors[4];
      break;
  }

  return color;
};

export const getSpycloudDeploymentColor = (status) => {
  let colors = coloursScale();

  switch (status) {
    case "active":
      return colors[0];
    case "active*":
      return colors[1];
    case "inactive":
      return colors[4];
    case "common":
      return colors[2];
  }
};

export const getSixClicksDeploymentColor = (status) => {
  let colors = coloursScale();

  switch (status) {
    case "complete":
      return colors[0];
    case "complete*":
      return colors[1];
    case "in prog":
    case "in prog*":
      return colors[2];
    case "tbc":
    case "tbc*":
      return colors[3];
    case "late":
    case "none":
    case "draft":
      return colors[4];
  }
};

export const getScorecardOperationalColor = (overall_grade) => {
  let colors = coloursScale();

  switch (overall_grade) {
    case "A":
      return colors[0];
    case "B":
      return colors[1];
    case "C":
      return colors[2];
    case "D":
      return colors[3];
    case "F":
      return colors[4];
  }
};
