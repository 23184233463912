<template>
<!--	<div class="heatmap-view" v-if="isHeatmapDataPrepared && (!isAggregateMode || (isAggregateMode && isAggregateModeDataPrepared))">-->
	<div class="heatmap-view" v-if="isHeatmapDataPrepared">

		<!-- Heatmap Header and Switch Buttons -->
		<div class="flex justify-between items-center">
			<h2 class="text-2xl text-gray-200 mb-4">Heatmap</h2>
			<div class="flex flex-col items-end">
				
				<!-- Toggle Cyber Health -->
				<div class="flex mb-2">
					<p class="text-sm mr-2 text-gray-300">Show Cyber Health:</p>
					<BaseToggle
						@click.self.stop
						:name="'view_cyber_health'"
						@change="toggleCyberHealthMode()"
						:value="isCyberHealthMode"
					/>
				</div>
				<!-- End Toggle Cyber Health -->
				
				<!-- Show Portfolio Only -->
				<div class="flex mb-2" v-if="isCyberHealthMode">
					<p class="text-sm mr-2 text-gray-300">Show portfolio only:</p>
					<BaseToggle
						@click.self.stop
						:name="'show_portfolio_only'"
						@change="toggleShowPortfolioOnly()"
						:value="isShowPortfolioOnly"
					/>
				</div>
				<!-- End Show Portfolio Only -->
				
				<!-- Show Values -->
				<div class="flex mb-2">
					<p class="text-sm mr-2 text-gray-300">Show values:</p>
					<BaseToggle
						@click.self.stop
						@change="valuesHandler"
						:name="'view_value'"
						:value="showValue"
					/>
				</div>
				<!-- End Show Values -->
				
				<!-- Colourblind Mode -->
				<div class="flex mb-2">
					<p class="text-sm mr-2 text-gray-300">Colourblind mode:</p>
					<BaseToggle
						@click.self.stop
						@change="(val) => changeColorBlind(val.target.checked)"
						:name="'colorblind'"
						:value="isColorBlindMode"
					/>
				</div>
				<!-- End Colourblind Mode -->
				
				<!-- Order by Rank -->
				<div class="flex">
					<p class="text-sm mr-2 text-gray-300">Order by rank:</p>
					<BaseToggle
						@click.self.stop
						@change="(val) => changeOrderByRankMode(val.target.checked)"
						:name="'order_by_rank'"
						:value="isOrderByRankMode"
					/>
				</div>
				<!-- End Order by Rank -->
			
			</div>
		</div>
		<!-- End Heatmap Header and Switch Buttons -->
		
		<!-- Range -->
		<div class="my-4">
			<div class="flex justify-between mb-1">
				<p class="text-sm text-gray-300 text-center">Bad</p>
				<p class="text-sm text-gray-300 text-center">Good</p>
			</div>
			<div class="flex rounded-sm overflow-hidden">
				<div
					v-for="(colour, i) in reversedColoursScale"
					:key="i"
					class="w-full h-2"
					:style="`background-color: rgba(${colour[colorMode]}, 0.9)`"
				></div>
			</div>
		</div>
		<!-- End Range -->
		
		<!-- Heatmap Main Table -->
		<div class="heatmap flex gap-1 max-w-full pb-4 items-start" v-if="!isCyberHealthMode">
			
			<!-- Organisations Name Column (First Column) -->
			<div class="grid grid-cols-1 gap-1 whitespace-nowrap flex-shrink-0">
				<div
					class="h-24 2xl:h-[74px] flex items-center justify-center bg-bgLight px-4 sticky top-0 z-10"
				>
					<p class="text-lg text-gray-300 text-center">Organisation / System</p>
				</div>
				<div
					v-for="(org, i) in flatStructure"
					:key="i"
					class="h-8 border transition-colors duration-300"
					:class="
            {
              // 'hoveredBg': (i == hoveredRow),
              // 'bg-bgLight': (i != hoveredRow),
              'border-gray-400': (org.org_type == 'VBU'),
              'border-green-500': (org.org_type == 'Group'),
              'border-yellow-600': (org.org_type == 'Sub-Portfolio'),
              'border-blue-500': (org.org_type == 'Portfolio'),
              'bg-gray-800': (i == hoveredRow && org.org_type == 'VBU'),
              'bg-green-800 bg-opacity-20': (i == hoveredRow && org.org_type == 'Group'),
              'bg-yellow-800 bg-opacity-20': (i == hoveredRow && org.org_type == 'Sub-Portfolio'),
              'bg-blue-800 bg-opacity-20': (i == hoveredRow && org.org_type == 'Portfolio'),
            }
          "
					@mouseover="selectHoveredRow(i)"
          @mouseleave="selectHoveredRow(null)"
          v-show="!isOrderByRankMode || (isOrderByRankMode && !isAllRowBlack(org))"
        >
					<div class="flex justify-between items-center w-100">
						<h4
							class="text-sm py-1.5 ml-4 pr-2 relative font-normal text-gray-300 item--name"
							:class="org.org_type"
						>
							<!-- <i
								class="w-1.5 h-1.5 rounded-xl absolute -left-2.5 top-1/2 transform -translate-y-1/2"
								:class="
									{
										'bg-green-500': (org.org_type == 'Group'),
										'bg-yellow-600': (org.org_type == 'Sub-Portfolio'),
										'bg-blue-500': (org.org_type == 'Portfolio')
									}
								"
							></i> -->
							{{ org.name }}
						</h4>
						<div
							class="rank pr-2 text-xs"
							:class="
                {
                  'text-gray-400': (org.org_type == 'VBU'),
                  'text-green-500': (org.org_type == 'Group'),
                  'text-yellow-600': (org.org_type == 'Sub-Portfolio'),
                  'text-blue-500': (org.org_type == 'Portfolio')
                }
              "
							v-if="showValue"
						>
							Rank {{ org.scoreInfo.rank }} / {{ org.scoreInfo.allOrgs }}
						</div>
					</div>
				
				</div>
			</div>
			<!-- End Organisations Name Column (First Column) -->
			
			<!-- System Column -->
			<HeatmapCrowdstrike
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapWebAppScans
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapProofpoint
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapKnowBe4
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapSpycloud
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapCisco
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapVulnerabilityManagement
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapAzure
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapWorkday
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapSixClicks
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
			
			<!-- System Column -->
			<HeatmapSecurityScoreCard
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
				:showValue="showValue"
			/>
			<!-- End System Column -->
		
		</div>
		<div class="heatmap flex gap-1 max-w-full pb-4 items-start" v-else>
			
			<!-- Organisations Name Column (First Column) -->
			<div class="grid grid-cols-1 gap-1 whitespace-nowrap flex-shrink-0">
				<div
					class="h-24 2xl:h-[74px] flex items-center justify-center bg-bgLight px-4 sticky top-0 z-10"
				>
					<p class="text-lg text-gray-300 text-center">Organisation / System</p>
				</div>
				<div
					v-for="(org, i) in flatStructure"
					:key="i"
					class="h-8 border transition-colors duration-300"
					:class="
            {
              // 'hoveredBg': (i == hoveredRow),
              // 'bg-bgLight': (i != hoveredRow),
              'border-gray-400': (org.org_type == 'VBU'),
              'border-green-500': (org.org_type == 'Group'),
              'border-yellow-600': (org.org_type == 'Sub-Portfolio'),
              'border-blue-500': (org.org_type == 'Portfolio'),
              'bg-gray-800': (i == hoveredRow && org.org_type == 'VBU'),
              'bg-green-800 bg-opacity-20': (i == hoveredRow && org.org_type == 'Group'),
              'bg-yellow-800 bg-opacity-20': (i == hoveredRow && org.org_type == 'Sub-Portfolio'),
              'bg-blue-800 bg-opacity-20': (i == hoveredRow && org.org_type == 'Portfolio'),
            }
          "
					@mouseover="selectHoveredRow(i)"
					@mouseleave="selectHoveredRow(null)"
					v-show="showRow(org)"
				>
					<div class="flex justify-between items-center w-100">
						<h4
							class="text-sm py-1.5 ml-4 pr-2 relative font-normal text-gray-300 item--name"
							:class="org.org_type"
						>
							<!-- <i
								class="w-1.5 h-1.5 rounded-xl absolute -left-2.5 top-1/2 transform -translate-y-1/2"
								:class="
									{
										'bg-green-500': (org.org_type == 'Group'),
										'bg-yellow-600': (org.org_type == 'Sub-Portfolio'),
										'bg-blue-500': (org.org_type == 'Portfolio')
									}
								"
							></i> -->
							{{ org.name }}
						</h4>
						<div
							class="rank pr-2 text-xs"
							:class="
                {
                  'text-gray-400': (org.org_type == 'VBU'),
                  'text-green-500': (org.org_type == 'Group'),
                  'text-yellow-600': (org.org_type == 'Sub-Portfolio'),
                  'text-blue-500': (org.org_type == 'Portfolio')
                }
              "
							v-if="showValue"
						>
							Rank {{ org.scoreInfo.rank }} / {{ org.scoreInfo.allOrgs }}
						</div>
					</div>
				
				</div>
			</div>
			<!-- End Organisations Name Column (First Column) -->
			
			<!-- Cyber Health Column -->
			<HeatmapCyberHealth
				:flatStructure="flatStructure"
				@onRowHover="selectHoveredRow"
				@onRowClick="clickedRow = $event"
				:hoveredRow="hoveredRow"
				:clickedRow="clickedRow"
			/>
			<!-- End Cyber Health Column -->
		
		</div>
		<!-- End Heatmap Main Table -->
	
	</div>
	
	<!-- Security Summary Modal -->
	<SecuritySummaryModal ref="SecuritySummaryModal" :selectedOrgId="selectedOrgId"/>
</template>

<script>
import BaseToggle from '@/components/Base/BaseToggle';
import HeatmapCrowdstrike from '@/components/HeatmapCrowdstrike';
import HeatmapWebAppScans from '@/components/HeatmapWebAppScans';
import HeatmapSpycloud from '@/components/HeatmapSpycloud';
import HeatmapProofpoint from '@/components/HeatmapProofpoint';
import HeatmapKnowBe4 from '@/components/HeatmapKnowBe4';
import HeatmapCisco from '@/components/HeatmapCisco';
import HeatmapVulnerabilityManagement from '@/components/HeatmapVulnerabilityManagement';
import HeatmapAzure from '@/components/HeatmapAzure';
import HeatmapWorkday from '@/components/HeatmapWorkday';
import HeatmapSixClicks from '@/components/HeatmapSixClicks';
import HeatmapSecurityScoreCard from '@/components/HeatmapSecurityScoreCard';
import HeatmapCyberHealth from "@/components/HeatmapCyberHealth.vue";

import {debounce} from 'debounce';

import {coloursScale} from '@/utils/colors';
import SecuritySummaryModal from '@/components/SecuritySummaryModal';

export default {
	components: {
    HeatmapCyberHealth,
		SecuritySummaryModal,
		BaseToggle,
		HeatmapCrowdstrike,
		HeatmapWebAppScans,
		HeatmapSpycloud,
		HeatmapProofpoint,
		HeatmapKnowBe4,
		HeatmapCisco,
		HeatmapVulnerabilityManagement,
		HeatmapAzure,
		HeatmapWorkday,
		HeatmapSixClicks,
		HeatmapSecurityScoreCard,
	},
	data() {
		return {
			hoveredRow: null,
			clickedRow: null,
			showValue: false,
			flatStructure: [],
			flatStructureNotOrdered: [],
			flatStructureOrdered: []
		};
	},
	computed: {
		isOrderByRankMode() {
			return this.$store.state.structure.isOrderByRankMode;
		},
		isAggregateMode() {
			return this.$store.state.structure.isAggregateMode;
		},
    isCyberHealthMode() {
      return this.$store.state.structure.isCyberHealthMode;
    },
		isShowPortfolioOnly() {
			return this.$store.state.structure.isShowPortfolioOnly;
		},
		selectedOrgId() {
			if (!this.clickedRow) return null
			return this.flatStructure[this.clickedRow]?.org_id
		},
		isColorBlindMode() {
			return this.$store.state.ui.colorblindMode;
		},
		colorMode() {
			return this.$store.state.ui.colorblindMode ? 'blind' : 'normal';
		},
		reversedColoursScale() {
			return [...coloursScale()].reverse();
		},
		selectedHierarchy() {
			return this.$store.state.structure.selectedHierarchy;
		},
		hierarchy() {
			if (this.selectedHierarchy.length) {
				return this.selectedHierarchy;
			} else {
				return this.$store.state.structure.hierarchy;
			}
		},
		isHeatmapDataPrepared() {
			return this.$store.state.heatmap.heatmapData.length > 0;
		},
		isAggregateModeDataPrepared() {
			let data = this.$store.state.heatmapAggregate.heatmapAggregate;
			
			return data && Object.getPrototypeOf(data) === Object.prototype && Object.keys(data).length !== 0;
		}
	},
	async created() {
		if (!this.$store.state.heatmap.heatmapData.length) {
			await this.$store.dispatch('heatmap/getHeatmapData');
			await this.$store.dispatch('heatmap/setHeatmapColors');
		}

		this.setFlatStructure();
		this.showAll(); // remove all filters when we enter to heatmap page to show all of data

    // Show Cyber Health and Portfolio only when Aggregate Mode is active
    if(this.isAggregateMode) {
      if(!this.isCyberHealthMode) {
        this.$store.commit('structure/SET_CYBER_HEALTH_MODE');
      }
      if(!this.isShowPortfolioOnly) {
        this.$store.commit('structure/SET_TOGGLE_SHOW_PORTFOLIO_ONLY');
      }
    }

		// check aggregate mode
		if (this.isAggregateMode) {
			this.calculateHeatmapAggregate();
		}
	},
	methods: {
		selectHoveredRow: debounce(function (row) {
			this.hoveredRow = row;
		}, 5),
		restructurize(level, acc = []) {
			if (!level?.children) {
				return level;
			}
			
			let childrenObjects = level.children.flatMap((el) =>
				this.restructurize(el, acc)
			);
			
			const newLevel = {...level};
			delete newLevel.children;
			
			return [...acc, newLevel, ...childrenObjects];
		},
		valuesHandler() {
			this.showValue = !this.showValue;
		},
		changeColorBlind(val) {
			this.$store.commit('ui/SET_COLORBLIND', val);
		},
		showRow(org) {
			if (this.isShowPortfolioOnly) {
				return ['Portfolio', 'Sub-Portfolio', 'Group'].includes(org.org_type)
			} else {
				return !['Portfolio', 'Sub-Portfolio', 'Group'].includes(org.org_type) || ['Volaris Consolidated', 'Lumine Group'].includes(org.name)
			}
		},
		overallScoreCalculate(org) {
			let systems = this.$store.state.heatmap.heatmapColorsValues[org.org_id];
			
			// number of eligible deployment or operational system
			let numberOfEligible = 0;
			for (let systemTitle in systems) {
				let system = systems[systemTitle];
				
				if (system.operational.value !== 'N/A') numberOfEligible++;
				if (system.deployment.value !== 'N/A') numberOfEligible++;
			}
			
			// each color percent of effect
			let colorPercentList = {
				'117, 226, 70': +((100 / numberOfEligible).toFixed(2)), // green 100% of eligible count
				'228, 248, 73': +(((100 / numberOfEligible) * 0.8).toFixed(2)), // yellow 80% of eligible count
				'248, 158, 73': +(((100 / numberOfEligible) * 0.6).toFixed(2)), // orange 60% of eligible count
				'248, 108, 73': +(((100 / numberOfEligible) * 0.4).toFixed(2)), // dark orange 40% of eligible count
				'248, 73, 73': +(((100 / numberOfEligible) * 0.2).toFixed(2)), // red 20% of eligible count
				'&#10071;': 1, // gray is 1 always
			}
			
			// calculate overall score
			let overallScore = 0;
			for (let systemTitle in systems) {
				let system = systems[systemTitle];
				
				overallScore += colorPercentList[system.operational.color.normal] || 0;
				overallScore += colorPercentList[system.deployment.color.normal] || 0;
			}
			
			return +(overallScore.toFixed(1));
		},
		setFlatStructure() {
			let flatStructureArr = [];
			
			this.hierarchy.forEach((level) => {
				flatStructureArr.push(this.restructurize(level));
			});
			
			// Set overall score and ranking and overall score aggregate for organisations
			let orgs = JSON.parse(JSON.stringify(flatStructureArr.flat()));
			let overallScoreObject = {
				VBU: [],
				Group: [],
				Portfolio: [],
        'Sub-Portfolio': []
			};
			
			// calculate overall score and categories by VBU, Group, Portfolio
			orgs.forEach((org) => {
				let overallScoreInfo = {
					org_id: org.org_id,
					org_type: org.org_type,
					overallScore: this.overallScoreCalculate(org)
				};
				
				overallScoreObject[org.org_type].push(overallScoreInfo);
				
				org.scoreInfo = overallScoreInfo;
			})
			
			// aggregate overall score (change overall score for Portfolios, Sub-Portfolios, and Groups)
			if (this.isShowPortfolioOnly) {
				orgs.forEach((item) => {
					if (item.VBUs) {
						item.VBUs.forEach((VBUItem) => {
							let val = orgs.find((orgItem) => (orgItem.org_id == VBUItem.org_id)).scoreInfo.overallScore;
							VBUItem.overallScore = val;
						})
						
						item.scoreInfo.overallScore = item.VBUs.reduce((sum, currentItem) => sum + Number(currentItem.overallScore), 0) / item.VBUs.length;
						item.scoreInfo.overallScore = +(item.scoreInfo.overallScore.toFixed(1))
						
						let obj = overallScoreObject[item.org_type].find((itemType) => itemType.org_id == item.org_id);
						obj.overallScore = item.scoreInfo.overallScore;
					}
				})
			}
			// console.log(orgs.find((org) => org.name == 'John Crook Group'))
		
			// set rank for orgs
			for (let type in overallScoreObject) {
				overallScoreObject[type].sort((a, b) => b.overallScore - a.overallScore);
				let orgTypeLength = overallScoreObject[type].length;
				
				overallScoreObject[type].forEach((item, index) => {
					let org = orgs.find((org) => org.org_id == item.org_id);
					org.scoreInfo.rank = index + 1;
					org.scoreInfo.allOrgs = orgTypeLength;
				})
			}
			
			// set not ordered flat structure
			this.flatStructureNotOrdered = orgs;
			
			// set ordered flat structure
			this.flatStructureOrdered = [];
			let typeList = ['Portfolio', 'Sub-Portfolio', 'Group', 'VBU'];
			for (let type of typeList) {
				overallScoreObject[type].forEach((item) => {
					let org = orgs.find((orgItem) => orgItem.org_id == item.org_id)
					this.flatStructureOrdered.push(org);
				})
			}
			
			// set flat structure using order mode
			if (this.isOrderByRankMode) {
				this.flatStructure = this.flatStructureOrdered;
			} else {
				this.flatStructure = this.flatStructureNotOrdered;
			}
		},
		changeOrderByRankMode(isOrdered) {
			if (isOrdered) {
				this.flatStructure = this.flatStructureOrdered;
			} else {
				this.flatStructure = this.flatStructureNotOrdered;
			}

      this.$store.commit('structure/SET_TOGGLE_ORDER_BY_RANK_MODE')
		},
		showAll() {
			this.$store.commit('structure/SELECT_HIERARCHY', {
				hierarchy: this.$store.state.structure.hierarchy,
			});
		},
    toggleCyberHealthMode() {
			this.$store.commit('structure/SET_CYBER_HEALTH_MODE');
		},
		toggleShowPortfolioOnly() {
			this.$store.commit('structure/SET_TOGGLE_SHOW_PORTFOLIO_ONLY');
		},
		async calculateHeatmapAggregate() {
			const noVBUsOrgs = this.flatStructure.filter((org) => org.VBUs);
			const heatmapColorsValues = this.$store.state.heatmap.heatmapColorsValues;
		
			await this.$store.dispatch('heatmapAggregate/calculateHeatmapAggregate', {noVBUsOrgs, heatmapColorsValues})
		},
    isAllRowBlack(org) {
      if (['Portfolio', 'Sub-Portfolio', 'Group'].includes(org.org_type)) {
        if (!['Volaris Consolidated', 'Lumine Group'].includes(org.name)) {
          return true;
        }
      }

      return false;
    }
	},
	watch: {
		hierarchy(val) {
			this.setFlatStructure();
			// this.calculateHeatmapAggregate();
		},
		isAggregateMode(val) {
			// this.calculateHeatmapAggregate();
		},
		isShowPortfolioOnly(val) {
			this.setFlatStructure();
		}
	}
};
</script>

<style lang="scss">
body.blur {
	.heatmap-view,
	.main-header,
	.sidebar-toggle-button,
	.sidebar {
		filter: blur(6px);
	}
}

// .heatmap {
//   height: 70vh;
// }
.heatmap-view {
	/* width */
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	
	/* button */
	::-webkit-scrollbar-button {
		height: 0px;
		width: 5px;
		background: #222;
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #393950;
	}
	
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		@apply cursor-pointer;
		
		background: #5a5a7e;
	}
	
	/* Track */
	::-webkit-scrollbar-track {
		background: #1d1d27;
	}
	
	/* The track NOT covered by the handle. */
	// ::-webkit-scrollbar-track-piece {
	//     background: rgb(25, 25, 26);
	// }
	/* Corner */
	::-webkit-scrollbar-corner {
		background: #999;
	}
	
	/* Resizer */
	::-webkit-resizer {
		background: #111;
	}
}

.heatmap-item {
	@apply h-8 flex justify-center mt-1 transition-colors duration-300 rounded-sm bg-gray-600 cursor-pointer border border-opacity-50;
	
	&.not-applicable {
		@apply cursor-auto;
	}
	
	p {
		position: relative;
		@apply text-white text-center my-2;
		
		text-shadow: -1px 0px 1px #000000b3, 1px 0px 1px #000000b3, 0px -1px 1px #000000b3, 0px 1px 1px #000000b3;
		font-size: 0.65rem;
		z-index: 1;
		
		&:before {
			content: "";
			position: absolute;
			background: #212020;
			width: calc(100% + 12px);
			height: 15px;
			z-index: -1;
			top: 0;
			left: -6px;
			border-radius: 3px;
		}
	}
}

.item--name {
	@apply relative;
	
	&::after {
		@apply absolute top-1/2 transform -translate-y-1/2 w-1.5 h-1.5 rounded-full;
		
		content: '';
		left: -1rem;
	}
	
	&.vbu::after {
		@apply bg-gray-400;
	}
	
	&.group::after {
		@apply bg-green-500;
	}
	
	&.portfolio::after {
		@apply bg-blue-500;
	}
	
	&.sub-portfolio::after {
		@apply opacity-100 bg-yellow-600;
	}
}

.hoveredBg {
	background: #353541;
}
</style>
