<template>
  <div><bar-chart :chartData="chartData" :options="options" /></div>
</template>

<script>
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  props: {
    chartData: {
      type: Object,
    },
  },
  components: {
    BarChart,
  },
  setup() {
    const options = {
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 9,
            boxHeight: 9,
            usePointStyle: true,
            color: 'rgba(255,255,255, 0.8)',
            padding: 15,
          },
            title: {
              display: true,
              color: 'rgba(255,255,255, 0.8)',
              text: 'Campaign Status - click on statuses to toggle on/off on the bar chart',
              padding: 5,
              font: {
                size: 14,
            },
          },
        },
      },
      scales: {
        y: {
          stacked: true,
          grid: {
            color: 'rgba(255, 255, 255, .1)',
          },
          ticks: {
            color: 'rgba(255, 255, 255, .65)',
            padding: 8,
          },
          title: {
            display: true,
            color: 'rgba(255, 255, 255, .8)',
            text: 'Users',
            padding: 8,
            font: {
              size: 13,
            },
          },
        },
        x: {
          stacked: true,
          grid: {
            color: 'rgba(255, 255, 255, .1)',
          },
          ticks: {
            color: 'rgba(255, 255, 255, .65)',
            padding: 10,
	          maxRotation: 0,
	          autoSkip: false
          }
        },
      },
      // legend: { display: true },
    };

    return { options };
  },
};
</script>

<style lang="scss" scoped></style>
