<template>
  <router-link class="card flex flex-col" :class="{ 'cursor-auto': !orgData?.is_clickable }"
    :to="orgData?.is_clickable > 0 ? `/web_app_scans/${org.org_id}` : '#'" @click="isClicked = true">
    <div
      class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
      :class="this.org && 'clickable'">
      <div>
        <span>Web Application Scans</span>
        <BaseTooltip class="relative ml-2 inline-block" :info="'Web Application Security description'">
          <font-awesome-icon class="text-gray-500" :icon="['fa', 'info-circle']" />
        </BaseTooltip>
      </div>
      <div class="flex items-center">
        <img src="@/assets/invicti_logo.png" class="h-6 mr-3 inline-block" />
        <div class="not-clickable-warning text-primary text-sm mr-3" v-if="!orgData?.has_data && isClicked">
          <font-awesome-icon class="text-primary mr-1" :icon="['fa', 'info-circle']" />
          <span>No click-through data</span>
        </div>
        <font-awesome-icon v-if="orgData?.has_data"
          class="text-gray-700 text-xl transform -rotate-45 icon transition-colors" :icon="['fa', 'arrow-right']" />
      </div>
    </div>
    <div class="grid h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg" :style="gridCount" v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode)">
      <div class="col-span-3 flex items-center border-r border-gray-700" :class="{'col-span-4': orgData?.domains_not_scanned && orgData?.domains_not_scanned === 0}">
        <div class="flex flex-col items-center px-1 flex-grow">
          <div class="flex items-center">
            <font-awesome-icon class="text-xl mr-2 text-blue-500" :icon="['fa', 'calendar-day']" />
            <h2 class="text-4xl" v-if="isAggregateMode && org.org_type != 'VBU'">
              {{ orgDataAggregate.last_scan_days || '-' }}
            </h2>
            <h2 class="text-4xl" v-else>
              {{ orgData?.last_scan_days || '-' }}
            </h2>
          </div>
          <p class="text-xs text-gray-400 text-center" v-if="!isAggregateMode">
            day(s) since last scan
            <span class="text-xs text-gray-400 text-center" v-if="orgData.has_primary_domain">
              of primary website
            </span>
            <span class="text-xs text-gray-400 text-center" v-else> of earliest website scanned </span>
          </p>

          <p class="text-xs text-gray-400 text-center" v-else>
            average number of<br />
            days since last scan
          </p>
        </div>
        <div class="self-stretch border-l border-gray-700"></div>
        <div class="flex flex-col  p-3">
          <div class="border-gray-700">
            <div class="flex items-center justify-center" :class="
              orgDataAggregate?.scan_status == 'Complete'
                ? 'text-green-500'
                : orgDataAggregate?.scan_status == 'Failed' || orgDataAggregate?.scan_status == 'Cancelled'
                  ? 'text-red-500'
                  : 'text-yellow-500'
            " v-if="isAggregateMode">
              <font-awesome-icon class="text-sm" :icon="[
                'fa',
                `${orgDataAggregate?.scan_status == 'Complete'
                  ? 'check-circle'
                  : orgDataAggregate?.scan_status == 'Failed' || orgDataAggregate?.scan_status == 'Cancelled'
                    ? 'times-circle'
                    : 'exclamation-circle'
                }`,
              ]" />
              <h4 class="uppercase text-center ">
                {{ orgDataAggregate?.scan_status || '-' }}
              </h4>
            </div>
            <div class="flex items-center" :class="
              orgData?.scan_status == 'Complete'
                ? 'text-green-500'
                : orgData?.scan_status == 'Failed' || orgData?.scan_status == 'Cancelled'
                  ? 'text-red-500'
                  : 'text-yellow-500'
            " v-else>
              <font-awesome-icon class="text-sm" :icon="[
                'fa',
                `${orgData?.scan_status == 'Complete'
                  ? 'check-circle'
                  : orgData?.scan_status == 'Failed' || orgData?.scan_status == 'Cancelled'
                    ? 'times-circle'
                    : 'exclamation-circle'
                }`,
              ]" />
              <h4 class="uppercase text-center ">{{ orgData?.scan_status || '-' }}</h4>
            </div>
            <div class="flex justify-center" v-if="!isAggregateMode">
              <p class="text-xs text-gray-400 text-center">
                scan status <br />
                <span class="text-xs text-gray-400 text-center" v-if="orgData.has_primary_domain">
                  of primary website
                </span>
                <span class="text-xs text-gray-400 text-center" v-else> of earliest website scanned </span>
              </p>
            </div>
            <p class="text-xs text-gray-400 text-center" v-else>
              most common <br />
              scan status
            </p>
          </div>
        </div>
        <div class="self-stretch border-l border-gray-700"></div>
        <div class="flex flex-col items-center px-1 flex-grow"
          v-if="orgData.domains_not_scanned && orgData.domains_not_scanned !== 0">
          <div class="flex items-center">
            <font-awesome-icon class="text-xl mr-2 text-red-500" :icon="['fa', 'exclamation-triangle']" />
            <h2 class="text-4xl" v-if="isAggregateMode && org.org_type != 'VBU'">
              {{ orgDataAggregate.domains_not_scanned || '-' }}
            </h2>
            <h2 class="text-4xl" v-else>
              {{ orgData?.domains_not_scanned || '-' }}
            </h2>
          </div>
          <p class="text-xs text-gray-400 text-center" v-if="!isAggregateMode">unscanned domains</p>
          <p class="text-xs text-gray-400 text-center" v-else>
            total number of<br />
            unscanned domains
          </p>
        </div>
        <div class="self-stretch border-l border-gray-700"></div>
        <div class="flex flex-col items-center p-3">
          <div class="flex items-center">
            <font-awesome-icon class="text-xl mr-2 text-yellow-500" :icon="['fa', 'exclamation-triangle']" />
            <h2 class="text-2xl" v-if="isAggregateMode && org.org_type != 'VBU'">
              {{ orgDataAggregate.outstanding_issues || '-' }}
            </h2>
            <h2 class="text-4xl" v-else>
              {{ orgData?.outstanding_issues || '-' }}
            </h2>
          </div>
          <p class="text-xs text-gray-400 text-center">issues from all domains<br />(recent scan)</p>
        </div>
      </div>
        
      <div class="flex flex-col justify-center items-center border-r border-gray-700" v-if="orgData.min_medium_fixed_last_2_months || orgData.critical_vulns_present_later_28_days">
        <div class="px-1 py-3" v-if="orgData.min_medium_fixed_last_2_months">
          <div class="flex items-center justify-center">
            <font-awesome-icon class="text-green-500 text-sm mr-2" :icon="['fas', 'wrench']" />
            <h4 class="uppercase" v-if="isAggregateMode && org.org_type != 'VBU'">
              {{ orgDataAggregate.min_medium_fixed_last_2_months || '-' }}
            </h4>
            <h4 class="uppercase" v-else>
              {{ orgData?.min_medium_fixed_last_2_months || '-' }}
            </h4>
          </div>
          <p class="text-xs text-gray-400 text-center">issues fixed within<br> last 2 months </p>
        </div>
        <div class="px-1 border-b border-gray-700 w-full" v-if="orgData.min_medium_fixed_last_2_months && orgData.critical_vulns_present_later_28_days"></div>
        <div class="px-1 py-3" v-if="orgData.critical_vulns_present_later_28_days">
          <div class="flex items-center justify-center">
            <font-awesome-icon class="text-xl mr-2 text-red-500" :icon="['fa', 'exclamation-triangle']" />
            <h4 v-if="isAggregateMode && org.org_type != 'VBU'">
              {{ orgDataAggregate.critical_vulns_present_later_28_days || '-' }}
            </h4>
            <h4 v-else>
              {{ orgData?.critical_vulns_present_later_28_days || '-' }}
            </h4>
          </div>
          <p class="text-xs text-gray-400 text-center">critical issues older than 28 days</p>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="px-1 py-3 border-b border-gray-700">
          <div class="flex items-center justify-center">
            <h4 class="uppercase" v-if="isAggregateMode && org.org_type != 'VBU'">
              {{ orgDataAggregate.num_domains || '-' }}
            </h4>
            <h4 class="uppercase" v-else>
              {{ orgData?.num_domains || '-' }}
            </h4>
          </div>
          <p class="text-xs text-gray-400 text-center">number of<br> domains</p>
        </div>
        <div class="px-1 py-3">
          <div class="flex items-center justify-center">
            <font-awesome-icon class="text-yellow-400 text-sm mr-2" :icon="['fa', 'calendar-day']" />
            <h4 v-if="isAggregateMode && org.org_type != 'VBU'">
              {{ orgDataAggregate.oldest_issue_days }}
              <span class="text-xs text-gray-400 font-normal">day(s)</span>
            </h4>
            <h4 v-else>
              {{ orgData?.oldest_issue_days }}
              <span class="text-xs text-gray-400 font-normal">day(s)</span>
            </h4>
          </div>
          <p class="text-xs text-gray-400 text-center">oldest issue</p>
        </div>
      </div>
      <!-- <div class="p-3 flex flex-col justify-center items-center w-2/5">

      </div> -->
    </div>
    <div class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
      v-else>
      <p class="py-8 opacity-50">No website assigned</p>
    </div>
  </router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
  components: {
    BaseTooltip,
  },
  data() {
    return {
      isClicked: false,
    };
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
    orgData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gridCount() {
      console.log(this.orgData)
      let cols = 4
      if (this.orgData.domains_not_scanned && this.orgData.domains_not_scanned === 0) {
          cols += 1
      }
      if (this.orgData.min_medium_fixed_last_2_months || this.orgData.critical_vulns_present_later_28_days) {
        cols += 1
      }
      return {
        'grid-template-columns': `repeat(${cols}, minmax(0, 1fr))`
      }
    },
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    orgDataAggregate() {
      let VBUs = this.org.VBUs;
      let widgetsData = this.$store.state.widgets.widgetsData;

      if (VBUs && VBUs.length) {
        let dataArr = [];
  
        VBUs.forEach((VBUItem) => {
          widgetsData?.forEach((orgItem) => {
            if (VBUItem.org_id === +orgItem.org_id) {
              dataArr.push({
                min_medium_fixed_last_2_months: orgItem.data.web_application_scans.min_medium_fixed_last_2_months || 0,
                critical_vulns_present_later_28_days: orgItem.data.web_application_scans.critical_vulns_present_later_28_days || 0,
                last_scan_days: orgItem.data.web_application_scans.last_scan_days || 0,
                outstanding_issues: orgItem.data.web_application_scans.outstanding_issues || 0,
                num_domains: orgItem.data.web_application_scans.num_domains || 0,
                oldest_issue_days: orgItem.data.web_application_scans.oldest_issue_days || 0,
                scan_status: orgItem.data.web_application_scans.scan_status,
              });
            }
          });
        });

        let statusList = {};
        let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
          statusList[currentObj.scan_status] = (statusList[currentObj.scan_status] || 0) + 1;

          return {
            min_medium_fixed_last_2_months: (sumObj.min_medium_fixed_last_2_months || 0) + (currentObj.min_medium_fixed_last_2_months || 0),
            critical_vulns_present_later_28_days: (sumObj.critical_vulns_present_later_28_days || 0) + (currentObj.critical_vulns_present_later_28_days || 0),
            last_scan_days: (sumObj.last_scan_days || 0) + (currentObj.last_scan_days || 0),
            outstanding_issues: (sumObj.outstanding_issues || 0) + (currentObj.outstanding_issues || 0),
            num_domains: (sumObj.num_domains || 0) + (currentObj.num_domains || 0),
            oldest_issue_days:
              (sumObj.oldest_issue_days || 0) < (currentObj.oldest_issue_days || 0)
                ? currentObj.oldest_issue_days || 0
                : sumObj.oldest_issue_days || 0,
          };
        }, 0);

        // aggregate scan_status
        let maxScanStatus = Object.entries(statusList).reduce((max, nextValue) => {
          return nextValue[1] >= max[1] ? nextValue : max;
        });

        return {
          last_scan_days: Number.isInteger(sumVBUs.last_scan_days / dataArr.length)
            ? sumVBUs.last_scan_days / dataArr.length
            : (sumVBUs.last_scan_days / dataArr.length).toFixed(2),
          outstanding_issues: sumVBUs.outstanding_issues,
          num_domains: sumVBUs.num_domains,
          oldest_issue_days: sumVBUs.oldest_issue_days,
          scan_status: maxScanStatus[0] != 'undefined' ? maxScanStatus[0] : '-',
          min_medium_fixed_last_2_months: sumVBUs.min_medium_fixed_last_2_months,
          critical_vulns_present_later_28_days: sumVBUs.critical_vulns_present_later_28_days
        };
      } else {
        return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  &--title {
    @apply transition-colors duration-300 relative;
  }

  &:hover {
    .card--title.clickable {
      background-color: rgba(52, 52, 62, 1);

      .icon {
        @apply text-gray-400;
      }
    }
  }
}
</style>
