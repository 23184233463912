<template>
	<div class="domain">
		<span class="mr-1 text-gray-500">Domain:</span>
		<span class="text-gray-300">{{otherInfo.domain}}</span>
	</div>
  <div class="chart w-3/5 text-center m-auto">
	  <line-chart
		  style="height: 500px;"
		  :chartData="chartData"
		  :options="options"
	  />
  </div>
	<div class="info mt-4">
		<div class="item flex justify-center items-center text-gray-700 mb-2">
			<div class="title mr-4 text-white w-36">Patching Cadence</div>
			<div class="high flex justify-between px-2 py-1 mr-2 bg-red-200 w-72">
				<div class="title whitespace-nowrap mr-2">
					<span class="inline-flex justify-center items-center mr-1 bg-red-600 rounded-3xl w-5 h-5 flex-shrink-0 text-white">!!!</span>HIGH SEVERITY
				</div>
				<div class="findings">{{ otherInfo.issue_summary.patching_cadence_high }} findings</div>
			</div>
			<div class="medium flex justify-between px-2 py-1 mr-2 bg-yellow-200 w-72">
				<div class="title whitespace-nowrap mr-2">
					<span class="inline-flex justify-center items-center mr-1 bg-yellow-500 rounded-3xl w-5 h-5 flex-shrink-0 text-white">!!</span>MEDIUM SEVERITY
				</div>
				<div class="findings">{{ otherInfo.issue_summary.patching_cadence_medium }} findings</div>
			</div>
			<div class="low flex justify-between px-2 py-1 bg-yellow-100 w-72">
				<div class="title whitespace-nowrap mr-2">
					<span class="inline-flex justify-center items-center mr-1 bg-yellow-300 rounded-3xl w-5 h-5 flex-shrink-0 text-white">!</span>LOW SEVERITY
				</div>
				<div class="findings">{{ otherInfo.issue_summary.patching_cadence_low }} findings</div>
			</div>
		</div>
		<div class="item flex justify-center items-center text-gray-700 mb-2">
			<div class="title mr-4 text-white w-36">Network Security</div>
			<div class="high flex justify-between px-2 py-1 mr-2 bg-red-200 w-72 w-72">
				<div class="title whitespace-nowrap mr-2">
					<span class="inline-flex justify-center items-center mr-1 bg-red-600 rounded-3xl w-5 h-5 flex-shrink-0 text-white">!!!</span>HIGH SEVERITY
				</div>
				<div class="findings whitespace-nowrap">{{ otherInfo.issue_summary.network_security_high }} findings</div>
			</div>
			<div class="medium flex justify-between px-2 py-1 mr-2 bg-yellow-200 w-72">
				<div class="title whitespace-nowrap mr-2">
					<span class="inline-flex justify-center items-center mr-1 bg-yellow-500 rounded-3xl w-5 h-5 flex-shrink-0 text-white">!!</span>MEDIUM SEVERITY
				</div>
				<div class="findings">{{ otherInfo.issue_summary.network_security_medium }} findings</div>
			</div>
			<div class="low flex justify-between px-2 py-1 bg-yellow-100 w-72">
				<div class="title whitespace-nowrap mr-2">
					<span class="inline-flex justify-center items-center mr-1 bg-yellow-300 rounded-3xl w-5 h-5 flex-shrink-0 text-white">!</span>LOW SEVERITY
				</div>
				<div class="findings">{{ otherInfo.issue_summary.network_security_low }} findings</div>
			</div>
		</div>
		<div class="item flex justify-center items-center text-gray-700 mb-2">
			<div class="title mr-4 text-white w-36">Application Security</div>
			<div class="high flex justify-between px-2 py-1 mr-2 bg-red-200 w-72">
				<div class="title whitespace-nowrap mr-2">
					<span class="inline-flex justify-center items-center mr-1 bg-red-600 rounded-3xl w-5 h-5 flex-shrink-0 text-white">!!!</span>HIGH SEVERITY
				</div>
				<div class="findings">{{ otherInfo.issue_summary.application_security_high }} findings</div>
			</div>
			<div class="medium flex justify-between px-2 py-1 mr-2 bg-yellow-200 w-72">
				<div class="title whitespace-nowrap mr-2">
					<span class="inline-flex justify-center items-center mr-1 bg-yellow-500 rounded-3xl w-5 h-5 flex-shrink-0 text-white">!!</span>MEDIUM SEVERITY
				</div>
				<div class="findings">{{ otherInfo.issue_summary.application_security_medium }} findings</div>
			</div>
			<div class="low flex justify-between px-2 py-1 bg-yellow-100 w-72">
				<div class="title whitespace-nowrap mr-2">
					<span class="inline-flex justify-center items-center mr-1 bg-yellow-300 rounded-3xl w-5 h-5 flex-shrink-0 text-white">!</span>LOW SEVERITY
				</div>
				<div class="findings">{{ otherInfo.issue_summary.application_security_low }} findings</div>
			</div>
		</div>
	</div>
</template>

<script>
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  props: {
    chartData: {
      type: Object,
    },
	  otherInfo: {
		  type: Object,
	  }
  },
  components: {
	  LineChart
  },
  setup() {
    const options = {
      plugins: {
        legend: {
          position: 'top',
          labels: {
            boxWidth: 32,
            boxHeight: 9,
            color: 'rgba(255,255,255)',
            padding: 15,
          }
        }
      },
	    scales: {
		    x: {
			    grid: {
				    display: false
			    },
			    ticks: {
				    color: "white"
			    },
		    },
		    y: {
			    grid: {
				    display: false
			    },
			    ticks: {
				    color: "white",
			    },
		    }
	    }
    };

    return { options };
  }
};
</script>

<style lang="scss" scoped></style>
