<template>
  <div class="flex-auto ml-5">
<h2 class="text-xl text-gray-300">Invite New User</h2>
    <div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
    <div v-if="!isLoading" >
      <div v-if="!inviteSent">
        <form @submit.prevent="inviteUser" class="text-left font-semibold text-gray-600 text-sm max-w-md">
        <input-base
            class="w-full mt-4 text-gray-300"
            name="currentPw"
            label="Email"
            placeholder="name@example.com"
            :value="email"
            :showLabel="false"
            :required="true"
            @change="
              (event) => {
                email = event.target.value;
              }
            "
          />
        
        <div class="show-children-button flex justify-between mt-5">
          <p class="text-base text-gray-300 mr-2">Make this user an Administrator:</p>
          <BaseToggle
            @click.self.stop
            :name="'is_admin'"
            @change="
              (event) => {
                is_admin = event.target.value;
              }
            "
            :value="is_admin"
          />
        </div>
      </form>
      <div class="">
        <button class="btn mt-8 px-10"  @click.prevent="inviteUser">
          Send Invite
        </button>
      </div>
      </div>
      <div v-else>
        <p class="text-gray-300">Invite sent</p>
      </div>
    </div>

    <!-- Loader -->
    <div v-else>
     <spinner-base class="w-10 h-10 mx-auto my-12" />
    </div>
  </div>
</template>

<script>
import SpinnerBase from '@/components/Base/SpinnerBase';
import InputBase from '@/components/Base/InputBase';
import BaseToggle from '@/components/Base/BaseToggle';
import axios from 'axios'

export default {
  components: {
    SpinnerBase,
    BaseToggle,
    InputBase
  },
  data() {
    return {
      email: '',
      is_admin: false,
      inviteSent: false,
      isLoading: false,
    }
  },
  methods: {
    async inviteUser() {
        this.isLoading = true

        try {
          const { data } = await axios({
            method: "POST",
            url: "https://id-ciso.redsmart.app/invite",
            headers: {
              'Authorization': `Bearer ${this.$store.state.auth.jwt.jwt_token}`
            },
            data: {
          email: this.email,
          is_tenant_admin: this.is_admin
            }
          })

          console.log(data)
          this.inviteSent = true
          return (this.isLoading = false)
        } catch (e) {
          console.log(e)

          return (this.isLoading = false)
        }
    },
  },
  computed: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
.input {
  @apply px-6 p-4 mt-4 mb-6 focus:outline-none font-normal w-full rounded-lg;

  caret-color: #6e6def;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.03);
  transition: box-shadow 0.25s linear;

  &:focus {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.09);
  }
}
</style>
