<template>
  <div v-if="user_by_pk">
    <h2 class="text-2xl font-bold text-gray-700 mb-4 text-center">Edit User</h2>

    <div class="bg-gray-50 -mx-6 px-6 py-5">
      <form
        @submit.prevent="updateUser"
        class="text-left font-semibold text-gray-600 text-sm max-w-md mx-auto"
      >
        <div>
          <label for="name" class="font-semibold"> Name </label>
          <p class="py-5 font-normal">
            {{ user_by_pk.first_name }} {{ user_by_pk.last_name }}
          </p>
        </div>
        <label for="email" class="font-semibold"> Email Address </label>
        <p class="py-5 font-normal">{{ user_by_pk.email }}</p>

        <div>
          <select-base
            name="role"
            label="Role"
            placeholder="User's role"
            class="bg-white"
            :value="is_admin"
            :options="roleOptions"
            @change="changeRole($event)"
          />
        </div>
      </form>
      <div class="flex justify-center mt-6"> <button
          class="
            block
            btn
            text-sm
            focus:outline-none
            text-white
            whitespace-nowrap
            py-2
            px-4
            rounded-lg
            transition
            duration-150
            ease-in-out
          "
          :class="
            hasRoleChanged
              ? 'bg-blue-500 hover:bg-blue-600 cursor-pointer'
              : 'bg-gray-200 hover:none cursor-default'
          "
          type="submit"
          @click="updateUser(is_admin)"
        >
          Save User
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SelectBase from "@/components/Base/SelectBase"

export default {
  components: {
    SelectBase,
  },
  props: {
    userId: {
      type: String,
    },
  },
  data() {
    return {
      email: "",
      confirmDelete: false,
      static_role: null,
      is_admin: null,
      roleOptions: ["User", "Administrator"],
      isLoading: false,
    }
  },
  methods: {
    changeRole(evt) {
      this.is_admin = evt
      console.log(evt)
    },
    async updateUser(role) {
      if (!this.hasRoleChanged) return

        this.isLoading = true

        try {

          if (role === 'Administrator') {
            await this.$store.dispatch("user/insertTenantAdmin", { id: this.user_by_pk.id })
          } else {
            await this.$store.dispatch("user/deleteTenantAdmin", { id: this.user_by_pk.id })

          }
        this.$toasted.success('User has has been updated!', {
          theme: 'bubble',
          duration: 2000,
          position: "bottom-right"
        })
        this.$emit('hide')

        return this.isLoading = false
      } catch (e) {
        console.log(e)

        this.$toasted.global.error({
          message: "There was an error processing this request, please contact support",
        })

        return this.isLoading = false
      }
    },
  },
  computed: {
    hasRoleChanged() {
      const one = this.is_admin
      const two = this.static_role
      if (one !== two) return true
      return false
    },
  },
  mounted() {
    // this.email = this.user.email
    // if (this.user.is_admin) {
    //   this.is_admin = "Administrator"
    //   this.static_role = "Administrator"
    // } else {
    //   this.is_admin = "User"
    //   this.static_role = "User"
    // }
  },
}
</script>

<style lang="scss" scoped>
.input {
  @apply px-6 p-4 mt-4 mb-6 focus:outline-none font-normal w-full rounded-lg;

  caret-color: #6e6def;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.03);
  transition: box-shadow 0.25s linear;

  &:focus {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.09);
  }
}
</style>
