<template>
  <button
    class="flex justify-center items-center px-3 py-2 ml-2 bg-blue-500 hover:bg-blue-700 rounded-3xl transition"
    :class="{'transform rotate-180': (sidebarMenuMode == 'expand')}"
    type="submit"
    @click="$store.commit('structure/SET_EXPAND_COLLAPSE_SIDEBAR_MENU')"
  >
    <font-awesome-icon
      class="text-white"
      :icon="['fa', 'chevron-down']"
    />
  </button>
</template>

<script>
export default {
  name: 'ExpandCollapseButton',

  computed: {
    sidebarMenuMode() {
      return this.$store.state.structure.sidebarMenuMode;
    },
  }
}
</script>


