<template>
  <div class="w-full">

    <!-- Systems Header -->
    <div class="h-24 2xl:h-[74px] flex-grow flex flex-col justify-between items-center bg-bgLight border border-gray-600 sticky top-0 z-10">
      <div class="flex justify-center items-center h-full w-full px-1 py-1">
        <span class="text-sm text-center">Cyber Health</span>
      </div>
    </div>
    <!-- End Systems Header -->

    <!-- Column Container -->
    <div class="grid grid-cols-1 gap-1">

      <!-- Endpoint Security Column -->
      <div class="w-full">
        <div
            v-for="(org, i) in flatStructure"
            :key="i"
            class="heatmap-item"
            :class="{
						'border border-gray-200': (i == hoveredRow)
          }"
            :style="`background-color: hsl(${getHeatmapColor(org)[colorMode].h}, ${getHeatmapColor(org)[colorMode].s}%, ${getHeatmapColor(org)[colorMode].l}%)`"
            @mouseover="$emit('onRowHover', i)"
            @mouseleave="$emit('onRowHover', null)"
            @click="heatmapItemClicked(i, getHeatmapColor(org), org)"
            v-show="showRow(org)"
        >
          <p>
            <span>{{ org.scoreInfo.overallScore }}%</span>
          </p>
        </div>
      </div>
      <!-- End Endpoint Security Column -->

    </div>
    <!-- End Column Container -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      // colorList: [ // RGB colors
      // 	{normal: '205, 0, 0', blind: '73, 0, 146'}, // RED INNIT
      // 	{normal: '224, 48, 0', blind: '109, 36, 170'}, // NEARLY RED
      // 	{normal: '243, 95, 0', blind: '146, 73, 195'}, // TANGERINE MAYBE
      // 	{normal: '255, 117, 0', blind: '182, 109, 219'}, // STILL BASICALLY ORANGE
      // 	{normal: '255, 153, 0', blind: '158, 133, 231'}, // DEFINITELY ORANGE
      // 	{normal: '255, 202, 0', blind: '133, 158, 243'}, // NEARLY ORANGE
      // 	{normal: '255, 255, 0', blind: '109, 182, 255'}, // YELLOW
      // 	{normal: '218, 231, 0', blind: '133, 194, 255'}, // NEARLY YELLOW
      // 	{normal: '156, 216, 0', blind: '158, 207, 255'}, // SLIGHTLY LESS GREEN
      // 	{normal: '26, 188, 0', blind: '182, 219, 255'} // GREEN
      // ],
      colorList: [ // HSL colors
        {normal: '0, 100, 40.2', blind: '270, 100, 28.6'}, // RED INNIT
        {normal: '13, 100, 43.9', blind: '273, 65, 40.4'}, // NEARLY RED
        {normal: '23, 100, 47.6', blind: '276, 50.4, 50.2'}, // TANGERINE MAYBE
        {normal: '28, 100, 50', blind: '280, 60.4, 64.3'}, // STILL BASICALLY ORANGE
        {normal: '36, 100, 50', blind: '255, 67.1, 71.4'}, // DEFINITELY ORANGE
        {normal: '48, 100, 50', blind: '226, 82.1, 73.7'}, // NEARLY ORANGE
        {normal: '60, 100, 50', blind: '210, 100, 71.4'}, // YELLOW
        {normal: '63, 100, 45.3', blind: '210, 100, 76.1'}, // NEARLY YELLOW
        {normal: '77, 100, 42.4', blind: '210, 100, 81'}, // SLIGHTLY LESS GREEN
        {normal: '112, 100, 36.9', blind: '210, 100, 85.7'} // GREEN
      ],
    }
  },
  props: {
    flatStructure: {
      type: Array,
      required: true,
    },
    hoveredRow: {
      type: [Number, null],
      default: null,
    },
    clickedRow: {
      type: [Number, null],
      default: null,
    }
  },
  computed: {
    colorMode() {
      return this.$store.state.ui.colorblindMode ? 'blind' : 'normal';
    },
    heatmapColorsValues() {
      return this.$store.state.heatmap.heatmapColorsValues;
    },
    isAggregateMode() {
      return this.$store.state.structure.isAggregateMode;
    },
    isCyberHealthMode() {
      return this.$store.state.structure.isCyberHealthMode;
    },
    isShowPortfolioOnly() {
      return this.$store.state.structure.isShowPortfolioOnly;
    },
    heatmapAggregate() {
      return this.$store.state.heatmapAggregate.heatmapAggregate;
    }
  },
  methods: {
    heatmapItemClicked(i, color, org) {
      this.$emit('onRowClick', i)

      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'aggregate',
        systemName: 'Aggregate',
        index: i,
        color: color,
        org: org
      })

      this.$vfm.show('security-summary-modal');
    },
    getHeatmapColor(org) {
      let overallScore = org.scoreInfo.overallScore;

      let startColor = {
        normal: '0, 100, 40.2'.split(', '),
        blind: '270, 100, 28.6'.split(', ')
      }

      let endColor = {
        normal: '112, 100, 36.9'.split(', '),
        blind: '210, 100, 85.7'.split(', ')
      }

      let hStart = {normal: +startColor.normal[0], blind: +startColor.blind[0]}
      let sStart = {normal: +startColor.normal[1], blind: +startColor.blind[1]}
      let lStart = {normal: +startColor.normal[2], blind: +startColor.blind[2]}

      let hEnd = {normal: +endColor.normal[0], blind: +endColor.blind[0]}
      let sEnd = {normal: +endColor.normal[1], blind: +endColor.blind[1]}
      let lEnd = {normal: +endColor.normal[2], blind: +endColor.blind[2]}

      let progress = +((overallScore / 100).toFixed(2));

      return {
        normal: {
          h: hStart.normal + (hEnd.normal - hStart.normal) * progress,
          s: sStart.normal + (sEnd.normal - sStart.normal) * progress,
          l: lStart.normal + (lEnd.normal - lStart.normal) * progress
        },
        blind: {
          h: hStart.blind + (hEnd.blind - hStart.blind) * progress,
          s: sStart.blind + (sEnd.blind - sStart.blind) * progress,
          l: lStart.blind + (lEnd.blind - lStart.blind) * progress
        }
      };
    },
    showRow(org) {
			if (this.isShowPortfolioOnly) {
				return ['Portfolio', 'Sub-Portfolio', 'Group'].includes(org.org_type)
			} else {
				return !['Portfolio', 'Sub-Portfolio', 'Group'].includes(org.org_type) || ['Volaris Consolidated', 'Lumine Group'].includes(org.name)
			}
		},
  },
  watch: {
    clickedRow(clickedRowIndex) {
      this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
        system: 'aggregate',
        systemName: 'Aggregate',
        index: clickedRowIndex,
        color: this.getHeatmapColor(this.flatStructure[clickedRowIndex]),
        org: this.flatStructure[clickedRowIndex]
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>
