<template>
	<router-link class="card flex flex-col" :class="{ 'cursor-auto': !orgData?.has_data }"
		:to="orgData?.has_data ? `/security_score_card/${org.org_id}` : '#'" @click="isClicked = true">
		<div class="card--title flex justify-between h-12 items-center bg-bgLighter border border-gray-700 px-6 py-3 text-gray-300 rounded-tl-lg rounded-tr-lg"
			:class="this.org && 'clickable'">
			<div>
				<span>Third Party Cyber Risk Management</span>
				<BaseTooltip class="relative ml-2 inline-block" :info="'Third Party Cyber Risk Management description'">
					<font-awesome-icon class="text-gray-500" :icon="['fa', 'info-circle']" />
				</BaseTooltip>
			</div>
			<div class="flex items-center">
				<img src="@/assets/securityscorecard.png" class="h-6 mr-3 inline-block" />
				<div class="not-clickable-warning text-primary text-sm mr-3" v-if="!orgData?.has_data && isClicked">
					<font-awesome-icon class="text-primary mr-1" :icon="['fa', 'info-circle']" />
					<span>No click-through data</span>
				</div>
				<font-awesome-icon v-if="orgData?.has_data"
					class="text-gray-700 text-xl transform -rotate-45 icon transition-colors"
					:icon="['fa', 'arrow-right']" />
			</div>
		</div>
		<div class="w-full h-full flex items-center border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
			v-if="isAggregateMode || (orgData?.has_data && !isAggregateMode)">

			<div class="flex flex-col items-center px-3 py-4 w-full">
				<div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
					<div class="mr-4">
						<div class="grade w-[64px] h-[64px]  flex justify-center items-center text-2xl font-bold"
							:style="{ background: `rgb(${hexagonInfo.color})` }">{{
								hexagonInfo.text }}
						</div>
					</div>
					<div class="right-info flex flex-col items-center">
						<h2 class="text-4xl">{{ Math.round(orgDataAggregate.vbu_score_this_month) }}</h2>
						<div class="flex items">
							<span class="change-value">{{ +(Math.abs(orgDataAggregate?.vbu_score_last_month -
								orgDataAggregate.vbu_score_this_month).toFixed(2)) || 0 }}</span>
							<font-awesome-icon
								v-if="Math.abs(orgDataAggregate?.vbu_score_last_month - orgDataAggregate.vbu_score_this_month) !== 0"
								class="ml-2 text-xl" :class="
									(orgDataAggregate?.vbu_score_this_month || 0) >
										(orgDataAggregate?.vbu_score_last_month || 0)
										? 'text-green-500' :
										'text-red-500'
								" :icon="[
	'fa',
	`arrow-alt-circle-${(orgDataAggregate.vbu_score_this_month || 0) >
		(orgDataAggregate?.vbu_score_last_month || 0)
		? 'up' :
		'down'
	}`,
]" />
						</div>
					</div>
				</div>
				<div class="flex items-center" v-else>
					<div class=" mr-4">
						<div class="grade w-[64px] h-[64px]  flex justify-center items-center text-2xl font-bold"
							:style="{ background: `rgb(${hexagonInfo.color})` }">{{
								hexagonInfo.text }}
						</div>
					</div>
					<div class="right-info flex flex-col items-center">
						<h2 class="text-4xl">{{ orgData?.vbu_score_this_month }}</h2>
						<div class="flex items">
							<font-awesome-icon
								v-if="Math.abs(orgData?.vbu_score_last_month - orgData?.vbu_score_this_month) !== 0"
								class="mr-2 text-xl" :class="
									(orgData?.vbu_score_this_month || 0) >
										(orgData?.vbu_score_last_month || 0)
										? 'text-green-500' :
										'text-red-500'
								" :icon="[
	'fa',
	`arrow-alt-circle-${(orgData?.vbu_score_this_month || 0) >
		(orgData?.vbu_score_last_month || 0)
		? 'up' :
		'down'
	}`,
]" />
							<span class="change-value">{{ Math.abs(orgData?.vbu_score_last_month -
								orgData?.vbu_score_this_month) }}</span>
						</div>
					</div>
				</div>
				<p class="text-sm text-gray-400 text-center">overall grade</p>
			</div>

			<div class="self-stretch border-l border-gray-700"></div>

			<!-- patching cadence -->
			<div class="flex flex-col items-center px-3 py-4 w-full">
				<div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
					<font-awesome-icon
						v-if="(orgDataAggregate?.patching_score_this_month == 100) && (orgDataAggregate?.patching_score_last_month == 100)"
						class="mr-2 text-xl text-green-500" :icon="['fa', 'check-circle']" />
					<font-awesome-icon v-else class="mr-2 text-xl" :class="
						(orgDataAggregate.patching_score_this_month || 0) >
							(orgDataAggregate.patching_score_last_month || 0)
							? 'text-green-500' :
							'text-red-500'
					" :icon="[
	'fa',
	`arrow-alt-circle-${(orgDataAggregate.patching_score_this_month || 0) >
		(orgDataAggregate.patching_score_last_month || 0)
		? 'up' :
		'down'
	}`,
]" />
					<h2 class="text-4xl">{{ Math.round(orgDataAggregate.patching_score_this_month) }}</h2>
				</div>
				<div class="flex items-center" v-else>
					<font-awesome-icon
						v-if="(orgData?.patching_score_this_month == 100) && (orgData?.patching_score_last_month == 100)"
						class="mr-2 text-xl text-green-500" :icon="['fa', 'check-circle']" />
					<font-awesome-icon v-else class="mr-2 text-xl" :class="
						(orgData?.patching_score_this_month || 0) >
							(orgData?.patching_score_last_month || 0)
							? 'text-green-500' :
							'text-red-500'
					" :icon="[
	'fa',
	`arrow-alt-circle-${(orgData?.patching_score_this_month || 0) >
		(orgData?.patching_score_last_month || 0)
		? 'up' :
		'down'
	}`,
]" />
					<h2 class="text-4xl">{{ orgData?.patching_score_this_month }}</h2>
				</div>
				<p class="text-sm text-gray-400 text-center">patching cadence</p>
			</div>
			<!-- end patching cadence -->

			<div class="self-stretch border-l border-gray-700"></div>

			<!-- network security -->
			<div class="flex flex-col items-center px-3 py-4 w-full">
				<div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
					<font-awesome-icon
						v-if="(orgDataAggregate?.network_score_this_month == 100) && (orgDataAggregate?.network_score_last_month == 100)"
						class="mr-2 text-xl text-green-500" :icon="['fa', 'check-circle']" />
					<font-awesome-icon v-else class="mr-2 text-xl" :class="
						(orgDataAggregate.network_score_this_month || 0) >
							(orgDataAggregate.network_score_last_month || 0)
							? 'text-green-500' :
							'text-red-500'
					" :icon="[
	'fa',
	`arrow-alt-circle-${(orgDataAggregate.network_score_this_month || 0) >
		(orgDataAggregate.network_score_last_month || 0)
		? 'up' :
		'down'
	}`,
]" />
					<h2 class="text-4xl">{{ Math.round(orgDataAggregate.network_score_this_month) }}</h2>
				</div>
				<div class="flex items-center" v-else>
					<font-awesome-icon
						v-if="(orgData?.network_score_this_month == 100) && (orgData?.network_score_last_month == 100)"
						class="mr-2 text-xl text-green-500" :icon="['fa', 'check-circle']" />
					<font-awesome-icon v-else class="mr-2 text-xl" :class="
						(orgData?.network_score_this_month || 0) >
							(orgData?.network_score_last_month || 0)
							? 'text-green-500' :
							'text-red-500'
					" :icon="[
	'fa',
	`arrow-alt-circle-${(orgData?.network_score_this_month || 0) >
		(orgData?.network_score_last_month || 0)
		? 'up' :
		'down'
	}`,
]" />
					<h2 class="text-4xl">{{ orgData?.network_score_this_month }}</h2>
				</div>
				<p class="text-sm text-gray-400 text-center">network security</p>
			</div>
			<!-- end network security -->

			<div class="self-stretch border-l border-gray-700"></div>

			<div class="flex flex-col items-center px-3 py-4 w-full">
				<div class="flex items-center" v-if="isAggregateMode && org.org_type != 'VBU'">
					<font-awesome-icon class="mr-2 text-xl" :class="
						(orgDataAggregate.application_score_this_month || 0) >
							(orgDataAggregate.application_score_last_month || 0)
							? 'text-green-500' :
							'text-red-500'
					" :icon="[
	'fa',
	`arrow-alt-circle-${(orgDataAggregate.application_score_this_month || 0) >
		(orgDataAggregate.application_score_last_month || 0)
		? 'up' :
		'down'
	}`,
]" />
					<h2 class="text-4xl">{{ Math.round(orgDataAggregate.application_score_this_month) }}</h2>
				</div>
				<div class="flex items-center" v-else>
					<font-awesome-icon class="mr-2 text-xl" :class="
						(orgData?.application_score_this_month || 0) >
							(orgData?.application_score_last_month || 0)
							? 'text-green-500' :
							'text-red-500'
					" :icon="[
	'fa',
	`arrow-alt-circle-${(orgData?.application_score_this_month || 0) >
		(orgData?.application_score_last_month || 0)
		? 'up' :
		'down'
	}`,
]" />
					<h2 class="text-4xl">{{ orgData?.application_score_this_month }}</h2>
				</div>
				<p class="text-sm text-gray-400 text-center">application security</p>
			</div>

		</div>
		<div class="flex items-center justify-center h-full border border-gray-700 border-t-0 rounded-bl-lg rounded-br-lg"
			v-else>
			<p class="py-8 opacity-50">
				No Security Scorecard information found.
			</p>
		</div>
	</router-link>
</template>

<script>
import BaseTooltip from '@/components/Base/BaseTooltip';

export default {
	components: {
		BaseTooltip,
	},
	data() {
		return {
			isClicked: false,
			hexagonColorsRange: {
				green: { min: 0, max: 20 },
				yellow: { min: 20, max: 40 },
				orange: { min: 40, max: 60 },
				darkorange: { min: 60, max: 80 },
				red: { min: 80, max: null }
			}
		}
	},
	props: {
		org: {
			type: Object,
			required: true,
		},
		orgData: {
			type: Object,
			required: true,
		}
	},
	computed: {
		isAggregateMode() {
			return this.$store.state.structure.isAggregateMode;
		},
		orgDataAggregate() {
			let VBUs = this.org.VBUs;
			let widgetsData = this.$store.state.widgets.widgetsData;

			if (VBUs && VBUs.length) {
				let dataArr = [];
				VBUs.forEach((VBUItem) => {
					widgetsData?.forEach((orgItem) => {
						if (VBUItem.org_id === +orgItem.org_id) {
							dataArr.push({
								vbu_score_this_month: orgItem.data.securityscorecard.vbu_score_this_month || 0,
								vbu_score_last_month: orgItem.data.securityscorecard.vbu_score_last_month || 0,
								patching_score_this_month: orgItem.data.securityscorecard.patching_score_this_month || 0,
								patching_score_last_month: orgItem.data.securityscorecard.patching_score_last_month || 0,
								network_score_this_month: orgItem.data.securityscorecard.network_score_this_month || 0,
								network_score_last_month: orgItem.data.securityscorecard.network_score_last_month || 0,
								application_score_this_month: orgItem.data.securityscorecard.application_score_this_month || 0,
								application_score_last_month: orgItem.data.securityscorecard.application_score_last_month || 0,
							})
						}
					})
				})

				let sumVBUs = dataArr.reduce((sumObj, currentObj) => {
					return {
						vbu_score_this_month: (sumObj.vbu_score_this_month || 0) + (currentObj.vbu_score_this_month || 0),
						vbu_score_last_month: (sumObj.vbu_score_last_month || 0) + (currentObj.vbu_score_last_month || 0),
						patching_score_this_month: (sumObj.patching_score_this_month || 0) + (currentObj.patching_score_this_month || 0),
						patching_score_last_month: (sumObj.patching_score_last_month || 0) + (currentObj.patching_score_last_month || 0),
						network_score_this_month: (sumObj.network_score_this_month || 0) + (currentObj.network_score_this_month || 0),
						network_score_last_month: (sumObj.network_score_last_month || 0) + (currentObj.network_score_last_month || 0),
						application_score_this_month: (sumObj.application_score_this_month || 0) + (currentObj.application_score_this_month || 0),
						application_score_last_month: (sumObj.application_score_last_month || 0) + (currentObj.application_score_last_month || 0),
					}
				}, 0)

				return {
					vbu_score_this_month: Number.isInteger(sumVBUs.vbu_score_this_month / dataArr.length) ?
						(sumVBUs.vbu_score_this_month / dataArr.length) : (sumVBUs.vbu_score_this_month / dataArr.length).toFixed(2),
					vbu_score_last_month: Number.isInteger(sumVBUs.vbu_score_last_month / dataArr.length) ?
						(sumVBUs.vbu_score_last_month / dataArr.length) : (sumVBUs.vbu_score_last_month / dataArr.length).toFixed(2),
					patching_score_this_month: Number.isInteger(sumVBUs.patching_score_this_month / dataArr.length) ?
						(sumVBUs.patching_score_this_month / dataArr.length) : (sumVBUs.patching_score_this_month / dataArr.length).toFixed(2),
					patching_score_last_month: Number.isInteger(sumVBUs.patching_score_last_month / dataArr.length) ?
						(sumVBUs.patching_score_last_month / dataArr.length) : (sumVBUs.patching_score_last_month / dataArr.length).toFixed(2),
					network_score_this_month: Number.isInteger(sumVBUs.network_score_this_month / dataArr.length) ?
						(sumVBUs.network_score_this_month / dataArr.length) : (sumVBUs.network_score_this_month / dataArr.length).toFixed(2),
					network_score_last_month: Number.isInteger(sumVBUs.network_score_last_month / dataArr.length) ?
						(sumVBUs.network_score_last_month / dataArr.length) : (sumVBUs.network_score_last_month / dataArr.length).toFixed(2),
					application_score_this_month: Number.isInteger(sumVBUs.application_score_this_month / dataArr.length) ?
						(sumVBUs.application_score_this_month / dataArr.length) : (sumVBUs.application_score_this_month / dataArr.length).toFixed(2),
					application_score_last_month: Number.isInteger(sumVBUs.application_score_last_month / dataArr.length) ?
						(sumVBUs.application_score_last_month / dataArr.length) : (sumVBUs.application_score_last_month / dataArr.length).toFixed(2),
				};
			} else {
				return {}
			}
		},
		hexagonInfo() {
			let vbu = this.orgData?.vbu_score_this_month;
			return this.calculateHexagonInfo(vbu);
		},
		hexagonInfoAggregate() {
			let vbu = this.orgDataAggregate.vbu_score_this_month;
			return this.calculateHexagonInfo(vbu);
		}
	},
	methods: {
		calculateHexagonInfo(vbu) {
			switch (true) {
				case (vbu < 60):
					return { text: "F", color: "239, 68, 68" };
				case (vbu >= 60 && vbu < 70):
					return { text: "D", color: "248, 108, 73" };
				case (vbu >= 70 && vbu < 80):
					return { text: "C", color: "245, 158, 11" };
				case (vbu >= 80 && vbu < 90):
					return { text: "B", color: "251, 191, 36" };
				default:
					return { text: "A", color: "16, 185, 129" };
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.card {
	&--title {
		@apply transition-colors duration-300 relative;

		h4 {
			@apply relative pl-2;

			&::before {
				@apply w-2 h-2 rounded-full bg-white absolute top-2 -left-2 opacity-20;

				content: '';
			}

			// border-bottom: 1px rgba(255, 255, 255, 0.467) dashed;

			&.group::before {
				@apply opacity-100;
				background-color: rgb(90, 192, 30);
			}

			&.portfolio::before {
				@apply opacity-100 bg-blue-500;
			}
		}
	}

	&:hover {
		.card--title {
			background-color: rgba(52, 52, 62, 1);

			.icon {
				@apply text-gray-400;
			}
		}
	}

	.grade {
		text-shadow: rgb(0 0 0 / 50%) 1px 0px 1px,
			rgb(0 0 0 / 50%) -1px 0px 1px,
			rgb(0 0 0 / 50%) 0px -1px 1px,
			rgb(0 0 0 / 50%) 0px 1px 1px;
		-webkit-mask-image: url(../assets/hexagon.svg);
		mask-image: url(../assets/hexagon.svg);
		background: url(../assets/hexagon.svg);
	}

}
</style>
