<template>
  <div>
    <BackButton />

    <div class="flex justify-between">
      <div>
        <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">
            Crowdstrike detections over the preceding 6 months
          </p>
      </div>
      <div class="text-center my-2">
        <img src="@/assets/crowdstrike_logo.svg" class="h-8 inline-block" />
      </div>
    </div>

    <div v-if="!isLoading">
      <CrowdstrikeLastMonthsChart :chartData="chartData" />
	    <CrowdstrikeEscalationsTable :escalations="escalations" :org-name="org.name" />
    </div>
    <div class="w-10 h-10 mx-auto my-4" v-else>
      <SpinnerBase />
    </div>
  </div>
</template>

<script>
import CrowdstrikeLastMonthsChart from '@/components/CrowdstrikeLastMonthsChart';
import SpinnerBase from '@/components/Base/SpinnerBase';
import BackButton from '@/components/Base/BackButton';
import CrowdstrikeEscalationsTable from "@/components/CrowdstrikeEscalationsTable";

export default {
  components: {
    BackButton,
    CrowdstrikeLastMonthsChart,
	  CrowdstrikeEscalationsTable,
    SpinnerBase,
  },
  data() {
    return {
      isLoading: true,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Low',
            data: [],
            backgroundColor: 'rgba(59, 130, 246, 0.7)',
            // borderWidth: 1,
            // borderColor: 'rgba(59, 130, 246, 1)',
          },
          {
            label: 'Medium',
            data: [],
            backgroundColor: 'rgba(139, 92, 246, 0.7)',
            // borderWidth: 1,
            // borderColor: 'rgba(139, 92, 246, 1)',
          },
          {
            label: 'High',
            data: [],
            backgroundColor: 'rgba(245, 158, 11, 0.7)',
            // borderWidth: 1,
            // borderColor: 'rgba(245, 158, 11, 1)',
          },
          {
            label: 'Critical',
            data: [],
            backgroundColor: 'rgba(239, 68, 68, 0.57)',
            // borderWidth: 1,
            // borderColor: 'rgba(239, 68, 68, 1)',
          },
        ],
      },
	    escalations: []
    };
  },
  async mounted() {
		// get data and populate
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "crowdstrike"
		});

    // set table data
	  this.escalations = this.$store.state.systemsDetails.systemsDetailsData.data[0].escalations;
		
		// set labels
		this.months.forEach((month) => {
			this.chartData.labels.push(new Date(month).toLocaleString('en-GB', {month: 'long'}).toUpperCase());
		})
		
		// set data
		const data = this.$store.state.systemsDetails.systemsDetailsData.data[0].chart_data;
		const months = this.months;
		
		months.forEach((month) => {
			let selectedMonthData = {};
			data.forEach((dataItem) => {
				let dataItemDate = `${dataItem.month}`; // YYYY-MM date format
				if (month === dataItemDate) {
					selectedMonthData = dataItem;
				}
			})
      this.chartData.datasets[0].data.push(selectedMonthData?.low || 0);
	    this.chartData.datasets[1].data.push(selectedMonthData?.medium || 0);
	    this.chartData.datasets[2].data.push(selectedMonthData?.high || 0);
	    this.chartData.datasets[3].data.push(selectedMonthData?.critical || 0);
		});
		
		this.isLoading = false;
	},
	computed: {
    months() {
			var today = new Date();
			var d;
			var month;
			var newArr = [];
			for (var i = 5; i > 0; i -= 1) {
				d = new Date(today.getFullYear(), today.getMonth() - i, 1);
				month = d.toLocaleString('en-GB', {month: 'long'}).toUpperCase();
		
				newArr.push(`${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? `0${(d.getMonth() + 1)}` : (d.getMonth() + 1)}`);
			}
			newArr.push(`${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1)}`);
			return newArr;
		},
    orgId() {
      return this.$route.params.id;
    },
    org() {
      return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
    },
  }
}

</script>

<style lang="scss" scoped></style>
