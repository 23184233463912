import { apolloClient as apollo } from "@/apollo";

const defaultStoreState = () => ({
  widgetsData: [],
});

const state = defaultStoreState();

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultStoreState());
  },
  SET_WIDGETS_DATA: (state, { widgetsData }) => {
    state.widgetsData = widgetsData;
  },
};

const actions = {
  getWidgetsData({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { dash_two_dashboard },
        } = await apollo.query({
          query: require("@/graphql/get_widgets_data.gql"),
        });

        // console.log("dash_two_dashboard", dash_two_dashboard)

        commit("SET_WIDGETS_DATA", { widgetsData: dash_two_dashboard });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
