<template>
	<div class="relative py-4" v-if="veryAttackedPeople">
		<div class="flex justify-between items-end">
			<div>
				<h4 class="text-lg mr-auto"> Very Attacked People </h4>
				<p class="mb-1">
					Details of Very Attacked People from the previous 90 days
				</p>
			</div>
			<export-excel
				class="rounded-lg transition bg-green-600 hover:bg-green-700 transition cursor-pointer px-3 py-2"
				:data="excelInfo.dataList"
				:fields="excelInfo.titleList"
				type="csv"
				:name="`${orgName}-very-attacked-people.csv`"
				worksheet="Proofpoint Very Attacked People"
				v-if="isExcelActive"
			>
				<font-awesome-icon
					:icon="['fa', 'file-excel']"
					class="mr-1"
				/>
				Export
			</export-excel>
		</div>
		<div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
		<table class="w-full relative rounded-lg overflow-hidden text-left">
			<thead>
				<tr>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-200 uppercase bg-gray-700 z-20 sticky top-0 cursor-pointer hover:bg-gray-600 transition"
						:class="{'pl-5': (index === 0)}"
						@click="order(tableTitleItem.slug)"
						v-for="(tableTitleItem, index) in tableTitleList"
						:key="index"
					>
						<div class="flex items-center justify-between">
							<span style="user-select: none;">{{ tableTitleItem.title }}</span>
							<div class="flex flex-col ml-3">
								<font-awesome-icon
									class="text-white"
									:icon="['fa', 'chevron-up']"
									v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'ASC')"
								/>
								<font-awesome-icon
									class="text-white"
									:icon="['fa', 'chevron-down']"
									v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'DESC')"
								/>
							</div>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, index) in veryAttackedPeopleCopy"
					:key="index"
					class="bg-gray-800 hover:bg-gray-600 transition duration-300"
				>
					<th
						scope="col"
						class="px-2 pl-5 py-3 text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
					>
						<div class="flex items-center">{{ item.email }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
					>
						<div class="flex items-center">{{ item.title }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
					>
						<div class="flex items-center">{{ item.vip }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-xs font-medium text-gray-300 uppercase z-20 sticky top-0"
					>
						<div class="flex items-center">{{ item.threat_categories }}</div>
					</th>
				</tr>
			</tbody>
		</table>
	</div>
	<div v-else>
		<h2 class="text-xl text-gray-300">No very attacked people</h2>
	</div>
</template>

<script>

export default {
	props: {
		veryAttackedPeople: {
			type: Object,
		},
		orgName: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			orderBy: {
				column: '',
				type: ''
			},
			veryAttackedPeopleCopy: [],
			tableTitleList: [
				{title: "Email", slug: "email"},
				{title: "Title", slug: "title"},
				{title: "VIP?", slug: "vip"},
				{title: "Associated Threat Categories", slug: "threat_categories"},
			],
			excelInfo: {
				titleList: {
					"Email": "email",
					"Title": "title",
					"VIP?": "vip",
					"Associated Threat Categories": "threat_categories",
				},
				dataList: []
			},
			isExcelActive: true
		}
	},
	created() {
		this.veryAttackedPeopleCopy = [...this.veryAttackedPeople];
		this.setExcelData();
	},
	methods: {
		order(column) {
			if (this.orderBy.column === column) {
				this.orderBy.type = (this.orderBy.type === "DESC") ? "ASC" : "DESC";
			} else {
				this.orderBy.type = "ASC";
			}
			
			this.orderBy.column = column;
			
			this.veryAttackedPeopleCopy.sort((a, b) => {
				let aItem = a[this.orderBy.column];
				let bItem = b[this.orderBy.column];
				
				if (this.orderBy.type === "ASC") {
					return (aItem > bItem) ? 1 : (aItem < bItem) ? -1 : 0;
				} else {
					return (aItem < bItem) ? 1 : (aItem > bItem) ? -1 : 0;
				}
			})
			
			this.setExcelData();
		},
		setExcelData() {
			this.excelInfo.dataList = this.veryAttackedPeopleCopy;
		}
	}
};
</script>

<style lang="scss" scoped></style>
