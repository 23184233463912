<template>
	<div class="w-full">
		
		<!-- Systems Header -->
		<HeatmapHeader
			:infoLeft="'Operational:\n\nPercentage of users with Two-Factor Authentication (2FA) enabled.\n\nAn employees email account is one of the most critical assets to keep secure, as they often hold the key to accessing any number of other systems.\n\n2FA adds another layer of protection to your accounts, and if your organisation has any less than 100% deployment then this metric will show as red and should be made a priority to get enabled on missing accounts which you can view in the dashboard page for the organisation.'"
			:infoRight="'Deployment:\n\nIf we haven\'t got any data regarding 2FA deployment for your organisation then this will show as grey and you will either need to employ a system which monitors the 2FA status of your employees emails and send us API details, or if a system is already in place, provide us with the relevant API details to gather the data.'"
		>
			<div class="flex justify-center items-center h-full w-full px-1">
				<span class="text-sm text-center">Multi-Factor Authentication</span>
			</div>
		</HeatmapHeader>
		<!-- End Systems Header -->
		
		<!-- Operational and Deployment Columns -->
		<div class="grid grid-cols-2 gap-1">
			
			<!-- Operational Column -->
			<div class="w-full">
				<div
					v-for="(org, i) in flatStructure"
					:key="i"
					class="heatmap-item"
					:class="{
						'border border-gray-200': getColorBaseOnAggregateMode(org) ? (i == hoveredRow && getColorBaseOnAggregateMode(org)?.operational.value != 'N/A') : null,
						'not-applicable': getColorBaseOnAggregateMode(org) ? getColorBaseOnAggregateMode(org)?.operational.value == 'N/A' : null
          }"
					:style="`background-color: rgba(${getColorBaseOnAggregateMode(org)?.operational.color[colorMode]}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
					@mouseover="$emit('onRowHover', i)"
					@mouseleave="$emit('onRowHover', null)"
					@click="heatmapItemClicked(i, getColorBaseOnAggregateMode(org), org, 'operational')"
					v-show="!isOrderByRankMode || (isOrderByRankMode && !isAllRowBlack(org))"
				>
					<p v-show="(showValue || i == hoveredRow) && getColorBaseOnAggregateMode(org)?.operational.value != 'N/A'">
						<span v-if="getColorBaseOnAggregateMode(org) && getColorBaseOnAggregateMode(org)?.operational.value !== 'notValid'">
							{{
								typeof getColorBaseOnAggregateMode(org)?.operational.value == "number" ?
									+(getColorBaseOnAggregateMode(org)?.operational.value.toFixed(1)) :
									getColorBaseOnAggregateMode(org)?.operational.value
							}}%
						</span>
						<span v-else>&#10071;</span>
					</p>
				</div>
			</div>
			<!-- End Operational Column -->
			
			<!-- Deployment Column -->
			<div class="w-full">
				<div
					v-for="(org, i) in flatStructure"
					:key="i"
					class="heatmap-item"
					:class="{
						'border border-gray-200': (i == hoveredRow && getColorBaseOnAggregateMode(org)?.deployment.value != 'N/A'),
						'not-applicable': getColorBaseOnAggregateMode(org)?.deployment.value == 'N/A'
          }"
					:style="`background-color: rgba(${getColorBaseOnAggregateMode(org)?.deployment.color[colorMode]}, ${
            i == hoveredRow ? '1' : '0.8'
          })`"
					@mouseover="$emit('onRowHover', i)"
					@mouseleave="$emit('onRowHover', null)"
					@click="heatmapItemClicked(i, getColorBaseOnAggregateMode(org), org, 'deployment')"
					v-show="!isOrderByRankMode || (isOrderByRankMode && !isAllRowBlack(org))"
				>
					<p v-show="(showValue || i == hoveredRow) && getColorBaseOnAggregateMode(org)?.deployment.value != 'N/A'">
							<span v-if="getColorBaseOnAggregateMode(org)?.deployment.value !== 'notValid'">
								{{getColorBaseOnAggregateMode(org)?.deployment.value}}
							</span>
						<span v-else>&#10071;</span>
					</p>
				</div>
			</div>
			<!-- End Deployment Column -->
		
		</div>
		<!-- End Operational and Deployment Columns -->
	
	</div>
</template>

<script>
import HeatmapHeader from '@/components/HeatmapHeader';

export default {
	components: {
		HeatmapHeader,
	},
	props: {
		flatStructure: {
			type: Array,
			required: true,
		},
		hoveredRow: {
			type: [Number, null],
			default: null,
		},
		clickedRow: {
			type: [Number, null],
			default: null,
		},
		showValue: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
    isOrderByRankMode() {
      return this.$store.state.structure.isOrderByRankMode;
    },
		colorMode() {
			return this.$store.state.ui.colorblindMode ? 'blind' : 'normal';
		},
		heatmapColorsValues() {
			return this.$store.state.heatmap.heatmapColorsValues;
		},
		isAggregateMode() {
			return this.$store.state.structure.isAggregateMode;
		},
		heatmapAggregate() {
			return this.$store.state.heatmapAggregate.heatmapAggregate;
		}
	},
	methods: {
		heatmapItemClicked(i, color, org, columnType) {
			this.$emit('onRowClick', i)
			
			this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
				system: 'azure',
				systemName: 'Azure',
				index: i,
				color: color,
				org: org
			})
			
			if (color[columnType].value != 'N/A') {
				this.$vfm.show('security-summary-modal');
			}
		},
		getHeatmapColorNormalMode(orgId) {
			if (!this.heatmapColorsValues[orgId]) return null
			return this.heatmapColorsValues[orgId].azure;
		},
		getHeatmapAggregateMode(orgId) {
			return this.heatmapAggregate[orgId]?.azure;
		},
		getColorBaseOnAggregateMode(org) {
			// if (this.isAggregateMode && org.org_type != "VBU")
			// 	return this.getHeatmapAggregateMode(org.org_id);
			// else
				return this.getHeatmapColorNormalMode(org.org_id);
		},
    isAllRowBlack(org) {
      if (['Portfolio', 'Sub-Portfolio', 'Group'].includes(org.org_type)) {
        if (!['Volaris Consolidated', 'Lumine Group'].includes(org.name)) {
          return true;
        }
      }

      return false;
    }
	},
	watch: {
		clickedRow(clickedRowIndex) {
			this.$store.commit('structure/SET_SECURITY_SUMMARY_MODAL_INFO', {
				system: 'azure',
				systemName: 'Azure',
				index: clickedRowIndex,
				color: this.getColorBaseOnAggregateMode(this.flatStructure[clickedRowIndex]),
				org: this.flatStructure[clickedRowIndex]
			})
		}
	}
};
</script>

<style lang="scss" scoped></style>
