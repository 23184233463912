<template>
  <div v-if="isDataPrepared">
    <BackButton />
    <div>
      <div class="flex justify-between">
        <div>
          <h4 class="text-lg mr-auto">{{ org.name }}</h4>
          <p class="mb-6">
            Number of critical and high and informational account compromises over the preceding 6 months
          </p>
        </div>
        <div class="text-center my-2">
          <img
            src="@/assets/SpyCloud-logotype-white.png"
            class="h-8 inline-block"
          />
        </div>
      </div>

      <div v-if="!breaches?.length" class="bg-bgLight py-8 text-center rounded-md">
        <p>There's no emails coupled with this organisation.</p>
      </div>

      <div class="relative py-4" v-else>
        <div
          v-if="!chartData?.labels?.length"
          class="bg-green-900 flex items-center justify-center inset-0 blur-drop text-center rounded-md absolute z-10"
        >
          <p>No detections found.</p>
        </div>
        <SpyCloudChart :chartData="chartData" />
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerBase from '@/components/Base/SpinnerBase';
import SpyCloudChart from '@/components/SpyCloudChart';
import {} from '@/utils/time';
import BackButton from '@/components/Base/BackButton';


export default {
	data() {
		return {
			isDataPrepared: false
		}
	},
  components: {
    BackButton,
    SpinnerBase,
    SpyCloudChart,
  },
	async created() {
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "spycloud"
		});
		
		this.isDataPrepared = true;
	},
  computed: {
    orgId() {
      return this.$route.params.id;
    },
    org() {
      return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
    },
    breaches() {
	    return this.$store.state.systemsDetails.systemsDetailsData.data[0]?.chart_data || [];
    },
    chartData() {
      let monthsArray = Array.from({ length: 6 }, (_, i) => {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() - i, 1);
      }).reverse();

      const data = {
        labels: [],
        datasets: [
          {
            data: [],
            fill: true,
            label: 'Critical',
            backgroundColor: 'rgba(239, 68, 68, 0.57)',
            //borderColor: 'rgb(185, 28, 28)'
          },
          {
            data: [],
            fill: true,
            label: 'High',
            backgroundColor: 'rgba(245, 158, 11, 0.7)',
            //borderColor: 'rgb(194, 65, 12)'
          },
          {
            data: [],
            fill: true,
            label: 'Informational',
            backgroundColor: 'rgba(59, 130, 246, 0.7)',
            //borderColor: 'rgb(194, 65, 12)'
          },
        ],
      };
	    
      monthsArray.forEach((month) => {
        data.labels.push(
          month.toLocaleString('en-GB', { month: 'long' }).toUpperCase()
        );
				
				let filteredData = this.breaches.find((breach) => {
					return (
						new Date(breach.month).getMonth() === month.getMonth() &&
						new Date(breach.month).getFullYear() === month.getFullYear()
					);
				});
				
        data.datasets[0].data.push(filteredData?.critical || 0);
        data.datasets[1].data.push(filteredData?.high || 0);
        data.datasets[2].data.push(filteredData?.informational || 0);
      });

      return data;
    },
  }
};
</script>

<style lang="scss" scoped>
.blur-drop {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}
</style>
