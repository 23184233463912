<template>
  <div>
    <transition name="reveal">
      <div
        v-if="show"
        class="
          fixed
          inset-0
          flex
          items-center
          justify-center
          px-8
          overflow-auto
          modal-wrapper
        "
      >
        <div
          class="
            modal
            inline-block
            align-bottom
            bg-gray-700
            rounded-lg
            px-4
            pt-5
            pb-4
            text-left
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:p-6
            overflow-show
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <slot />
        </div>
        <div
          class="backdrop fixed inset-0"
          @click.self="$emit('hide', $event)"
        ></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  z-index: 999;
}

.modal {
  max-height: 90vh;
  max-width: 90vw;
}

.backdrop {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: -1;
}

.reveal-enter-active {
  animation: fadeIn 0.4s ease-in-out forwards;

  .modal {
    animation: fadeInUp 0.4s ease-in-out backwards;
  }

  .backdrop {
    animation: fadeIn 0.4s ease-in-out forwards;
  }
}

.reveal-leave-active {
  animation: fadeOut 0.4s ease-in-out forwards;

  .modal {
    animation: fadeOutDown 0.4s ease-in-out forwards;
  }

  .backdrop {
    animation: fadeOut 0.4s ease-in-out forwards;
  }
}
</style>