import { apolloClient as apollo } from "@/apollo";
import {
  getColor,
  coloursScale,
  getSpycloudDeploymentColor,
  getSixClicksDeploymentColor,
  getScorecardOperationalColor,
} from "@/utils/colors";
import structure from "@/store/modules/structure";

const defaultStoreState = () => ({
  heatmapAggregate: {},
});

const state = defaultStoreState();

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultStoreState());
  },
  // Set Aggregate for Systems
  SET_CROWDSTRIKE_AGGREGATE(state, { org, VBUsColorsValues, settings }) {
    let system = state.heatmapAggregate[org.org_id].crowdstrike;

    // calculate operational and deployment value aggregate
    let sumOfOperationalValue;
    let sumOfOperationalColorBase;
    let sumOfDeploymentValue;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.crowdstrike.operational.value
        )
      ) {
        sumOfOperationalValue =
          (sumOfOperationalValue || 0) +
          (+colorsValuesItem.crowdstrike.operational.value || 0);
      }
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.crowdstrike.operational.colorBase
        )
      ) {
        sumOfOperationalColorBase =
          (sumOfOperationalColorBase || 0) +
          (+colorsValuesItem.crowdstrike.operational.colorBase || 0);
      }
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.crowdstrike.deployment.value
        )
      ) {
        sumOfDeploymentValue =
          (sumOfDeploymentValue || 0) +
          (+colorsValuesItem.crowdstrike.deployment.value || 0);
      }
    });

    if (sumOfOperationalValue === undefined) {
      // undefined means none of the VBU values are summable values
      system.operational.value =
        VBUsColorsValues[0].crowdstrike.operational.value;
    } else {
      system.operational.value =
        sumOfOperationalValue / (VBUsColorsValues?.length || 0);
    }

    if (sumOfOperationalColorBase === undefined) {
      // undefined means none of the VBU values are summable values
      system.operational.colorBase =
        VBUsColorsValues[0].crowdstrike.operational.colorBase;
    } else {
      system.operational.colorBase =
        sumOfOperationalColorBase / (VBUsColorsValues?.length || 0);
    }

    if (sumOfDeploymentValue === undefined) {
      // undefined means none of the VBU values are summable values
      system.deployment.value =
        VBUsColorsValues[0].crowdstrike.deployment.value;
    } else {
      system.deployment.value =
        sumOfDeploymentValue / (VBUsColorsValues?.length || 0);
    }

    // calculate color aggregate
    let isOperationalNotAcceptableValue = [NaN, "notValid", "N/A"].includes(
      system.operational.value
    );
    system.operational.color = isOperationalNotAcceptableValue
      ? VBUsColorsValues[0].crowdstrike.operational.color
      : getColor(
          settings.crowdstrike_operational,
          system.operational.colorBase
        );

    let isDeploymentNotAcceptableValue = [NaN, "notValid", "N/A"].includes(
      system.deployment.value
    );
    system.deployment.color = isDeploymentNotAcceptableValue
      ? VBUsColorsValues[0].crowdstrike.deployment.color
      : getColor(
          settings.crowdstrike_deployment,
          Math.round(system.deployment.value)
        );
  },
  SET_WEB_APPLICATION_SCANS_AGGREGATE(
    state,
    { org, VBUsColorsValues, settings }
  ) {
    let system = state.heatmapAggregate[org.org_id].web_application_scans;

    // calculate operational and deployment value aggregate
    let sumOfOperationalValue;
    let sumOfOperationalColorBase;
    let sumOfDeploymentValue;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.web_application_scans.operational.value
        )
      ) {
        sumOfOperationalValue =
          (sumOfOperationalValue || 0) +
          (+colorsValuesItem.web_application_scans.operational.value || 0);
      }
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.web_application_scans.operational.colorBase
        )
      ) {
        sumOfOperationalColorBase =
          (sumOfOperationalColorBase || 0) +
          (+colorsValuesItem.web_application_scans.operational.colorBase || 0);
      }
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.web_application_scans.deployment.value
        )
      ) {
        sumOfDeploymentValue =
          (sumOfDeploymentValue || 0) +
          (+colorsValuesItem.web_application_scans.deployment.value || 0);
      }
    });

    if (sumOfOperationalValue === undefined) {
      // undefined means none of the VBU values are summable values
      system.operational.value =
        VBUsColorsValues[0].web_application_scans.operational.value;
    } else {
      system.operational.value =
        sumOfOperationalValue / (VBUsColorsValues?.length || 0);
    }

    if (sumOfOperationalColorBase === undefined) {
      // undefined means none of the VBU values are summable values
      system.operational.colorBase =
        VBUsColorsValues[0].web_application_scans.operational.colorBase;
    } else {
      system.operational.colorBase =
        sumOfOperationalColorBase / (VBUsColorsValues?.length || 0);
    }

    if (sumOfDeploymentValue === undefined) {
      // undefined means none of the VBU values are summable values
      system.deployment.value =
        VBUsColorsValues[0].web_application_scans.deployment.value;
    } else {
      system.deployment.value =
        sumOfDeploymentValue / (VBUsColorsValues?.length || 0);
    }

    // calculate color aggregate
    let isOperationalNotAcceptableValue = [NaN, "notValid", "N/A"].includes(
      system.operational.value
    );
    system.operational.color = isOperationalNotAcceptableValue
      ? VBUsColorsValues[0].web_application_scans.operational.color
      : system.operational.colorBase > 0
      ? getColor(
          settings.invicti_operational,
          system.operational.value,
          false,
          true
        )
      : getColor(settings.invicti_operational, system.operational.value);

    let isDeploymentNotAcceptableValue = [NaN, "notValid", "N/A"].includes(
      system.deployment.value
    );
    system.deployment.color = isDeploymentNotAcceptableValue
      ? VBUsColorsValues[0].web_application_scans.deployment.color
      : getColor(settings.invicti_deployment, system.deployment.value);
  },
  SET_PROOFPOINT_AGGREGATE(state, { org, VBUsColorsValues, settings }) {
    let system = state.heatmapAggregate[org.org_id].proofpoint;

    // calculate operational and deployment value aggregate
    let sumOfOperationalValue;
    let sumOfOperationalColorBase;
    let sumOfDeploymentValue;
    let sumOfDeploymentColorBase;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.proofpoint.operational.value
        )
      ) {
        sumOfOperationalValue =
          (sumOfOperationalValue || 0) +
          (+colorsValuesItem.proofpoint.operational.value || 0);
      }
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.proofpoint.operational.colorBase
        )
      ) {
        sumOfOperationalColorBase =
          (sumOfOperationalColorBase || 0) +
          (+colorsValuesItem.proofpoint.operational.colorBase || 0);
      }
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.proofpoint.deployment.value
        )
      ) {
        sumOfDeploymentValue =
          (sumOfDeploymentValue || 0) +
          (+colorsValuesItem.proofpoint.deployment.value || 0);
      }
      if (
        !["notValid", "N/A"].includes(
          colorsValuesItem.proofpoint.deployment.colorBase
        )
      ) {
        sumOfDeploymentColorBase =
          (sumOfDeploymentColorBase || 0) +
          (+colorsValuesItem.proofpoint.deployment.colorBase || 0);
      }
    });

    if (sumOfOperationalValue === undefined) {
      // undefined means none of the VBU values are summable values
      system.operational.value =
        VBUsColorsValues[0].proofpoint.operational.value;
    } else {
      system.operational.value =
        sumOfOperationalValue / (VBUsColorsValues?.length || 0);
    }

    if (sumOfOperationalColorBase === undefined) {
      // undefined means none of the VBU values are summable values
      system.operational.colorBase =
        VBUsColorsValues[0].proofpoint.operational.colorBase;
    } else {
      system.operational.colorBase =
        sumOfOperationalColorBase / (VBUsColorsValues?.length || 0);
    }

    if (sumOfDeploymentValue === undefined) {
      // undefined means none of the VBU values are summable values
      system.deployment.value = VBUsColorsValues[0].proofpoint.deployment.value;
    } else {
      system.deployment.value =
        sumOfDeploymentValue / (VBUsColorsValues?.length || 0);
    }

    if (sumOfDeploymentColorBase === undefined) {
      // undefined means none of the VBU values are summable values
      system.deployment.colorBase =
        VBUsColorsValues[0].proofpoint.deployment.colorBase;
    } else {
      system.deployment.colorBase =
        sumOfDeploymentColorBase / (VBUsColorsValues?.length || 0);
    }

    // calculate color aggregate
    let isOperationalNotAcceptableValue = [NaN, "notValid", "N/A"].includes(
      system.operational.value
    );
    system.operational.color = isOperationalNotAcceptableValue
      ? VBUsColorsValues[0].proofpoint.operational.color
      : getColor(settings.proofpoint_operational, system.operational.colorBase);

    let isDeploymentNotAcceptableValue = [NaN, "notValid", "N/A"].includes(
      system.deployment.value
    );
    system.deployment.color = isDeploymentNotAcceptableValue
      ? VBUsColorsValues[0].proofpoint.deployment.color
      : getColor(settings.proofpoint_deployment, system.deployment.colorBase);

    // if (org.name == 'Miller Group A') {
    //   console.log("system", system)
    //   console.log("VBUsColorsValues", VBUsColorsValues)
    // }
  },
  SET_KNOWBE_AGGREGATE(state, { org, VBUsColorsValues, settings }) {
    let system = state.heatmapAggregate[org.org_id].knowbe;

    // calculate operational and deployment value aggregate
    let sumOfOperationalValue = 0;
    let sumOfDeploymentValue = 0;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (colorsValuesItem.knowbe.operational.value !== "notValid") {
        sumOfOperationalValue +=
          +colorsValuesItem.knowbe.operational.value || 0;
      }
      if (colorsValuesItem.knowbe.deployment.value !== "notValid") {
        sumOfDeploymentValue += +colorsValuesItem.knowbe.deployment.value || 0;
      }
    });
    system.operational.value =
      sumOfOperationalValue / (VBUsColorsValues?.length || 0);
    system.deployment.value =
      sumOfDeploymentValue / (VBUsColorsValues?.length || 0);

    // calculate color aggregate
    system.operational.color = !system.operational.value
      ? { normal: NaN, blind: NaN }
      : getColor(settings.knowbe4_operational, system.operational.value);

    system.deployment.color = !system.deployment.value
      ? { normal: NaN, blind: NaN }
      : getColor(
          settings.knowbe4_deployment,
          Math.round(system.deployment.value)
        );
  },
  SET_SPYCLOUD_AGGREGATE(state, { org, VBUsColorsValues, settings }) {
    let system = state.heatmapAggregate[org.org_id].spycloud;

    // calculate operational value aggregate
    let sumOfOperationalValue = 0;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (colorsValuesItem.spycloud.operational.value !== "notValid") {
        sumOfOperationalValue +=
          +colorsValuesItem.spycloud.operational.value || 0;
      }
    });
    system.operational.value =
      sumOfOperationalValue / (VBUsColorsValues?.length || 0);

    // calculate deployment value aggregate
    let spycloudDeploymentStatusList = {
      active: 0,
      "active*": 0,
      inactive: 0,
      common: 0,
    };
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (
        spycloudDeploymentStatusList[
          colorsValuesItem.spycloud.deployment.value
        ] != undefined
      )
        spycloudDeploymentStatusList[
          colorsValuesItem.spycloud.deployment.value
        ]++;
    });

    let maxSpycloudDeployment = Object.entries(
      spycloudDeploymentStatusList
    ).reduce((max, nextValue) => {
      return nextValue[1] >= max[1] ? nextValue : max;
    });
    system.deployment.value = maxSpycloudDeployment[0];

    // calculate color aggregate
    system.operational.color = getColor(
      settings.spycloud_operational,
      system.operational.value
    );
    system.deployment.color = ["ourvolaris", "!"].includes(
      system.deployment.value
    )
      ? { normal: NaN, blind: NaN }
      : getSpycloudDeploymentColor(system.deployment.value);
  },
  SET_CISCO_AGGREGATE(state, { org, VBUsColorsValues, settings }) {
    let system = state.heatmapAggregate[org.org_id].cisco;

    // calculate operational value aggregate
    let sumOfOperationalValue = 0;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (colorsValuesItem.cisco.operational.value !== "notValid") {
        sumOfOperationalValue += +colorsValuesItem.cisco.operational.value || 0;
      }
    });
    system.operational.value =
      sumOfOperationalValue / (VBUsColorsValues?.length || 0);

    // calculate deployment value aggregate
    const VBUsActiveCount = VBUsColorsValues.filter(
      (item) => item.cisco.deployment.value == "active"
    )?.length;
    system.deployment.value =
      VBUsActiveCount / (VBUsColorsValues?.length || 0) > 0.5
        ? "active"
        : "notValid";

    // calculate color aggregate
    system.operational.color = isNaN(system.operational.value)
      ? { normal: NaN, blind: NaN }
      : getColor(settings.cisco_operational, system.operational.value);
    system.deployment.color =
      system.deployment.value === "notValid"
        ? { normal: NaN, blind: NaN }
        : getColor(null, system.deployment.value, true);
  },
  SET_VULNERABILITY_MANAGEMENT_AGGREGATE(
    state,
    { org, VBUsColorsValues, settings }
  ) {
    let system = state.heatmapAggregate[org.org_id].vulnerability_management;

    // calculate operational and deployment value aggregate
    let sumOfOperationalValue = 0;
    let sumOfOperationalColorBase = 0;
    let sumOfDeploymentValue = 0;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (
        colorsValuesItem.vulnerability_management.operational.value !==
        "notValid"
      ) {
        sumOfOperationalValue +=
          +colorsValuesItem.vulnerability_management.operational.value || 0;
        sumOfOperationalColorBase +=
          +colorsValuesItem.vulnerability_management.operational.colorBase || 0;
      }
      if (
        colorsValuesItem.vulnerability_management.deployment.value !==
        "notValid"
      ) {
        sumOfDeploymentValue +=
          +colorsValuesItem.vulnerability_management.deployment.value || 0;
      }
    });
    system.operational.value =
      sumOfOperationalValue / (VBUsColorsValues?.length || 0);
    system.operational.colorBase =
      sumOfOperationalColorBase / (VBUsColorsValues?.length || 0);
    system.deployment.value =
      sumOfDeploymentValue / (VBUsColorsValues?.length || 0);

    // calculate color aggregate
    system.operational.color = getColor(
      settings.rapid7_operational,
      system.operational.colorBase
    );
    system.deployment.color = getColor(
      settings.rapid7_deployment,
      Math.round(system.deployment.value)
    );
  },
  SET_AZURE_AGGREGATE(state, { org, VBUsColorsValues, settings }) {
    let system = state.heatmapAggregate[org.org_id].azure;

    // calculate operational value aggregate
    let sumOfOperationalValue = 0;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (colorsValuesItem.azure.operational.value !== "notValid") {
        sumOfOperationalValue += +colorsValuesItem.azure.operational.value || 0;
      }
    });
    system.operational.value =
      sumOfOperationalValue / (VBUsColorsValues?.length || 0);

    // calculate deployment value aggregate
    const VBUsActiveCount = VBUsColorsValues.filter(
      (item) => item.azure.deployment.value == "active"
    )?.length;
    system.deployment.value =
      VBUsActiveCount / (VBUsColorsValues?.length || 0) > 0.5
        ? "active"
        : "notValid";

    // calculate color aggregate
    system.operational.color = getColor(
      settings.azure_operational,
      system.operational.value
    );
    system.deployment.color =
      system.deployment.value === "notValid"
        ? { normal: NaN, blind: NaN }
        : getColor(null, system.deployment.value, true);
  },
  SET_WORKDAY_AGGREGATE(state, { org, VBUsColorsValues, settings }) {
    let system = state.heatmapAggregate[org.org_id].workday;

    // calculate operational and deployment value aggregate
    let sumOfOperationalValue = 0;
    let sumOfDeploymentValue = 0;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (colorsValuesItem.workday.operational.value !== "notValid") {
        sumOfOperationalValue +=
          +colorsValuesItem.workday.operational.value || 0;
      }
      if (colorsValuesItem.workday.deployment.value !== "notValid") {
        sumOfDeploymentValue += +colorsValuesItem.workday.deployment.value || 0;
      }
    });
    system.operational.value =
      sumOfOperationalValue / (VBUsColorsValues?.length || 0);
    system.deployment.value =
      sumOfDeploymentValue / (VBUsColorsValues?.length || 0);

    // calculate color aggregate
    system.operational.color =
      system.operational.value < 0
        ? getColor(settings.workday_operational, 0)
        : isNaN(system.operational.value) || system.operational.value == null
        ? { normal: NaN, blind: NaN }
        : getColor(settings.workday_operational, system.operational.value);

    system.deployment.color =
      system.deployment.value > 0
        ? getColor(settings.workday_deployment, false, true)
        : getColor(settings.workday_deployment, true, true);
  },
  SET_SIX_CLICKS_AGGREGATE(state, { org, VBUsColorsValues, settings }) {
    let system = state.heatmapAggregate[org.org_id].sixclicks;

    // calculate operational value aggregate
    let sumOfOperationalValue = 0;
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (colorsValuesItem.sixclicks.operational.value !== "notValid") {
        sumOfOperationalValue +=
          +colorsValuesItem.sixclicks.operational.value || 0;
      }
    });
    system.operational.value =
      sumOfOperationalValue / (VBUsColorsValues?.length || 0);

    // calculate deployment value aggregate
    let sixclicksDeploymentStatusList = {
      complete: 0,
      "complete*": 0,
      "in prog": 0,
      "in prog*": 0,
      tbc: 0,
      "tbc*": 0,
      late: 0,
    };
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (
        sixclicksDeploymentStatusList[
          colorsValuesItem.sixclicks.deployment.value
        ] != undefined
      )
        sixclicksDeploymentStatusList[
          colorsValuesItem.sixclicks.deployment.value
        ]++;
    });

    let maxSixclicksDeployment = Object.entries(
      sixclicksDeploymentStatusList
    ).reduce((max, nextValue) => {
      return nextValue[1] >= max[1] ? nextValue : max;
    });
    system.deployment.value = maxSixclicksDeployment[0];

    // calculate color aggregate
    system.operational.color =
      isNaN(system.operational.value) ||
      [null, "!"].includes(system.deployment.value)
        ? { normal: NaN, blind: NaN }
        : getColor(settings.sixclicks_operational, system.operational.value);

    system.deployment.color = [null, undefined, "!"].includes(
      system.deployment.value
    )
      ? { normal: NaN, blind: NaN }
      : getSixClicksDeploymentColor(system.deployment.value);
  },
  SET_SECURITY_SCORE_CARD_AGGREGATE(
    state,
    { org, VBUsColorsValues, settings }
  ) {
    let system = state.heatmapAggregate[org.org_id].securityscorecard;

    // calculate operational value aggregate
    let securityScoreCardOperationalStatusList = {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      F: 0,
    };
    VBUsColorsValues.forEach((colorsValuesItem) => {
      if (
        securityScoreCardOperationalStatusList[
          colorsValuesItem.securityscorecard.operational.value
        ] != undefined
      )
        securityScoreCardOperationalStatusList[
          colorsValuesItem.securityscorecard.operational.value
        ]++;
    });

    let maxSecurityScoreCardOperational = Object.entries(
      securityScoreCardOperationalStatusList
    ).reduce((max, nextValue) => {
      return nextValue[1] >= max[1] ? nextValue : max;
    });
    system.operational.value = maxSecurityScoreCardOperational[0];

    // calculate deployment value aggregate
    const VBUsActiveCount = VBUsColorsValues.filter(
      (item) => item.securityscorecard.deployment.value == "active"
    )?.length;
    system.deployment.value =
      VBUsActiveCount / (VBUsColorsValues?.length || 0) > 0.5
        ? "active"
        : "notValid";

    // calculate color aggregate
    system.operational.color = [null, undefined, "!"].includes(
      system.operational.value
    )
      ? { normal: NaN, blind: NaN }
      : getScorecardOperationalColor(system.operational.value);

    system.deployment.color = [null, undefined, "!"].includes(
      system.deployment.value
    )
      ? { normal: NaN, blind: NaN }
      : getColor(
          settings.scorecard_deployment,
          system.deployment.value === "active",
          true
        );
  },
};

const actions = {
  calculateHeatmapAggregate(
    { commit, state, rootState },
    { noVBUsOrgs, heatmapColorsValues }
  ) {
    // set colors and values aggregate for each organisation
    noVBUsOrgs.forEach((org) => {
      // build an array of org_ids of organisation VBUs
      const VBUs = org.VBUs.map((VBUItem) => VBUItem.org_id);

      // find heatmap colors values for each organisation according to organisation VBUs.
      let VBUsColorsValues = [];
      for (let orgId in heatmapColorsValues) {
        if (VBUs.includes(+orgId)) {
          VBUsColorsValues.push(heatmapColorsValues[orgId]);
        }
      }

      // set initial aggregate object into heatmap aggregate object for each organisation
      state.heatmapAggregate[org.org_id] = {
        crowdstrike: {
          deployment: { value: 0 },
          operational: { value: 0 },
        },
        web_application_scans: {
          deployment: { value: null },
          operational: { value: null },
        },
        proofpoint: {
          deployment: { value: 0 },
          operational: { value: 0 },
        },
        knowbe: {
          deployment: { value: 0 },
          operational: { value: 0 },
        },
        spycloud: {
          deployment: { value: "" },
          operational: { value: 0 },
        },
        cisco: {
          deployment: { value: "" },
          operational: { value: 0 },
        },
        vulnerability_management: {
          deployment: { value: 0 },
          operational: { value: 0 },
        },
        azure: {
          deployment: { value: "" },
          operational: { value: 0 },
        },
        workday: {
          deployment: { value: 0 },
          operational: { value: "" },
        },
        sixclicks: {
          deployment: { value: "" },
          operational: { value: 0 },
        },
        securityscorecard: {
          deployment: { value: "" },
          operational: { value: "" },
        },
      };

      // set colors and values aggregate for each system
      let settings = rootState.dashboardSettings?.data[0] || {};

      commit("SET_CROWDSTRIKE_AGGREGATE", { org, VBUsColorsValues, settings });
      commit("SET_WEB_APPLICATION_SCANS_AGGREGATE", {
        org,
        VBUsColorsValues,
        settings,
      });
      commit("SET_PROOFPOINT_AGGREGATE", { org, VBUsColorsValues, settings });
      commit("SET_KNOWBE_AGGREGATE", { org, VBUsColorsValues, settings });
      commit("SET_SPYCLOUD_AGGREGATE", { org, VBUsColorsValues, settings });
      commit("SET_CISCO_AGGREGATE", { org, VBUsColorsValues, settings });
      commit("SET_VULNERABILITY_MANAGEMENT_AGGREGATE", {
        org,
        VBUsColorsValues,
        settings,
      });
      commit("SET_AZURE_AGGREGATE", { org, VBUsColorsValues, settings });
      commit("SET_WORKDAY_AGGREGATE", { org, VBUsColorsValues, settings });
      commit("SET_SIX_CLICKS_AGGREGATE", { org, VBUsColorsValues, settings });
      commit("SET_SECURITY_SCORE_CARD_AGGREGATE", {
        org,
        VBUsColorsValues,
        settings,
      });

      // console.log("state.heatmapAggregate", state.heatmapAggregate)
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
