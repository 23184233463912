import { apolloClient as apollo } from "@/apollo";
import structure from "@/store/modules/structure"; /* todo: must be removed */

const defaultStoreState = () => ({
  systemsDetailsData: {},
});

const state = defaultStoreState();

const getters = {};

const mutations = {
  RESET_STORE: (store) => {
    Object.assign(store, defaultStoreState());
  },
  SET_SYSTEMS_DETAILS_DATA: (state, { systemsDetailsData }) => {
    state.systemsDetailsData = Array.isArray(systemsDetailsData)
      ? systemsDetailsData[0]
      : {};
  },
};

const actions = {
  getSystemsDetailsData({ commit }, { orgId, system }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { dash_two_clickthrough },
        } = await apollo.query({
          query: require("@/graphql/get_click_through_data.gql"),
          variables: {
            org_id: orgId,
            system: system,
          },
        });

        // console.log("clickthrough data", dash_two_clickthrough)

        commit("SET_SYSTEMS_DETAILS_DATA", {
          systemsDetailsData: dash_two_clickthrough,
        });

        resolve();
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
