<template>
  <div class="switch-wrapper cursor-pointer rounded-full relative shadow-sm">
    <input
      :id="name"
      type="checkbox"
      :name="name"
      :checked="value"
      class="focus:outline-none focus:shadow-md checkbox w-2.5 h-2.5 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
    />
    <label
      :for="name"
      class="toggle-label block w-11 h-5 overflow-hidden rounded-full border-2 border-gray-600 cursor-pointer transition-colors duration-200 ease-linear"
    ></label>
  </div>
</template>

<script>
export default {
  name: 'SwitchBase',
  props: {
    name: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      required: false,
    },
  }
};
</script>

<style lang="scss">
.switch-wrapper {
  width: 44px;
}

.checkbox {
  top: 0.05rem;
}

.checkbox:not(:checked) {
  @apply bg-yellow-400;

  left: 0.05rem;
}

.checkbox:not(:checked) + .toggle-label {
  @apply bg-bgDarkest;
}

.checkbox:checked {
  @apply bg-green-500;
  right: 0.05rem;
}

.checkbox:focus + .toggle-label {
  @apply shadow-sm;
}

.checkbox:checked + .toggle-label {
  @apply border-green-500;
}

.checkbox:checked:focus + .toggle-label {
  @apply border-green-400 shadow-sm;
}
</style>
