<template>
	<div class="relative py-4" v-if="tableData.length">
		<p class="mb-2">List of ineffective controls - CMMI 0’s and 1’s</p>
		<div class="flex justify-between items-center">
			<export-excel
				class="rounded-lg bg-green-600 hover:bg-green-700 transition cursor-pointer px-3 py-2"
				:data="excelInfo.dataList"
				:fields="excelInfo.titleList"
				type="csv"
				:name="`${org.name}-six-clicks-data.csv`"
				worksheet="Asset Management"
			>
				<font-awesome-icon
					:icon="['fa', 'file-excel']"
					class="mr-4"
				/>
				Export
			</export-excel>
		</div>
		<div class="w-full border-b border-gray-700 mt-3 mb-5"></div>
		<table class="w-full relative rounded-lg overflow-hidden">
			<thead>
				<tr>
					<th
						scope="col"
						class="px-2 py-3 text-left text-xs font-medium text-gray-200 uppercase bg-gray-700 z-20 sticky top-0 cursor-pointer hover:bg-gray-600 transition"
						:class="{'pl-5': (index === 0)}"
						@click="order(tableTitleItem.slug)"
						v-for="(tableTitleItem, index) in tableTitleList"
						:key="index"
					>
						<div class="flex items-center justify-between">
							<span style="user-select: none;">{{ tableTitleItem.title }}</span>
							<div class="flex flex-col">
								<font-awesome-icon
									class="text-white"
									:icon="['fa', 'chevron-up']"
									v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'ASC')"
								/>
								<font-awesome-icon
									class="text-white"
									:icon="['fa', 'chevron-down']"
									v-show="orderBy.column !== tableTitleItem.slug || (orderBy.column === tableTitleItem.slug && orderBy.type === 'DESC')"
								/>
							</div>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, index) in tableDataCopy"
					:key="index"
				>
					<th
						scope="col"
						class="px-2 pl-5 py-3 text-center text-xs font-medium text-gray-300 bg-gray-800 uppercase z-20 sticky top-0"
					>
						<div class="flex text-left items-center">{{ item.domain }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-300 bg-gray-800 uppercase z-20 sticky top-0"
					>
						<div class="flex text-left items-center">{{ item.question }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-300 bg-gray-800 uppercase z-20 sticky top-0"
					>
						<div class="flex text-left items-center">{{ item.target_response }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-300 bg-gray-800 uppercase z-20 sticky top-0"
					>
						<div class="flex text-left items-center">{{ item.actual_response }}</div>
					</th>
					<th
						scope="col"
						class="px-2 py-3 text-center text-xs font-medium text-gray-300 bg-gray-800 uppercase z-20 sticky top-0"
					>
						<div class="flex text-left items-center">{{ item.responder }}</div>
					</th>
				</tr>
			</tbody>
		</table>
	</div>
	<div v-else>
		<h2 class="text-xl text-gray-300">No ineffective controls</h2>
	</div>
</template>

<script>

export default {
  props: {
	  tableData: {
      type: Array,
		  default: []
    },
	  org: {
			type: Object,
		  default: {}
	  }
  },
	data() {
		return {
			orderBy: {
				column: '',
				type: ''
			},
			tableDataCopy: [],
			tableTitleList: [
				{title: "Domain", slug: "domain"},
				{title: "Question", slug: "question"},
				{title: "Target Response", slug: "target_response"},
				{title: "Actual Response", slug: "actual_response"},
				{title: "Responded by", slug: "responder"},
			],
			excelInfo: {
				titleList: {
					"Domain": "domain",
					"Question": "question",
					"Target Response": "target_response",
					"Actual Response": "actual_response",
					"Responded by": "responder"
				},
				dataList: []
			},
			isExcelActive: false,
		}
	},
	created() {
		this.tableDataCopy = [...this.tableData];

		this.setExcelData();
	},
	methods: {
		order(column) {
			if (this.orderBy.column === column) {
				this.orderBy.type = (this.orderBy.type === "DESC") ? "ASC" : "DESC";
			} else {
				this.orderBy.type = "ASC";
			}
			
			this.orderBy.column = column;
			
			this.tableDataCopy.sort((a, b) => {
				let aItem = a[this.orderBy.column];
				let bItem = b[this.orderBy.column];
				
				if (this.orderBy.type === "ASC") {
					return (aItem > bItem) ? 1 : (aItem < bItem) ? -1 : 0;
				} else {
					return (aItem < bItem) ? 1 : (aItem > bItem) ? -1 : 0;
				}
			})
			
			this.setExcelData();
		},
		setExcelData() {
			this.excelInfo.dataList = this.tableDataCopy;
		}
	}
};
</script>
<style lang="scss" scoped></style>
