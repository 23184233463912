<template>
	<div>
		<BackButton/>
		
		<div>
			<div class="flex justify-between">
				<div>
					<h4 class="text-lg mr-auto">{{ org.name }}</h4>
					<p class="mb-6">Critical, Severe and Moderate vulnerabilities detected in the last 6 months</p>
				</div>
				<div class="text-center my-2">
					<img src="@/assets/rapid7_logo.svg" class="h-8 inline-block"/>
				</div>
			</div>
			
			<div class="relative py-4">
				<div
					v-if="!chartData?.labels?.length"
					class="bg-green-900 flex items-center justify-center inset-0 blur-drop text-center rounded-md absolute z-10"
				>
					<p>No detections found.</p>
				</div>
				<div v-if="!isLoading">
					<VulnerabilityManagementChart :chartData="chartData"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SpinnerBase from '@/components/Base/SpinnerBase';
import VulnerabilityManagementChart from '@/components/VulnerabilityManagementChart';
import BackButton from '@/components/Base/BackButton';
// import {} from '@/utils/time';

export default {
	data() {
		return {
			isLoading: true,
			chartData: {
				labels: [],
				datasets: [
					{
						label: 'Severe',
						data: [],
						backgroundColor: 'rgba(245, 158, 11, 0.7)'
					},
					{
						label: 'Critical',
						data: [],
						backgroundColor: 'rgba(239, 68, 68,0.7)'
					},
					{
						label: 'Moderate',
						data: [],
						backgroundColor: 'rgba(59, 130, 246, 0.7)'
					},
				],
			}
		}
	},
	components: {
		BackButton,
		SpinnerBase,
		VulnerabilityManagementChart,
	},
	async mounted() {
		// get data and populate
		await this.$store.dispatch('systemsDetails/getSystemsDetailsData', {
			orgId: this.orgId,
			system: "rapidsev"
		});
		
		// set labels
		this.months.forEach((month) => {
			this.chartData.labels.push(new Date(month).toLocaleString('en-GB', {month: 'long'}).toUpperCase());
		})
		
		// set data
		const data = this.$store.state.systemsDetails.systemsDetailsData.data;
		const months = this.months;
		
		months.forEach((month) => {
			let selectedMonthData = {};
			data.forEach((dataItem) => {
				let dataItemDate = `${dataItem.date.split('-')[0]}-${dataItem.date.split('-')[1]}`; // YYYY-MM date format
				if (month === dataItemDate) {
					selectedMonthData = dataItem;
				}
			})
			
			this.chartData.datasets[0].data.push(selectedMonthData?.severe || 0);
			this.chartData.datasets[1].data.push(selectedMonthData?.critical || 0);
			this.chartData.datasets[2].data.push(selectedMonthData?.moderate || 0);
		});
		
		this.isLoading = false;
	},
	computed: {
		months() {
			var today = new Date();
			var d;
			var month;
			var newArr = [];
			for (var i = 5; i > 0; i -= 1) {
				d = new Date(today.getFullYear(), today.getMonth() - i, 1);
				month = d.toLocaleString('en-GB', {month: 'long'}).toUpperCase();
		
				newArr.push(`${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? `0${(d.getMonth() + 1)}` : (d.getMonth() + 1)}`);
			}
			newArr.push(`${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1)}`);
			return newArr;
		},
		orgId() {
			return this.$route.params.id;
		},
		org() {
			return this.$store.getters['structure/GET_ORGANISATION'](this.orgId);
		}
	}
};
</script>

<style lang="scss" scoped>
.blur-drop {
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(4px);
}
</style>
